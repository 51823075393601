export const formatUrl = (url) => {
  if (!url) return "";
  let newUrl = url?.trim();
  if (!url?.includes("https") && !url?.includes("http")) {
    return "https://" + newUrl;
  }
  return newUrl;
};

// export const formatPhoneNumber = (phoneNumberString) => {
//   var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     var intlCode = match[1] ? "1 " : "";
//     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//   }
//   return phoneNumberString;
// };
export const formatPhoneNumber = (phoneNumberString) => {
  // Remove all non-digit characters
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  // Check if there's a country code (1-4 digits at the beginning)
  var countryCodeMatch = cleaned.match(/^(\d{1,4})(\d{3})(\d{3})(\d{4})$/);
  if (countryCodeMatch) {
    var countryCode = countryCodeMatch[1];
    var areaCode = countryCodeMatch[2];
    var firstPart = countryCodeMatch[3];
    var secondPart = countryCodeMatch[4];

    // Build the formatted phone number with the country code
    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  // If there's no country code, assume it's a local number
  var match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If the input is not in a recognizable format, return it as is
  return phoneNumberString;
};

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
