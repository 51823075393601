const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,

    "& button": {
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  qrWrapper: {
    position: "relative",
    mt: 4,
    "& img": {
      position: "absolute",
      top: "-35px",
      left: "53px",
      textAlign: "center",
    },
  },
  qrCode: {
    textAlign: "center",
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
    m: 1,
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },

  title: {
    color: "#838383",
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
    mt: 4,
  },

  subTitle: {
    color: "#838383",
    textAlign: "center",
  },
  shareBtnWrapper: {
    textAlign: "center",
    mt: 2,
    mb: 2,
    "& button": {
      textTransform: "capitalize",
      height: 45,
      minWidth: 120,
    },
  },

  btnsWrapper: {
    m: 2.5,
  },
  downloadBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },
};

export default styles;
