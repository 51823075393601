import { useState, useEffect, useMemo } from "react";
import {
	Box,
	Stack,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Menu,
	MenuItem,
	Divider,
} from "@mui/material";
import PageTitle from "../../../components/common/PageTitle";
import _, { set } from "lodash";
import { useSnackbar } from "notistack";
import FormInput from "../../../components/forms/FormInput";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import routes from "../../../data/constants/routes";
import AddBusinessCard from "../../../components/customer/AddBusinessCard";
import { styled } from "@mui/material/styles";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";

import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import {
	SAVE_CARD,
	COMMUNITY_LISTING,
	COMMUNITY_DELETE,
} from "../../../data/constants/apiRoutes";
import { destroy, get, post } from "../../../server";
import { Clear, MoreVertOutlined } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useAtom } from "jotai";
import { mobileViewAtom } from "../../../data/store";
import { useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import Pagination from "../../../components/pagination/Pagination";

const styles = {
	topWrapper: {
		flexDirection: { sm: "row", xs: "column" },
		alignItems: { sm: "center", xs: "flex-start" },
		justifyContent: "space-between",
		gap: 1.5,
		pb: 2,
	},
	addBtn: {
		whiteSpace: "nowrap",
		background: "#135099",
		borderRadius: "10px",
		textTransform: "capitalize",
		width: "200px",
		height: "50px",
		"&:hover": {
			background: "#135099",
		},
	},
	gridWrapper: {
		display: "flex",
		justifyContent: { sm: "flex-start", xs: "center" },
		flexWrap: "wrap",
		gap: "30px",
		pt: 2,
	},
	card: {
		position: "relative",
		background: "#fff",
		borderRadius: 4,
		width: 200,
		height: 190,
		"& h3": {
			color: "#000",
			fontSize: 18,
			fontWeight: 500,
			textAlign: "center",
			mt: 1,
			px: 1,
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			maxWidth: 280,
			overflow: "hidden",
		},
		"& p": {
			color: "#4B4B4B",
			textAlign: "center",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			maxWidth: 280,
			overflow: "hidden",
			px: 1,
		},
	},
	menuBtn: {
		position: "absolute",
		top: 15,
		right: 15,
		p: "5px",
		background: "rgba(255, 255, 255, 0.3)",
		backdropFilter: "blur(20px)",
		"& svg": {
			width: 20,
			height: 20,
		},
	},
	coverImgWrapper: {
		height: 140,
		borderRadius: "16px 16px 0px 0px",
		"& img": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			borderRadius: "16px 16px 0px 0px",
		},
	},
	coverImgSkeleton: {
		height: 140,
		borderRadius: "16px 16px 0px 0px",
		bgcolor: "#eee",
	},
	profileWrapper: {
		textAlign: "center",
		mt: 3,
		"& img": {
			//   width: "100%",
			//   height: "100%",
			width: 70,
			height: 70,
			objectFit: "cover",
			border: "2px solid #fff",
			borderRadius: "50%",
		},
		"& svg": {
			width: "100%",
			height: 70,
		},
	},
	deleteModal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		p: 4,
	},
	noData: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		// minHeight: "calc(100vh - 64px)",

		// width: "100%",
		mt: 5,
		"& h1": {
			fontSize: 24,
			color: "#000",
			fontWeight: 500,
		},
	},
};

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open, mobileView }) => ({
		flexGrow: 1,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginRight: mobileView ? 0 : -drawerWidth,
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: 0,
		}),
	})
);

const ConnectionsListing = () => {
	const [open, setOpen] = useState(false);
	const [cards, setCards] = useState([]);
	const [currentCard, setCurrentCard] = useState({});
	const [contactId, setContactId] = useState(null);

	const [openQrModal, setOpenQrModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const [filterText, setFilterText] = useState("");
	const [isSearch, setIsSearch] = useState(false);
	const [searchParam, setSearchParam] = useState("");

	const { enqueueSnackbar } = useSnackbar();
	const client = useQueryClient();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);
	const menuOpen = Boolean(anchorEl);

	const [mobileView, setMobileView] = useAtom(mobileViewAtom);

	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});

	const [totalCount, setTotalCount] = useState(0);

	const handleClick = (e, card) => {
		setCurrentCard(card?.contactCard);
		setContactId(card?.id);
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		// setCurrentCard({});
		setAnchorEl(null);
	};

	const handleDrawerOpen = (card) => {
		setOpen(true);
		setAnchorEl(null);
	};

	// const handleEditNavigation = () => {
	// 	navigate(`${routes.CUSTOMER_EDIT_CONNECTION}/${currentCard.id}`);
	// };

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleOpenDelete = () => {
		setOpenDeleteModal(true);
	};

	const handleCloseDelete = () => {
		setOpenDeleteModal(false);
		setOpen(false);
	};

	const handleDelete = async () => {
		const { status, data } = await destroy(`${COMMUNITY_DELETE}/${contactId}`);
		if (status === true) {
			enqueueSnackbar(data, { variant: "success" });
			handleInvalidate();
		}
		handleClose();
		handleCloseDelete();
	};

	const handleSaveCard = async () => {
		const cardPhone = currentCard?.contact_card_phone?.map((item) => ({
			country_phone: item.country_code,
			phone_number: item.phone,
			phone_type: "",
		}));

		const { status, message, data } = await post(
			`${SAVE_CARD}/${currentCard.uuid}`,
			{
				full_name: currentCard?.fullName,
				first_name: currentCard?.fullName,
				description: currentCard?.description,
				profile_image: currentCard?.profileImage,
				website_url: currentCard?.website,
				email: currentCard?.cardEmails[0].email,
				company: currentCard?.company,
				company_position: currentCard?.title,
				cardPhone: cardPhone || null,
			}
		);

		if (status === true) {
			const link = document.createElement("a");
			link.download = "temp.vcf";
			link.href = data.url;
			link.click();

			enqueueSnackbar("VCF file has been downloaded successfully", {
				variant: "success",
			});
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const getListing = async () => {
		let url;

		url = new URL(COMMUNITY_LISTING);
		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.pageSize);
		if (isSearch) url.searchParams.append("search", searchParam);

		const { status, data } = await get(url.toString());
		if (status) {
			const newData = data?.data?.map((item) => ({
				...item,
				contactCard: {
					...item.contactCard,
					cardEmails: item.contactCard.contact_card_email,
					cardPhones: item.contactCard.contact_card_phone.map((phone) => ({
						phone: `${phone.country_code}${phone.phone}`,
						countryCode: phone.country_code,
					})),
					cardWebsites: item.contactCard.contact_card_websites,
				},
			}));
			setCards(newData);
			setTotalCount(data.totalCount);
		}
	};

	const listingQuery = useQuery(
		[queryNames.CONTACT, paginationModel.page, searchParam],
		getListing,
		{
			refetchOnWindowFocus: false,
		}
	);

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.CONTACT);
	};

	const handleFilter = (e) => {
		setFilterText(e.target.value);
	};

	const handleSearch = () => {
		if (filterText.length > 0) {
			setIsSearch(true);
			setSearchParam(filterText);
		}
	};

	const handleCancleSearch = () => {
		setIsSearch(false);
		setSearchParam("");
		setFilterText("");
	};

	// const filteredCards = useMemo(() => {
	// 	const filtered = cards?.filter((item) =>
	// 		`${item.contactCard.fullName || ""} ${item.contactCard.cardName || ""}`
	// 			.toLowerCase()
	// 			.includes(filterText.toLowerCase())
	// 	);

	// 	return filtered;
	// }, [filterText, cards]);

	if (listingQuery.isLoading || listingQuery.isFetching) {
		return <Loader />;
	}

	return (
		<>
			<Stack direction="row">
				<Main open={open} mobileView={mobileView}>
					<Box
						component="div"
						sx={{
							borderTop: "1px solid #EBEBEE",
							marginTop: "20px",
							py: "20px",
						}}
					>
						<Stack sx={styles.topWrapper}>
							<PageTitle title="Your Community" />
							<Stack direction="row" alignItems="center" spacing={2}>
								<FormInput
									placeholder="Search..."
									value={filterText}
									onChange={handleFilter}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{isSearch ? (
													<IconButton onClick={handleCancleSearch}>
														<Clear />
													</IconButton>
												) : (
													<IconButton onClick={handleSearch}>
														<SearchIcon />
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") handleSearch();
									}}
								/>
							</Stack>
						</Stack>
						{cards?.length > 0 ? (
							<Box sx={styles.gridWrapper}>
								{
									cards?.map((card) => (
										<Box sx={styles.card} key={card.id}>
											<Box>
												<Box sx={styles.profileWrapper}>
													{card?.contactCard?.profileImage ? (
														<Box
															component="img"
															src={card?.contactCard?.profileImage}
														/>
													) : (
														<PlaceholderImage />
													)}
												</Box>
											</Box>

											<IconButton
												sx={styles.menuBtn}
												onClick={(e) => handleClick(e, card)}
												aria-controls={menuOpen ? "menu" : undefined}
												aria-haspopup="true"
												aria-expanded={menuOpen ? "true" : undefined}
											>
												<MoreVertOutlined />
											</IconButton>
											<Menu
												anchorEl={anchorEl}
												id="menu"
												open={menuOpen}
												onClose={handleClose}
												// onClick={handleClose}
												PaperProps={{
													elevation: 0,
													sx: {
														overflow: "visible",
														mt: 1,
														borderRadius: "5px",
														width: 200,
													},
												}}
												transformOrigin={{
													horizontal: "right",
													vertical: "top",
												}}
												anchorOrigin={{
													horizontal: "right",
													vertical: "bottom",
												}}
											>
												<MenuItem onClick={() => handleDrawerOpen(card)}>
													View
												</MenuItem>
												{/* <MenuItem onClick={handleEditNavigation}>Edit</MenuItem> */}
												{/* <MenuItem onClick={handleDuplicate}>
                        Duplicate Card
					</MenuItem> */}
												{/* <Divider /> */}
												<MenuItem onClick={handleOpenDelete}>Delete</MenuItem>
												<MenuItem onClick={() => setOpenQrModal(true)}>
													Download QR
												</MenuItem>
												<MenuItem onClick={handleSaveCard}>Save Card</MenuItem>
											</Menu>
											<Typography component="h3">
												{card?.contactCard?.cardName
													? card?.contactCard?.cardName
													: card?.contactCard?.fullName}
											</Typography>
											<Typography component="p">
												{card?.contactCard?.cardEmails?.length > 0
													? card?.contactCard?.cardEmails[0].email
													: ""}
											</Typography>
										</Box>
									))
									//   )
								}
								{/* <AddBusinessCard /> */}
							</Box>
						) : (
							<Box sx={styles.noData}>
								<Typography component="h1">No record found</Typography>
							</Box>
						)}
					</Box>

					<Pagination
						paginationModel={paginationModel}
						setPaginationModel={setPaginationModel}
						totalCount={totalCount}
					/>
				</Main>

				<RightBusinessDrawer
					open={open}
					inViewMode={true}
					handleDrawerClose={handleDrawerClose}
					handleOpenDelete={handleOpenDelete}
					isComunity={true}
					{...currentCard}
				/>
			</Stack>

			<ConfirmationModal
				header="Are you sure?"
				title={`Do you really want to delete this record. This process cannot be undone.`}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				open={openDeleteModal}
				handleClose={handleCloseDelete}
				handleConfirm={handleDelete}
			/>

			<QrCodeModel
				open={openQrModal}
				handleClose={() => setOpenQrModal(false)}
				uuid={currentCard.uuid}
				personName={currentCard.fullName}
			/>
		</>
	);
};

export default ConnectionsListing;
