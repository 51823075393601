const styles = {
  stepper: {
    pt: 3,
    "& .MuiStep-root": {
      p: 0,
    },
    "& .MuiStepLabel-label": {
      color: "#000",
      fontWeight: 600,
    },
    "& .MuiStepConnector-root": {
      left: "calc(-50% + 15px)",
      right: " calc(50% + 15px)",
    },
    "& .MuiStepLabel-root": {
      flexDirection: "column",
    },
    "& .MuiStepConnector-line": {
      borderColor: "#ebebeb",
    },
    "& .MuiStepIcon-root": {
      width: 30,
      height: 30,
      color: "#F8F8F8",
      border: "1px solid #ebebeb",
      borderRadius: "50%",
    },
    "& .MuiStepLabel-iconContainer.Mui-active": {
      "& .MuiStepIcon-root": {
        color: "#135099",
        border: "none",
      },
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepLabel-label.Mui-active": {
      color: "#135099",
    },
  },

  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",

    // width: "100%",
    // mt: 5,
    "& h1": {
      fontSize: 24,
      color: "#000",
      fontWeight: 500,
    },
  },
  headers: {
    px: 2,
    mb: "18px",
    "& label": {
      color: "#1E1E1F",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  cartItems: {
    bgcolor: "#F8F8F8",
    borderRadius: "10px",
    py: "10px",
    px: 2,

    border: "1px solid #E8EEF5",

    "& label": {
      color: "#000",
      fontSize: 17,
      fontWeight: 500,
    },
    // // "& h3": {
    // //   color: "#000",
    // //   fontSize: 20,
    // //   fontWeight: 500,
    // //   lineHeight: "20px",
    // // },
    // // "& h4": {
    // //   color: "#135099",
    // //   fontSize: 20,
    // //   fontWeight: 500,
    // //   lineHeight: "20px",
    // //   mr: 2,
    // // },
  },
  select: {
    width: 120,
    height: 40,
    pr: 1.25,
    pl: 2.25,
    borderRadius: "10px",
    bgcolor: "#FFF",
    "& fieldset": {
      border: "1px solid #E8EEF5",
      borderColor: "#E8EEF5",
    },
    "&:hover fieldset": {
      borderColor: "#656DFC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#656DFC",
    },
    "& .MuiOutlinedInput-input": {
      py: 1.25,
      px: 2,
    },
  },
  formControlLabel: {
    "&.MuiFormControlLabel-root": {
      mr: 0,
    },
  },
  rightWrapper: {
    bgcolor: "#fff",
    p: 2.5,
    borderRadius: 2.5,
    "& h4": {
      fontSize: 20,
      color: "#1E1E1F",
      fontWeight: 600,
      pb: 2,
      mb: 2,
      borderBottom: "1px solid #D4D4D4",
    },
    "& h5": {
      color: "#4B4B4B",
    },
  },
  subtotal: {
    flexDirection: "row",
    justifyContent: "space-between",
    "& p": {
      fontSize: 14,
      color: "#4B4B4B",
    },
    "& h6": {
      color: "#1E1E1F",
      fontWeight: 500,
    },
  },
  total: {
    py: 1.5,
    my: 1.5,
    borderTop: "1px solid #D4D4D4",
    borderBottom: "1px solid #D4D4D4",
  },
  coupon: {
    color: "#135099",
    fontSize: 14,
    pb: 1,
    fontWeight: 600,
  },
  card: {
    flexDirection: "row",
    columnGap: 2,
    py: 3,
    "& p": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  checkoutBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    textTransform: "Capitalize",
    height: 50,
    mb: 3,
  },
  doneBtnWrapper: {
    textAlign: "center",
  },
  doneBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    textTransform: "Capitalize",
    height: 50,
    minWidth: 120,
  },
  profileCard: {
    p: 1,
    // background: "#fff",
    // borderRadius: 0,
    // width: 180,
    // height: 210,
    position: "relative",
    background: "#fff",
    borderRadius: 0,
    // border: "1px solid #E6E6E9",
    width: 225,
    height: 355,
    "& h3": {
      // color: "#000",
      // fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      px: 2,
      mt: 5,
    },
    "& h4": {
      color: "#000",
      textAlign: "center",
      // whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      wordBreak: "break-all",
      px:1
    },
    "& h6": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      p: "8px 8px 0 8px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
  },
  premiumProfileCard: {
    position: "relative",
    background: "#fff",
    borderRadius: 0,
    // border: "1px solid #E6E6E9",
    width: 225,
    height: 355,
    "& h3": {
      // color: "#000",
      // fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
      // maxWidth: 280,
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",

      overflow: "hidden",
      px: 2,
      mt: 6,
    },
    "& h4": {
      color: "#000",
      textAlign: "center",
      // whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      wordBreak: "break-all",
      px:1
    },
    "& h6": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      p: "8px 8px 0 8px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
    "&:hover": {
      "& .menuBtn": {
        opacity: 1,
      },
    },
  },
  templateProfileCard: {
    position: "relative",
    background: "#fff",
    borderRadius: 0,
    // border: "1px solid #E6E6E9",
    width: 225,
    height: 355,
    "& h3": {
      // color: "#000",
      // fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
      // maxWidth: 280,
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      overflow: "hidden",

      px: 2,
      mt: 3,
    },
    "& h4": {
      color: "#000",
      textAlign: "center",
      // whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      wordBreak: "break-all",
      px:1
    },
    "& h6": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      p: "8px 8px 0 8px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
    "&:hover": {
      "& .menuBtn": {
        opacity: 1,
      },
    },
  },

  menuBtn: {
    opacity: 0,
    position: "absolute",
    top: 15,
    right: 15,
    p: "5px",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(20px)",
    "& svg": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.3)",
    },
  },
  coverImgWrapper: {
    height: 180,
    borderRadius: "16px 16px 0px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      //   borderRadius: "16px 16px 0px 0px",
    },
  },
  profileCardCoverImgWrapper: {
    height: 180,
    borderRadius: "16px 16px 0px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      //   borderRadius: "16px 16px 0px 0px",
    },
  },
  coverImgSkeleton: {
    height: 180,
    borderRadius: "16px 16px 0px 0px",
    bgcolor: "#eee",
  },
  profileWrapper: {
    width: 80,
    height: 80,
    position: "absolute",
    top: 120,
    left: "50%",
    transform: "translateX(-50%)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      border: "2px solid #fff",
      borderRadius: "50%",
    },
    "& svg": {
      width: "100%",
      height: 70,
    },
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    p: 4,
  },
  logoWrapper: {
    p: "12px 30px",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  profile: {
    width: "100%",
    height: 180,
    objectFit: "cover",
  },
  profileSkeleton: {
    width: "100%",
    maxHeight: 130,
    objectFit: "contain",
  },
  logoWrapperTop: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "12px 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    width: "100%",
  },

  cardName: {
    "& span": {
      color: "#000",
      fontSize: 17,
      fontWeight: 500,
    },
  },

  qtyBtnContainer: {
    bgcolor: "#FFF",
    border: "1px solid #E8EEF5",
    borderRadius: "10px",
    width: 225,

    "& label": {
      color: "#000",
      fontSize: 16,
      fontWeight: 500,
      width: 70,
      textAlign: "center",
    },
  },
  qtyBtn: {
    bgcolor: "#E8EEF5",
    borderRadius: "10px",
    minWidth: 20,
    minHeight: 45,
    border: 0,
  },

  qrCodeWrapper: {
    // border: "1px solid #E6E6E9",
    p: 1,
    background: "#fff",
    borderRadius: 0,
    width: 225,
    height: 355,
    // "& canvas": {
    //   width: "100% !important",
    //   height: "100% !important",
    // },
    "& h3": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      px: 2,
      mt: 11,
    },
    "& h4": {
      color: "#000",
      textAlign: "center",
      // whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      wordBreak: "break-all",
      px:1
    },
    "& h6": {
      color: "#000",
      fontSize: 18,
      fontWeight: 600,
      textAlign: "center",
      p: "8px 8px 0 8px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
    },
  },
  qrCodeCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  team: {
    textTransform: "Capitalize",
    fontSize: 20,
    fontWeight: 500,
    mb: 1,
  }
};
export default styles;
