import { Route } from "react-router-dom";
import routes from "../constants/routes";
import Login from "../../pages/admin/auth/Login";
import DashboardLayout from "../../layout/DashboardLayout";
import CustomersListing from "../../pages/admin/customers/CustomersListing";
import AddEditCustomer from "../../pages/admin/customers/AddEditCustomer";
import BusinessCardsListing from "../../pages/admin/businessCards/Listing";
import CardOdersListing from "../../pages/admin/cardOrders/CardOdersListing";
import Reports from "../../pages/admin/reports/Reports";
import Settings from "../../pages/admin/Settings";
import CheckIfAdminAlreadyLoggedIn from "../../components/auth/CheckIfAdminAlreadyLoggedIn";
import AddEditProduct from "../../pages/admin/product/AddEditProduct";
import CategoryListing from "../../pages/admin/category/Listing";
import AddEditCategory from "../../pages/admin/category/AddEditCategory";
import PrivacyPolicy from "../../pages/admin/help/PrivacyPolicy";
import AboutUs from "../../pages/admin/help/AboutUs";
import ContactUsListing from "../../pages/admin/help/ContactUsListing";
import ContactUsDetail from "../../pages/admin/help/ContactUsDetail";
import ProductListing from "../../pages/admin/product/ProductListing";
import ResetPassword from "../../pages/admin/auth/ResetPassword";
import VerifyOTP from "../../pages/admin/auth/VerifyOTP";
import NewPassword from "../../pages/admin/auth/NewPassword";
import CheckAdminAuth from "../../components/auth/CheckAdminAuth";
import AddNotification from "../../pages/admin/notification/AddNotification";
import PricingListing from "../../pages/admin/pricing/PricingListing";
import CardOrderDetail from "../../pages/admin/cardOrders/CardOrderDetail";
import PublicCard from "../../pages/customer/publicPages/PublicCard";
import TeamsListing from "../../pages/admin/teams/TeamsListing";
import EditTeam from "../../pages/admin/teams/EditTeam";
import TeamDetail from "../../pages/admin/teams/TeamDetail";
import VeiwProduct from "../../pages/admin/product/VeiwProduct";
import TermsAndConditions from "../../pages/admin/help/TermsAndConditions";

const CustomerRoutes = (
  <>
    <Route path={routes.ADMIN_LOGIN} element={<Login />} />
    <Route path={routes.ADMIN_RESET_PASSWORD} element={<ResetPassword />} />
    <Route path={routes.ADMIN_VERIFY_OTP} element={<VerifyOTP />} />
    <Route path={routes.ADMIN_CREATE_NEW_PASSWORD} element={<NewPassword />} />

    <Route element={<CheckAdminAuth />}>
      <Route element={<DashboardLayout />}>
        <Route path={routes.ADMIN_PRICING} element={<PricingListing />} />
        <Route path={routes.ADMIN_CUSTOMERS} element={<CustomersListing />} />

        <Route
          path={routes.ADMIN_CUSTOMERS_ADD}
          element={<AddEditCustomer />}
        />
        <Route
          path={`${routes.ADMIN_CUSTOMERS_EDIT}/:customerId`}
          element={<AddEditCustomer />}
        />
        <Route path={routes.ADMIN_TEAMS} element={<TeamsListing />} />
        <Route
          path={`${routes.ADMIN_EDIT_TEAM}/:teamId`}
          element={<EditTeam />}
        />
        <Route
          path={`${routes.ADMIN_TEAM_DETAIL}/:teamId`}
          element={<TeamDetail />}
        />

        <Route
          path={routes.ADMIN_BUSINESS_CARD}
          element={<BusinessCardsListing />}
        />
        <Route
          path={`${routes.ADMIN_BUSINESS_CARD}/:uuid`}
          element={<PublicCard />}
        />

        <Route path={routes.ADMIN_CARD_ORDERS} element={<CardOdersListing />} />
        <Route
          path={`${routes.ADMIN_CARD_ORDERS}/:orderId`}
          element={<CardOrderDetail />}
        />

        <Route path={routes.ADMIN_PRODUCT} element={<ProductListing />} />
        <Route
          path={`${routes.ADMIN_PRODUCT}/:productId`}
          element={<VeiwProduct />}
        />
        <Route path={routes.ADMIN_PRODUCT_ADD} element={<AddEditProduct />} />
        <Route
          path={`${routes.ADMIN_PRODUCT_EDIT}/:productId`}
          element={<AddEditProduct />}
        />

        <Route path={routes.ADMIN_CATEGORY} element={<CategoryListing />} />
        <Route path={routes.ADMIN_CATEGORY_ADD} element={<AddEditCategory />} />
        <Route
          path={`${routes.ADMIN_CATEGORY_EDIT}/:categoryId`}
          element={<AddEditCategory />}
        />

        <Route path={routes.ADMIN_REPORTS} element={<Reports />} />
        <Route path={routes.ADMIN_SETTINGS} element={<Settings />} />
        <Route path={routes.ADMIN_ABOUT_US} element={<AboutUs />} />
        <Route path={routes.ADMIN_PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={routes.ADMIN_CONTACT_US} element={<ContactUsListing />} />
        <Route
          path={`${routes.ADMIN_CONTACT_US_DETAIL}/:contactUsId`}
          element={<ContactUsDetail />}
        />
        <Route
          path={routes.ADMIN_TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          path={routes.ADMIN_SEND_NOTIFICATION}
          element={<AddNotification />}
        />
      </Route>
    </Route>
  </>
);

export default CustomerRoutes;
