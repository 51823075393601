import { UPLOAD_IMAGE } from "../../data/constants/apiRoutes";
import { post } from "../../server";

const uploadFile = async (file, imageType) => {
  let formDataObj = new FormData();

  formDataObj.append("type", imageType === "image" ? "image" : "cover_image");
  formDataObj.append("file", file);

  let { status, message, data } = await post(UPLOAD_IMAGE, formDataObj);

  return { status, message, data };
};

export default uploadFile;
