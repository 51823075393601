import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
// import Footer from "./Footer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const drawerWidth = 280;
const styles = {
  main: {
    backgroundColor: "#F9FAFC",
    minHeight: "calc(100vh - 64px)",
    p: { md: 3, xs: "25px 16px" },
    mt: { md: 6.5, xs: 5.5 },
    flexGrow: 1,
  },
};

function DashboardLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
    <>
      <Sidebar
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: "auto",
          // "@media (max-width: 720px)": {
          //   width:  `calc(100% - ${drawerWidth}px)` ,
          // }
        }}
      >
        <Header
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
        />
        <Box sx={styles.main}>
          <Outlet />
        </Box>
        {/* <Footer drawerWidth={drawerWidth} /> */}
      </Box>
    </>
  );
}

export default DashboardLayout;
