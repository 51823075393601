import { GET_IMAGE } from "./apiRoutes";
import FirstImage from "../../assets/images/ConnectionBg1.svg";
export const templateImages = [
  {
    id: 1,
    // src: "../../assets/images/ConnectionBg1.svg",
    // value: "../..assets/images/ConnectionBg1.svg",
    src: FirstImage,

    value: FirstImage,
  },
];
export const coverImages = [
  {
    id: 1,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg1.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg1.png",
  },
  {
    id: 2,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg2.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg2.png",
  },
  {
    id: 3,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg3.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg3.png",
  },
  {
    id: 4,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg5.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg5.png",
  },
  {
    id: 5,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg6.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg6.png",
  },
  {
    id: 6,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg7.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg7.png",
  },
  {
    id: 7,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg8.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg8.png",
  },
  {
    id: 8,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg9.png",
    value:
      "https://narebapp.s3.us-east-2.amazonaws.com/KnocKnoc/templates/CardBg9.png",
  },
];

export const logoImages = [
  {
    id: 1,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/blueLogo.png",
    value:
    "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/blueLogo.png",
  },
  {
    id: 2,
    src: "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/whiteLogo.png",
    value:
    "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/whiteLogo.png",
  },
]

export const PrimaryColor = [
  { id: 1, bgcolor: "#D3A23B" },
  { id: 2, bgcolor: "#135099" },
  { id: 3, bgcolor: "#717171" },
  { id: 4, bgcolor: "#F48974" },
  { id: 5, bgcolor: "#9840F8" },
];

export const tabs = [
  {
    id: 1,
    name: "General",
  },
  {
    id: 2,
    name: "Display",
  },
  {
    id: 3,
    name: "Fields",
  },
  {
    id: 4,
    name: "Card Name",
  },
];
