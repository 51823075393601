import {
  Modal,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import _ from "lodash";
import LoadingOverlay from "../overlay/LoadingOverlay";
import { useMutation, useQuery } from "react-query";
import { post, put,get } from "../../server";
import { useSnackbar } from "notistack";
import {
  PURCHASE_SUBSCRIPTION,
  SUBSCRIPTION_PURCHASE,
  CUSTOMER_PROFILE_GET
} from "../../data/constants/apiRoutes";
import { useState } from "react";
import queryNames from "../../data/constants/queryNames";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../data/constants/routes";
import { loggedUserAtom } from "../../data/store";
import { useAtom } from "jotai";
import LoadingButton from "../button/LoadingButton";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 380, xs: 310 },
    bgcolor: "background.paper",
    borderRadius: 2,
    outline: "none",
    boxShadow: 15,
    minHeight: "250px",
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
    mb: 3,
  },

  submitBtn: {
    mt: 4,
    mb: 2,
    textTransform: "capitalize",
    height: "45px",
    whiteSpace: "nowrap",
    width: "100% !important",
    bgcolor: "#D3A23B",
    "&:hover": { bgcolor: "#D3A23B" },
  },

  cardElementWrapper: {
    border: "1px solid #C9C9C9",
    padding: "15px",
    borderRadius: "5px",
  },
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
const cardElementOptions = {
  // hidePostalCode: true,
};

const StripeModal = ({ open, handleClose, plan }) => {
  return (
    <Modal open={open}>
      <Box sx={styles.container}>
        <Box sx={styles.closeIconBtnWrapper}>
          <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
            <Close />
          </IconButton>
        </Box>
        <Typography sx={styles.headerText}>Payment</Typography>

        <Elements stripe={stripePromise}>
          <CheckoutForm plan={plan} handleClose={handleClose} />
        </Elements>
      </Box>
    </Modal>
  );
};

const CheckoutForm = ({ plan, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const handleFormRequest = async (paymentToken) => {
    const { status, message, data } = await post(PURCHASE_SUBSCRIPTION, {
      package_id: plan.id,
      total_price: plan.price_monthly,
      stripe_token: paymentToken,
      is_downgrade:plan.id < loggedUser.data.package_id ? true : false
    });

    if (status) {
      enqueueSnackbar(message, { variant: "success" });

      const userData = await get(CUSTOMER_PROFILE_GET);
      let user = _.cloneDeep(loggedUser);
      user.data.package = userData.data.package;
      user.data.package_id = userData.data.package_id;
      setLoggedUser(user);
      handleClose();
      // setLoggedUser(data);
      // navigate(routes.CUSTOMER_BUSINESS_CARD);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const paymentToken = await stripe.createToken(
      elements.getElement(CardElement)
    );

    mutation.mutate(paymentToken.token.id);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Box style={styles.cardElementWrapper}>
          <CardElement options={cardElementOptions} />
        </Box>
        <LoadingButton
          variant="contained"
          disabled={!stripe || !elements}
          fullWidth
          sx={styles.submitBtn}
          type="submit"
          isLoading={mutation.isLoading}
        >
          Purchase Subscription
        </LoadingButton>
      </Box>
    </>
  );
};

export default StripeModal;
