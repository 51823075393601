import { useState } from "react";
import { ReactComponent as LinkedIn } from "../../../assets/svg/businesscard/LinkedIn.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/businesscard/Facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/businesscard/Instagram.svg";
import { ReactComponent as Globe } from "../../../assets/svg/businesscard/Globe.svg";
import { ReactComponent as Email } from "../../../assets/svg/businesscard/Email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/businesscard/Phone.svg";
import { ReactComponent as Call } from "../../../assets/svg/businesscard/Call.svg";
import { ReactComponent as Location } from "../../../assets/svg/businesscard/Location.svg";
import { ReactComponent as Company } from "../../../assets/svg/businesscard/Company.svg";
import { ReactComponent as Tiktok } from "../../../assets/svg/createCard/Tiktok.svg";
import { ReactComponent as PInterest } from "../../../assets/svg/createCard/PInterest.svg";
import { ReactComponent as Threads } from "../../../assets/svg/createCard/Threads.svg";
import { ReactComponent as Clubhouse } from "../../../assets/svg/createCard/Clubhouse.svg";
import { Box, Stack, Typography, Skeleton, Button, Modal } from "@mui/material";
import { formatUrl, formatPhoneNumber } from "../../../utils/helpers/formatter";
import { CloseRounded } from "@mui/icons-material";
import PlaceholderImage from "../../../assets/images/PlaceholderImage.jpg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Logo from "../../../assets/images/shareLogo.png";
import VideoTemplate from "./VideoTemplate";

const styles = {
  card: {
    border: "1px solid #1C5FAE",
    borderRadius: 4,
    "& h5": {
      color: "#1E1E1F",
      fontSize: 14,
      wordBreak: "break-all",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  detail: {
    p: "45px 16px 16px",
    "& .qr-code": {
      width: 45,
      height: 45,
    },
    "& svg": {
      minWidth: 15,
    },
  },

  coverImgWrapper: {
    height: 180,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "16px 16px 0px 0px",
    },
  },
  coverSkeleton: {
    background: "#eee",
    height: 180,
    borderTopRightRadius: 18,
    borderTopLeftRadius: 18,
  },
  coverWrapper: {
    position: "relative",
  },
  nameWrapper: {
    color: "#000",
    textAlign: "center",

    "& h2": {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.1,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      marginInline: "auto",
    },
    "& h6": {
      fontSize: 13,
      pt: 0.5,
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 280,
      overflow: "hidden",
      marginInline: "auto",
    },
  },
  profileWrapper: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: 100,
    width: 100,
    height: 100,
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
      border: "2px solid #fff",
      background: "#F9FAFC",
    },
  },
  logo: {
    width: "100%",
    height: 40,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    top: 20,
    textAlign: "center",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  desc: {
    color: "#1E1E1F",
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.2,
    p: "15px 0 10px ",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 280,
    overflow: "hidden",
  },
  socialIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 30,
    height: 30,
    borderRadius: "50%",
    "& svg": {
      width: 17,
      height: 17,
    },
  },
  emailBtn: {
    color: "#fff",
    border: "none",
    borderRadius: 2.5,
    textTransform: "capitalize",
    textDecoration: "none",
    display: "block",
    textAlign: "center",
    minHeight: 45,
    p: 1.5,
    cursor: "pointer",
    mt: 1.5,
  },
  callBtn: {
    bgcolor: "#E8EEF5",
    color: "#000",
    border: "none",
    borderRadius: 2.5,
    textTransform: "capitalize",
    height: 45,
    lineHeight: "45px",
    display: "flex",
    textDecoration: "none",
    justifyContent: "space-between",
    cursor: "pointer",
    mt: 1.5,
    px: 3,
    "& svg": {
      position: "relative",
      top: 12,
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#fff",
    borderRadius: 3,
    p: 3,
    "& h2": {
      textAlign: "center",
      fontSize: 20,
      fontWeight: 600,
      pb: 2,
    },
    "& a": {
      textDecoration: "none",
      color: "#000",
    },
  },
  iconBox: {
    bgcolor: "#135099",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 35,
    height: 35,
    "& svg": {
      fill: "#fff",
      "& path": {
        fill: "#fff",
      },
    },
  },
};

function TemplateThree({
  inViewMode,
  fullName,
  title,
  department,
  profileImage,
  backgroundImage,
  logoImage,
  headline,
  cardEmails,
  cardPhones,
  address,
  company,
  primaryColor,
  QRCode,
  instagram,
  website,
  facebook,
  linkedin,
  tiktok,
  pinterest,
  clubhouse,
  threads,
  calendar,
  cardWebsites,
  youtube,
  // vimeo,
}) {
  const [emailOpenModal, setEmailOpenModal] = useState(false);
  const [phoneOpenModal, setPhoneOpenModal] = useState(false);
  const handleEmailClick = () => {
    setEmailOpenModal(true);
  };

  const handlePhoneClick = () => {
    setPhoneOpenModal(true);
  };

  const handleEmailModalClose = () => {
    setEmailOpenModal(false);
  };

  const handlePhoneModalClose = () => {
    setPhoneOpenModal(false);
  };
  return (
    <>
      <Box sx={styles.card}>
        <Box sx={styles.coverWrapper}>
          {backgroundImage ? (
            <Box sx={styles.coverImgWrapper}>
              <Box
                component="img"
                src={
                  backgroundImage
                    ? typeof backgroundImage === "object"
                      ? URL.createObjectURL(backgroundImage)
                      : backgroundImage.includes("amazonaws")
                      ? backgroundImage
                      : backgroundImage
                    : null
                }
              />
            </Box>
          ) : (
            <Box sx={styles.coverSkeleton}></Box>
          )}
          {logoImage ? (
            <Box sx={styles.logo}>
              <Box
                component="img"
                src={
                  logoImage
                    ? typeof logoImage === "object"
                      ? URL.createObjectURL(logoImage)
                      : logoImage
                    : null
                }
              ></Box>
            </Box>
          ) : inViewMode && !logoImage ? null : !logoImage ? (
            <Box
              component="img"
              src={Logo}
              sx={{ ...styles.logo, maxWidth: 200, width: "unset" }}
            />
          ) : (
            <Skeleton
              sx={{ ...styles.logo, maxWidth: 200 }}
              variant="rounded"
            />
          )}
          <Box sx={styles.profileWrapper}>
            {profileImage ? (
              <Box
                component="img"
                src={
                  profileImage
                    ? typeof profileImage === "object"
                      ? URL.createObjectURL(profileImage)
                      : profileImage
                    : null
                }
              />
            ) : inViewMode && !profileImage ? (
              <Box
                component="img"
                src={PlaceholderImage}
                sx={styles.dummyImage}
              />
            ) : (
              <Skeleton variant="circular" width={100} height={100} />
            )}
          </Box>
        </Box>
        <Box sx={styles.detail}>
          <Box sx={styles.nameWrapper} pb={0.5}>
            {company ? (
              <Typography component="h2" sx={{ color: "#000" }}>
                {company}
              </Typography>
            ) : inViewMode ? null : (
              <Skeleton
                variant="rounded"
                sx={{ mx: "auto" }}
                width={150}
                height={15}
              />
            )}
          </Box>

          <Box sx={styles.nameWrapper} pb={2}>
            {fullName ? (
              <Typography
                component="h2"
                sx={{ color: primaryColor ? primaryColor : "#000" }}
              >
                {fullName}
              </Typography>
            ) : inViewMode ? null : (
              <Skeleton
                variant="rounded"
                sx={{ mx: "auto" }}
                width={150}
                height={15}
              />
            )}
            <Typography component="h6">
              {title && department ? (
                <>
                  {title} {department ? `/ ${department}` : null}
                </>
              ) : department ? (
                <>{department}</>
              ) : inViewMode ? null : (
                <Skeleton
                  variant="rounded"
                  sx={{ mx: "auto" }}
                  width={100}
                  height={15}
                />
              )}
            </Typography>
          </Box>

          <Stack
            direction="row"
            justifyContent="center"
            gap={1}
            flexWrap="wrap"
          >
            {facebook ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(facebook)}
                target="_blank"
              >
                <Facebook />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {/* {youtube ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(youtube)}
                target="_blank"
              >
                <YouTubeIcon />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )} */}
            {linkedin ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(linkedin)}
                target="_blank"
              >
                <LinkedIn />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {instagram ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(instagram)}
                target="_blank"
              >
                <Instagram />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {/* {tiktok ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(tiktok)}
                target="_blank"
              >
                <Tiktok />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )} */}
            {pinterest ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(pinterest)}
                target="_blank"
              >
                <PInterest />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {threads ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(threads)}
                target="_blank"
              >
                <Threads />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
            {clubhouse ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(clubhouse)}
                target="_blank"
              >
                <Clubhouse />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}

            {calendar ? (
              <Box
                sx={{
                  ...styles.socialIcon,
                  bgcolor: primaryColor ? primaryColor : "#eee",
                  "& path": {
                    fill: primaryColor ? "#fff" : "#000",
                  },
                }}
                component="a"
                href={formatUrl(calendar)}
                target="_blank"
              >
                <CalendarMonthIcon />
              </Box>
            ) : inViewMode ? null : (
              <Skeleton variant="circular" width={20} height={20} />
            )}
          </Stack>

          {cardPhones?.length ? (
            <>
              {cardPhones.length === 1 ? (
                <Box
                  sx={styles.callBtn}
                  href={`tel:${cardPhones[0].phone}`}
                  component="a"
                >
                  <Call />
                  Call
                  <Call className="none" />
                </Box>
              ) : (
                <Box sx={styles.callBtn} onClick={handlePhoneClick}>
                  <Call />
                  Call
                  <Call className="none" />
                </Box>
              )}
            </>
          ) : inViewMode ? null : (
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              sx={{ mt: 1.5 }}
            />
          )}

          {headline ? (
            <Typography sx={styles.desc}>{headline}</Typography>
          ) : inViewMode ? null : (
            <Skeleton
              variant="rounded"
              width={200}
              height={15}
              sx={{ mt: 2 }}
            />
          )}

          {cardEmails?.length ? (
            <>
              {cardEmails.length === 1 ? (
                <Box
                  sx={{
                    ...styles.emailBtn,
                    bgcolor: primaryColor ? primaryColor : "#135099",
                  }}
                  href={`mailto:${cardEmails[0].email}`}
                  component="a"
                >
                  Email
                </Box>
              ) : (
                <Box
                  sx={{
                    ...styles.emailBtn,
                    bgcolor: primaryColor ? primaryColor : "#135099",
                  }}
                  onClick={handleEmailClick}
                >
                  Email
                </Box>
              )}
            </>
          ) : inViewMode ? null : (
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              sx={{ mt: 1.5 }}
            />
          )}

          {address ? (
            <Box
              sx={{
                ...styles.emailBtn,
                bgcolor: primaryColor ? primaryColor : "#135099",
              }}
              href={`http://maps.google.com/?q=${
                inViewMode ? address : address?.address
              }`}
              component="a"
              target="_blank"
            >
              View Address
            </Box>
          ) : inViewMode ? null : (
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              sx={{ mt: 1.5 }}
            />
          )}

          {cardWebsites?.length > 0 &&
            cardWebsites.map((website, index) => {
              return (
                <>
                  {website.title && website.url ? (
                    <Box
                      component="a"
                      sx={{
                        ...styles.emailBtn,
                        bgcolor: primaryColor ? primaryColor : "#135099",
                      }}
                      key={index}
                      href={formatUrl(website.url)}
                      target="_blank"
                    >
                      {website.title}
                    </Box>
                  ) : inViewMode ? null : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={45}
                      sx={{ mt: 1.5 }}
                      key={index}
                    />
                  )}
                </>
              );
            })}
          <VideoTemplate youtube={youtube} tiktok={tiktok} />
        </Box>
      </Box>
      <Modal open={emailOpenModal}>
        <Box sx={styles.modal}>
          <Stack direction="row" justifyContent="flex-end">
            <CloseRounded onClick={handleEmailModalClose} className="pointer" />
          </Stack>
          <Typography component="h2">Emails</Typography>
          {cardEmails?.length
            ? cardEmails.map((single, index) => (
                <Box sx={{ pb: 1.5 }} key={index}>
                  <Box
                    href={`mailto:${single.email}`}
                    key={single.id}
                    component="a"
                  >
                    <Stack
                      direction="row"
                      columnGap={1.5}
                      alignItems="center"
                      sx={{ display: "inline-flex" }}
                    >
                      <Box
                        sx={{
                          ...styles.iconBox,
                          bgcolor: primaryColor ? primaryColor : "#135099",
                        }}
                      >
                        <Email />
                      </Box>
                      {single.email}
                    </Stack>
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </Modal>
      <Modal open={phoneOpenModal}>
        <Box sx={styles.modal}>
          <Stack direction="row" justifyContent="flex-end">
            <CloseRounded onClick={handlePhoneModalClose} className="pointer" />
          </Stack>
          <Typography component="h2">Phones</Typography>
          {cardPhones?.length
            ? cardPhones.map((single, index) => (
                <Box sx={{ pb: 1.5 }} key={index}>
                  <Box
                    href={`tel:${single.phone}`}
                    key={single.id}
                    component="a"
                  >
                    <Stack
                      direction="row"
                      columnGap={1.5}
                      alignItems="center"
                      sx={{ display: "inline-flex" }}
                    >
                      <Box
                        sx={{
                          ...styles.iconBox,
                          bgcolor: primaryColor ? primaryColor : "#135099",
                        }}
                      >
                        <Phone />
                      </Box>
                      {formatPhoneNumber(single.phone)}
                    </Stack>
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </Modal>
    </>
  );
}

export default TemplateThree;
