import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Collapse,
} from "@mui/material";
import { ReactComponent as MasterCard } from "../../../assets/svg/MasterCard.svg";
import AddToCartStep from "../checkout/checkoutSteps/AddtoCartStep";
import { Add, ExpandLess, ExpandMore, Remove } from "@mui/icons-material";
import SelectInput from "../../../components/forms/SelectInput";
import Logo from "../../../assets/images/LOGO.png";
import PlaceHolderImg from "../../../assets/images/PlaceholderImage.jpg";

import {
  ColorlibConnector,
  ColorlibStepIcon,
} from "../../../components/common/CustomStepper";
import CheckOutStep from "../checkout/checkoutSteps/CheckoutStep";
import OrderCompleteStep from "../checkout/checkoutSteps/OrderCompleteStep";
import { get } from "../../../server";
import {
  PRINT_ORDER_CARD_PRICING_LISTING,
  CARDS_DETAIL,
} from "../../../data/constants/apiRoutes";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader";
import styles from "../../../assets/styles/customer/orderCard.styles";
import FormLabel from "../../../components/forms/FormLabel";
import { QRCode } from "react-qrcode-logo";

import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import generateUniqueFileName from "../../../utils/helpers/generateUniqueFileName";
import LoadingButton from "../../../components/button/LoadingButton";
import { toPng } from "html-to-image";
import LoadingBackDrop from "../../../components/overlay/LoadingBackDrop";

const steps = ["Add to cart", "Checkout", "Order Complete"];

const PersonalOrderCard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [total, setTotal] = useState(0);
  const [cart, setCart] = useState([]);
  const [pricingQueryDone, setPricingQueryDone] = useState(false);
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});

  const [orderCardsData, setOrderCardsData] = useState({
    stripeId: null,
    orderCards: [],
    shippingLatitude: null,
    shippingLongitude: null,
    totalPrice: 0,
    shippingAddress: "",
    paymentType: "",
    paymentMethod: "",
  });

  const { cardId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const isId = cardId !== null && typeof cardId !== "undefined";

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeCheckBox = (e, index) => {
    if (e.target.checked) {
      return;
    }
    const updatedCart = [...cart];
    updatedCart[index].isChecked = e.target.checked;
    setCart(updatedCart);
  };

  const handleToggleDetail = (value, index) => {
    const updatedCart = cart.map((item, i) => {
      if (i === index) {
        return { ...item, isCollapsed: value };
      } else {
        return { ...item, isCollapsed: false };
      }
    });

    setCart(updatedCart);
  };

  const handleChangeCardType = (e, index) => {
    const updatedCart = [...cart];
    updatedCart[index].cardType = e.target.value;
    updatedCart[index].isChecked = false;

    if (updatedCart[index].cardType === "basic") {
      updatedCart[index].unitPrice = prices.basicPricing;
    } else {
      updatedCart[index].unitPrice = prices.premiumPricing;
    }

    setCart(updatedCart);
  };

  const handleChangeQuantity = (value, index) => {
    const updatedCart = [...cart];

    updatedCart[index].quantity = value;
    updatedCart[index].price =
      updatedCart[index].cardType === "basic"
        ? prices.basicPricing * value
        : prices.premiumPricing * value;
    setCart(updatedCart);
  };

  const calculateSubtotal = () => {
    const filteredCart = cart.filter((item) => item.isChecked);

    const subtotal = filteredCart?.reduce((total, item) => {
      return total + item.price;
    }, 0);

    return subtotal.toFixed(2);
  };

  const fetchSingleCard = async () => {
    const { status, message, data } = await get(`${CARDS_DETAIL}/${cardId}`);

    if (status) {
      let preparedData = [];
      const newObj = {
        ...data,
        primaryColor: data.primaryColor ? data.primaryColor : "#000",
        nameFontSize:
          data.fullName.length > 45 ? 14 : data.fullName.length > 40 ? 16 : 18,
        cardType: "basic",
        quantity: 1,
        price: prices.basicPricing,
        unitPrice: prices.basicPricing,
        isChecked: false,
        isCollapsed: false,
        frontImage: null,
        backImage: null,
      };

      preparedData.push(newObj);

      setCart(preparedData);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const fetchPricing = async () => {
    let { status, message, data } = await get(PRINT_ORDER_CARD_PRICING_LISTING);

    if (status) {
      const basicPricing = data.find((pricing) => pricing.name === "basic");
      const premiumPricing = data.find((pricing) => pricing.name !== "basic");
      setPrices({
        basicPricing: parseFloat(basicPricing.price),
        premiumPricing: parseFloat(premiumPricing.price),
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
    setPricingQueryDone(true);
  };

  const pricingQuery = useQuery(queryNames.PRICING, fetchPricing, {
    enabled: isId,
    refetchOnWindowFocus: false,
  });

  const singleCardQuery = useQuery([queryNames.CARD, cardId], fetchSingleCard, {
    enabled: isId && pricingQueryDone,
    refetchOnWindowFocus: false,
  });

  const handleCapture = async (index, frontCardId, backCardId) => {
    const frontCard = document.getElementById(frontCardId);
    const backCard = document.getElementById(backCardId);
    setLoading(true);

    if (!frontCard || !backCard) {
      return;
    }

    try {
      let frontImagePath, backImagePath; // Define the variables here

      // Convert front card to PNG and upload to AWS
      const frontDataUrl = await toPng(frontCard, {
        cacheBust: true,
        pixelRatio: 5,
      });
      const frontBlob = await fetch(frontDataUrl).then((res) => res.blob());
      const frontFile = new File([frontBlob], generateUniqueFileName(), {
        type: "image/png",
      });
      const frontData = await awsFileUpload(frontFile, "orderCard");
      if (frontData.status === true) {
        frontImagePath = { path: frontData.location }; // Assign the value here
      } else {
        enqueueSnackbar("Error uploading front image", { variant: "error" });
      }

      // Convert back card to PNG and upload to AWS
      const backDataUrl = await toPng(backCard, {
        cacheBust: true,
        pixelRatio: 5,
      });
      const backBlob = await fetch(backDataUrl).then((res) => res.blob());
      const backFile = new File([backBlob], generateUniqueFileName(), {
        type: "image/png",
      });
      const backData = await awsFileUpload(backFile, "orderCard");
      if (backData.status === true) {
        backImagePath = { path: backData.location }; // Assign the value here
      } else {
        enqueueSnackbar("Error uploading back image", { variant: "error" });
      }

      // Update the cart state with the image paths
      setCart((prevCart) => {
        let myUpdatedCart = [...prevCart];
        myUpdatedCart[index].frontImage = frontImagePath.path;
        myUpdatedCart[index].backImage = backImagePath.path;
        myUpdatedCart[index].isCollapsed = false;
        myUpdatedCart[index].isChecked = true;
        myUpdatedCart[index].price =
          myUpdatedCart[index].cardType === "basic"
            ? prices.basicPricing * myUpdatedCart[index].quantity
            : prices.premiumPricing * myUpdatedCart[index].quantity;
        return myUpdatedCart;
      });
    } catch (err) {
      console.error("Error creating or uploading images:", err);
      enqueueSnackbar(err, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    const subtotal = calculateSubtotal();
    setTotal(subtotal);
    const filteredCart = cart.filter((item) => item.isChecked);

    setOrderCardsData({
      ...orderCardsData,

      totalPrice: parseFloat(subtotal),
      orderCards: filteredCart.map(
        ({ id, quantity, price, cardType, frontImage, backImage }) => ({
          cardId: id,
          quantity: quantity,
          totalPrice: price,
          pricingId: cardType === "basic" ? 1 : 2,
          frontImage: frontImage,
          backImage: backImage,
        })
      ),
    });
  }, [cart]);

  if (pricingQuery.isFetching || singleCardQuery.isFetching) {
    return <Loader />;
  }

  return (
    <Box sx={{ position: "relative", p: 1.5 }}>
      <LoadingBackDrop loading={loading} />
      <>
        <Box my={2}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: activeStep === index ? "#135099" : "#1E1E1F",
                      fontSize: "16px",
                      fontWeight: 600,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 ? (
          <Grid container mt={5} spacing={2}>
            <Grid item md={8} xs={12}>
              {cart.length > 0 ? (
                <>
                  {cart.map((item, index) => {
                    return (
                      <Stack
                        direction="column"
                        sx={styles.cartItems}
                        key={index}
                        // alignItems="center"
                        // gap={2}
                        flexWrap="wrap"
                        mb={1}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Typography sx={styles.cardName}>
                            <Box component="span">Card Name:</Box>
                            {` ${
                              item?.cardName ? item?.cardName : item?.fullName
                            }`}
                          </Typography>
                          <FormControlLabel
                            sx={styles.formControlLabel}
                            control={
                              <Checkbox
                                checked={item.isChecked}
                                onChange={(e) => handleChangeCheckBox(e, index)}
                              />
                            }
                          />
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Typography sx={styles.cardName}>
                            <Box component="span">Owner Name:</Box>

                            {` ${item?.userData?.first_name} ${item?.userData?.last_name}`}
                          </Typography>
                          <IconButton
                            onClick={() =>
                              handleToggleDetail(!item?.isCollapsed, index)
                            }
                          >
                            {item?.isCollapsed ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </Stack>

                        <Collapse
                          in={item.isCollapsed}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <Typography sx={styles.cardName}>
                              <Box component="span">Unit Price:</Box>

                              {` $${item.unitPrice}`}
                            </Typography>
                            <Typography sx={styles.cardName} mt={1}>
                              <Box component="span">Card Type:</Box>{" "}
                              {item.teamData ? "Team Card" : "Personal Card"}
                            </Typography>
                            <Box>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                gap={2}
                                mt={2}
                                flexWrap="wrap"
                              >
                                <Box width="225px">
                                  <FormLabel label="Choose Plan" />

                                  <SelectInput
                                    sx={styles.select}
                                    value={item.cardType}
                                    onChange={(e) =>
                                      handleChangeCardType(e, index)
                                    }
                                  >
                                    <MenuItem value="basic">Basic</MenuItem>
                                    <MenuItem value="premium">Premium</MenuItem>
                                  </SelectInput>
                                </Box>
                                <Box>
                                  <FormLabel label="Quantity" />
                                  <Stack
                                    sx={styles.qtyBtnContainer}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    mt={1}
                                  >
                                    <Button
                                      disabled={item.quantity <= 1}
                                      onClick={() =>
                                        handleChangeQuantity(
                                          item.quantity - 1,
                                          index
                                        )
                                      }
                                      sx={styles.qtyBtn}
                                    >
                                      <Remove sx={{ fill: "#000" }} />
                                    </Button>

                                    <Typography component="label">
                                      {item.quantity}
                                    </Typography>

                                    <Button
                                      // // disabled={counter >= product["countInStock"]}
                                      onClick={() =>
                                        handleChangeQuantity(
                                          item.quantity + 1,
                                          index
                                        )
                                      }
                                      sx={styles.qtyBtn}
                                    >
                                      <Add sx={{ fill: "#135099" }} />
                                    </Button>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              flexWrap="wrap"
                              gap={2}
                              mt={2}
                            >
                              <Box>
                                {item.cardType === "basic" ? (
                                  <Box sx={styles.profileCard} id={item.uuid}>
                                    <Box sx={styles.profileCardCoverImgWrapper}>
                                      <Box
                                        component="img"
                                        src={Logo}
                                        alt="cart item"
                                      />
                                    </Box>
                                    <Typography
                                      component="h3"
                                      sx={{
                                        color: item.primaryColor,
                                        fontSize: item.nameFontSize,
                                      }}
                                    >
                                      {item.fullName}
                                    </Typography>
                                    <Typography component="h4">
                                      {`${item?.title ? item?.title : ""} ${
                                        item?.title && item?.department
                                          ? "/"
                                          : ""
                                      } ${
                                        item?.department ? item?.department : ""
                                      }`}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <>
                                    {item.templateNo === 2 ||
                                    item.templateNo === 3 ? (
                                      <Box
                                        sx={styles.premiumProfileCard}
                                        key={item.id}
                                        id={item.uuid}
                                      >
                                        {item.backgroundImage ? (
                                          <Box sx={styles.coverImgWrapper}>
                                            <Box
                                              component="img"
                                              src={item.backgroundImage}
                                            />
                                          </Box>
                                        ) : (
                                          <Box
                                            sx={styles.coverImgSkeleton}
                                          ></Box>
                                        )}

                                        <Box sx={styles.logoWrapperTop}>
                                          {item?.logoImage ? (
                                            <Box
                                              component="img"
                                              src={item?.logoImage}
                                              sx={styles.logo}
                                            />
                                          ) : null}
                                        </Box>

                                        <Box sx={styles.profileWrapper}>
                                          {item.profileImage ? (
                                            <Box
                                              component="img"
                                              src={item.profileImage}
                                            />
                                          ) : (
                                            <Box
                                              component="img"
                                              src={PlaceHolderImg}
                                              alt="Placeholder-img"
                                            />
                                          )}
                                        </Box>

                                        <Typography
                                          component="h3"
                                          sx={{
                                            color: item.primaryColor,
                                            fontSize: item.nameFontSize,
                                          }}
                                        >
                                          {item.fullName}
                                        </Typography>

                                        <Typography component="h4">
                                          {`${item?.title ? item?.title : ""} ${
                                            item?.title && item?.department
                                              ? "/"
                                              : ""
                                          } ${
                                            item?.department
                                              ? item?.department
                                              : ""
                                          }`}
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={styles.templateProfileCard}
                                        key={item.id}
                                        id={item.uuid}
                                      >
                                        <Box sx={styles.logoWrapper}>
                                          {item.logoImage ? (
                                            <Box
                                              component="img"
                                              src={item.logoImage}
                                              sx={styles.logo}
                                            />
                                          ) : null}
                                        </Box>

                                        {item.profileImage ? (
                                          <Box
                                            component="img"
                                            src={item.profileImage}
                                            sx={styles.profile}
                                          />
                                        ) : (
                                          <Box
                                            component="img"
                                            src={PlaceHolderImg}
                                            alt="Placeholder-img"
                                            sx={styles.profileSkeleton}
                                          />
                                        )}

                                        {/* <Typography component="h3">
                                          {item.fullName}
                                        </Typography>

                                        <Typography component="p">
                                          {item?.title}
                                          {item?.department
                                            ? ` / ${item?.department}`
                                            : null}
                                        </Typography> */}

                                        <Typography
                                          component="h3"
                                          sx={{
                                            color: item.primaryColor,
                                            fontSize: item.nameFontSize,
                                          }}
                                        >
                                          {item.fullName}
                                        </Typography>

                                        <Typography component="h4">
                                          {`${item?.title ? item?.title : ""} ${
                                            item?.title && item?.department
                                              ? "/"
                                              : ""
                                          } ${
                                            item?.department
                                              ? item?.department
                                              : ""
                                          }`}
                                        </Typography>
                                      </Box>
                                    )}
                                  </>
                                )}
                              </Box>
                              <Box sx={styles.qrCodeWrapper} id={item.id}>
                                <Box sx={styles.qrCodeCard}>
                                  <QRCode
                                    value={`${
                                      import.meta.env.VITE_SHARE_URL
                                    }/card/${
                                      item.uuid
                                    }?personName=${`${item.userData.first_name} ${item.userData.last_name}`}`}
                                  />
                                </Box>
                                <Typography component="p"></Typography>
                                <Typography component="h3">
                                  Scan Qr Code
                                </Typography>
                              </Box>
                            </Stack>
                            <Box mt={2} mb={1} sx={styles.doneBtnWrapper}>
                              <LoadingButton
                                variant="contained"
                                sx={styles.doneBtn}
                                onClick={() =>
                                  handleCapture(index, item.uuid, item.id)
                                }
                                // isLoading={loading}
                              >
                                Done
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Collapse>

                        {/* <Box width={150}>
                      <SelectInput
                        sx={styles.select}
                        value={item.quantity}
                        onChange={(e) => handleChangeQuantity(e, index)}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                      </SelectInput>
                    </Box> */}

                        {/* <Typography component="h4">${item.price}</Typography> */}
                        {/* <IconButton>
                      <Clear
                        sx={{ width: 20, height: 20, color: "#d32f2f" }}
                        onClick={() => handleRemoveItem(item.id)}
                      />
                    </IconButton> */}
                      </Stack>
                    );
                  })}
                </>
              ) : (
                <Box sx={styles.noData}>
                  <Typography component="h1">No card found</Typography>
                </Box>
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={styles.rightWrapper}>
                <Typography component="h4">Order Summary</Typography>
                <Stack sx={styles.subtotal} pb={1}>
                  <Typography>Subtotal</Typography>
                  <Typography component="h6">${total}</Typography>
                </Stack>
                {/* <Stack sx={styles.subtotal}>
                  <Typography>Shipping</Typography>
                  <Typography component="h6">Via DHL</Typography>
                </Stack> */}
                <Stack sx={[styles.subtotal, styles.total]}>
                  <Typography>Total</Typography>
                  <Typography component="h6">${total}</Typography>
                </Stack>
                {/* <Typography sx={styles.coupon}>Have a coupon?</Typography> */}
                {/* <Typography component="h5">
                Shipping, taxes, and discounts added at checkout.
              </Typography> */}
                {/* <Stack sx={styles.card}>
                <MasterCard />
                <Box>
                  <Typography>Digital Card No</Typography>
                  <Typography>**** **** 7894</Typography>
                </Box>
              </Stack> */}
                <Button
                  variant="contained"
                  sx={styles.checkoutBtn}
                  disableElevation
                  fullWidth
                  onClick={handleNext}
                  disabled={
                    orderCardsData?.orderCards?.length < 1 ? true : false
                  }
                >
                  Checkout
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : activeStep === 1 ? (
          <CheckOutStep
            handleNext={handleNext}
            handleBack={handleBack}
            setAddress={setAddress}
            address={address}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            totalCards={cart.length}
            orderCardsData={orderCardsData}
            setOrderCardsData={setOrderCardsData}
            setOrderData={setOrderData}
            isPersonalCard={true}
          />
        ) : (
          <OrderCompleteStep orderData={orderData} />
        )}
      </>
    </Box>
  );
};

export default PersonalOrderCard;
