import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { useState, useRef } from "react";
import Logo from "../../assets/images/shareLogo.png";
import CustomInputField from "../../components/forms/CustomInputField";
import { ReactComponent as ProfileCamera } from "../../assets/svg/ProfileCamera.svg";
import { Close } from "@mui/icons-material";
import { REGISTER_FORM } from "../../data/constants/apiRoutes";
import PhoneInput from "react-phone-input-2";
import { validator } from "../../utils/helpers/validator";
import { RegisterFormSchema } from "../../utils/schemas/RegisterFormSchema";
import { useMutation } from "react-query";
import _ from "lodash";
import { uploadImageType } from "../../data/constants/uploadImageTypes";
import awsFileUpload from "../../utils/helpers/awsFileUpload";
import { useSnackbar } from "notistack";
import { post } from "../../server";
import { useLocation, useNavigate } from "react-router";
import routes from "../../data/constants/routes";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../data/store";
import LoadingButton from "../../components/button/LoadingButton";
import CropAvatar from "../../components/modal/CropAvatar";

const styles = {
	wrapper: {
		mt: 3,
		maxWidth: 360,
		marginInline: "auto",
		background:
			"url(https://narebapp.s3.us-east-2.amazonaws.com/knocknock/createProducts/shareBg.png)",
		backgroundSize: "cover",
		backgroundPosition: "center",
		borderRadius: 5,
		p: 3,
		position: "relative",
		"&::before": {
			content: "''",
			position: "absolute",
			background: "#135099",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			opacity: "0.8",
			borderRadius: 5,
		},
		"& h3": {
			fontSize: 26,
			color: "#fff",
			position: "relative",
			textAlign: "center",
			fontWeight: 700,
			pt: 3,
		},
		"& h6": {
			color: "#fff",
			position: "relative",
			fontSize: 18,
			textAlign: "center",
		},
		"& form": {
			position: "relative",
			pt: 5,
			"& h4": {
				color: "#fff",
				fontWeight: 500,
				pb: 1.5,
			},
			"& .MuiTextField-root": {
				mb: 1.5,
			},
			"& input": {
				bgcolor: "#fff",
			},
		},
	},
	imgWrapper: {
		textAlign: "center",
		position: "relative",
		cursor: "pointer",
	},
	submitBtn: {
		borderRadius: 2.5,
		background: "#D2A13A",
		textTransform: "capitalize",
		height: 50,
		mt: 4,
		"&:hover": {
			background: "#D2A13A",
		},
	},
	cancelBtn: {
		borderRadius: 2.5,
		background: "#fff",
		color: "#135099",
		textTransform: "capitalize",
		height: 50,
		mt: 1.5,
		"&:hover": {
			background: "#fff",
		},
	},
	profileImg: {
		width: 104,
		height: 104,
		border: "1px solid #eee",
		borderRadius: 4,
		objectFit: "contain",
		cursor: "pointer",
	},
	crossWrapper: {
		display: "inline-block",
		position: "relative",
		mb: 1.5,
		"& svg": {
			position: "absolute",
			top: 10,
			right: 10,
			background: "rgba(255, 255, 255, 0.3)",
			backdropFilter: "blur(20px)",
			borderRadius: "50%",
			p: 0.5,
			cursor: "pointer",
		},
	},
	errorMessage: {
		color: "#d32f2f",
	},
	login: {
		color: "#1E1E1F",
		textAlign: "center",
		fontWeight: 500,
		"& a": {
			textDecoration: "none",
			color: "#000000",
			fontWeight: 600,
			cursor: "pointer",
		},
	},
};

const initialForm = {
	first_name: "",
	last_name: "",
	email: "",
	phone: "",
	country_code: "",
	image: "",
};

function ShareCard() {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const [profileImg, setProfileImg] = useState(null);

	const [form, setForm] = useState(initialForm);

	const profileRef = useRef();
	const [modal, setModal] = useState(false);

	const [errors, setErrors] = useState({});

	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();
	const cardId = new URLSearchParams(location.search).get("cardId");
	const navigate = useNavigate();

	const handleProfileClick = () => {
		profileRef.current.click();
	};

	const handleProfileChange = (e) => {
		setProfileImg(URL.createObjectURL(e.target.files[0]));
		setModal("image");
	};

	const handleRemoveProfile = () => {
		setForm({ ...form, image: "" });
	};

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		setForm({ ...form, [name]: value });
	};

	const handlePhoneChange = (value, country) => {
		setForm({ ...form, phone: value, country_code: country.dialCode });
	};

	const validate = () => {
		const newErrors = validator(form, RegisterFormSchema);
		setErrors(newErrors);
		if (Object.keys(newErrors).length) return false;
		return true;
	};

	const handleFormSubmit = async () => {
		let payload = _.cloneDeep(form);
		let newStatus = true;

		if (profileImg) {
			const data = await awsFileUpload(
				payload.image,
				uploadImageType.registerForm
			);
			if (data?.status === true) {
				payload.image = data?.location;
			} else {
				enqueueSnackbar("Error uploading image", { variant: "error" });
				return (newStatus = false);
			}
		}

		if (newStatus) {
			const { status, message, data } = await post(REGISTER_FORM, {
				...payload,
				card_id: cardId,
				phone: payload.phone?.slice(payload.country_code.length),
			});
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				setLoggedUser({
					data: {
						first_name: payload.first_name,
						last_name: payload.last_name,
						email: payload.email,
						share_card: true,
					},
				});
				navigate(routes.CUSTOMER_SIGNUP);
			} else enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleFormSubmit);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validate()) return;
		mutation.mutate();
	};

	const handleLoginClick = () => {
		sessionStorage.setItem("cardId", cardId);
		setLoggedUser({
			...loggedUser,
			data: {
				...loggedUser.data,
				share_card: true,
			},
		});
		if (loggedUser?.token) navigate(routes.CUSTOMER_BUSINESS_CARD);
		else navigate(routes.CUSTOMER_LOGIN);
	};

	return (
		<>
			<Box sx={styles.wrapper}>
				<Box sx={styles.imgWrapper}>
					<Box component="img" src={Logo} />
				</Box>
				<Typography component="h3">Connect with me</Typography>
				<Typography component="h6">Stay in the know</Typography>
				<Box component="form" noValidate>
					<Typography component="h4">Upload Photo</Typography>
					{form?.image ? (
						<Box sx={styles.crossWrapper}>
							<Box
								component="img"
								sx={styles.profileImg}
								src={
									form?.image
										? typeof form?.image === "object"
											? URL.createObjectURL(form?.image)
											: form?.image
										: null
								}
							/>
							<Close onClick={handleRemoveProfile} />
						</Box>
					) : (
						<ProfileCamera
							onClick={handleProfileClick}
							className="pointer mb-12"
						/>
					)}
					<input
						type="file"
						accept="image/*"
						hidden
						ref={profileRef}
						id="profile"
						onChange={handleProfileChange}
						key={form?.image}
					/>
					<CustomInputField
						label="First Name"
						name="first_name"
						value={form.first_name}
						onChange={handleChange}
						error={errors?.first_name ? true : false}
						helperText={errors?.first_name || ""}
					/>
					<CustomInputField
						label="Last Name"
						name="last_name"
						value={form.last_name}
						onChange={handleChange}
						error={errors?.last_name ? true : false}
						helperText={errors?.last_name || ""}
					/>
					<CustomInputField
						label="Email"
						name="email"
						type="email"
						value={form.email}
						onChange={handleChange}
						error={errors?.email ? true : false}
						helperText={errors?.email || ""}
					/>
					<Box component="div" className={"phoneInputWrapper"}>
						<PhoneInput
							value={form.phone}
							inputClass={
								errors.phone
									? "phoneNumberInput2 errorInput"
									: "phoneNumberInput2"
							}
							country={"us"}
							enableSearch
							onChange={(value, country) => handlePhoneChange(value, country)}
						/>
						{errors.phone ? (
							<FormHelperText sx={styles.errorMessage}>
								{errors.phone}
							</FormHelperText>
						) : null}
					</Box>
					<LoadingButton
						type="submit"
						variant="contained"
						sx={styles.submitBtn}
						disableElevation
						fullWidth
						onClick={handleSubmit}
						isLoading={mutation.isLoading}
					>
						Submit
					</LoadingButton>
					<Button
						variant="contained"
						sx={styles.cancelBtn}
						disableElevation
						fullWidth
					>
						Cancel
					</Button>
				</Box>
			</Box>
			<Box display="flex" justifyContent="center" mt={1} mb={2}>
				<Typography component="label" sx={styles.login}>
					Already have an Account ?{" "}
					<Typography component="a" onClick={handleLoginClick}>
						Login
					</Typography>
				</Typography>
			</Box>

			<CropAvatar
				modal={modal}
				setModal={setModal}
				image={profileImg}
				user={form}
				setUser={setForm}
				cropType=""
				aspectRatio={4 / 4}
			/>
		</>
	);
}

export default ShareCard;
