import { Close } from "@mui/icons-material";
import { IconButton, Box, styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import FormAutoComplete from "../forms/FormAutoComplete";
import { useEffect, useRef, useState } from "react";
import BusinessCardPreview from "../customer/BusinessCardPreview";
import LoadingButton from "../button/LoadingButton";
import { useMutation } from "react-query";
import { SAVE_CONTACT } from "../../data/constants/apiRoutes";
import { post } from "../../server";
import { useSnackbar } from "notistack";
import BoxModal from "./BoxModal";
import { useNavigate } from "react-router";
import routes from "../../data/constants/routes";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "#fff",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
    maxHeight: "90%",
    overflowY: "auto",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  btn: {
    height: 45,
    mt: 2,
    width: "100%",
    boxShadow: "none",
    textTransform: "capitalize",
    borderRadius: "10px",
    bgcolor: "#D3A23B",
    color: "#fff",
    "&:hover": {
      bgcolor: "#D3A23B",
      boxShadow: "none",
    },
  },
};

const CardModal = ({ cardModal, setCardModal, cards }) => {
  const [cardOptions, setCardOptions] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();

  // const [replace, setReplace] = useState(false);
  // const [replaceUserCard, setReplaceUserCard] = useState(false);
  const replace = useRef(false);
  const replaceUserCard = useRef(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (cards?.length > 0) {
      // sessionStorage.setItem("cardId", 246);
      setCardOptions(
        cards.map((card) => ({
          label: card.cardName ? card.cardName : card.fullName,
          value: card.id,
          type: card.type,
        }))
      );
    }
  }, [cards]);

  const handleCardSelect = (value) => {
    if (value) {
      setSelectedCard(cards.find((option) => option.id === value.value));
    }
  };

  const saveCard = async () => {
    const { status, message, data } = await post(SAVE_CONTACT, {
      function_id: sessionStorage.getItem("cardId"),
      function_type: "card",
      user_card_id: selectedCard.id,
      replace: replace.current,
      replace_user_card: replaceUserCard.current,
    });
    console.log(status, message, data);
    if (status === true) {
      sessionStorage.removeItem("cardId");
      enqueueSnackbar("Knocknoc card has been saved in your community", {
        variant: "success",
      });
      setCardModal(false);
      navigate(routes.CUSTOMER_CONNECTIONS);
      replace.current = false;
      replaceUserCard.current = false;
    } else {
      if (message === "This card already exists") {
        setModalData({
          header: message,
          message:
            "This KnocKnoc Card already exist in your Community do you want to replace?",
          button: "Replace",
          type: "replace",
        });
        setModal(true);
      } else if (message === "This selected user card already exist") {
        setModalData({
          header: message,
          message:
            "Are you sure you want to share this card to save in his community?",
          button: "Replace",
          type: "user_replace",
        });
        setModal(true);
      } else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(saveCard);

  const handleSave = () => {
    mutation.mutate();
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const handleConfirm = () => {
    handleModalClose();
    if (modalData?.type === "replace") {
      // setReplace(true);
      replace.current = true;
      replaceUserCard.current = false;
    } else if (modalData?.type === "user_replace") {
      // setReplaceUserCard(true);
      replace.current = false;
      replaceUserCard.current = true;
    }
    mutation.mutate();
  };

  const handleClose = () => {
    setCardModal(false);
    sessionStorage.removeItem("cardId");
  };

  const GroupHeader = styled("div")({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    backgroundColor: "#135099",
    color: "#fff",
    // color: "#1E1E1F",
    // fontWeight: 600,
  });

  const GroupItems = styled("ul")({
    padding: 0,
  });
  return (
    <>
      <Modal open={cardModal} onClose={handleClose}>
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper} id="modal-modal-title">
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <Box id="modal-modal-description" mt={2}>
            <Box width="100%">
              <FormAutoComplete
                placeholder="Select Card"
                options={cardOptions}
                value={cardOptions.find(
                  (option) => option.value === selectedCard?.id
                )}
                onChange={(e, value) => handleCardSelect(value)}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                groupBy={(option) => option.type}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>
                      {params.group === "personal"
                        ? "Personal Cards"
                        : "Team Cards"}
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
              />
            </Box>
            {selectedCard && (
              <Box mt={2}>
                <BusinessCardPreview {...selectedCard} inViewMode />
                <LoadingButton
                  sx={styles.btn}
                  onClick={handleSave}
                  isLoading={mutation.isLoading}
                >
                  Proceed
                </LoadingButton>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <BoxModal
        header={modalData?.header}
        title={modalData?.message}
        open={modal}
        handleClose={handleModalClose}
        handleConfirm={handleConfirm}
        confirmButton={modalData?.button}
        confirmButtonColor="#E53935"
        isLoading={mutation.isLoading}
      />
    </>
  );
};

export default CardModal;
