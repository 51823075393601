import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FormInput from "../../../../components/forms/FormInput";
import { ReactComponent as Stripe } from "../../../../assets/svg/Cards/Stripe.svg";
import { ReactComponent as RightArrow } from "../../../../assets/svg/RightArrow.svg";
import { useJsApiLoader } from "@react-google-maps/api";
import LocationAutoComplete from "../../../../components/forms/LocationAutoComplete";
import FormLabel from "../../../../components/forms/FormLabel";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_ORDER_PLACE } from "../../../../data/constants/apiRoutes";
import { post } from "../../../../server";
import LoadingButton from "../../../../components/button/LoadingButton";
import PrintOrderSchema from "../../../../utils/schemas/PrintOrderSchema";
import { validator } from "../../../../utils/helpers/validator";

const styles = {
  container: {
    bgcolor: "#fff",
    borderRadius: "10px",
    py: "20px",
    px: 5,
    mt: 5,
    "& h3": {
      color: "#000",
      fontSize: 20,
      fontWeight: 600,
    },
    "& label": {
      color: "#1E1E1F",
      fontSize: 14,
      fontWeight: 500,
    },
    "& h5": {
      color: "#135099",
      fontSize: 14,
      fontWeight: 500,
      mt: 2,
    },
  },
  divider: {
    color: "#EEE",
    my: 2,
  },
  card: {
    "& h3": {
      color: "#000",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "20px",
    },
    "& label": {
      color: "#000",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "20px",
    },
  },
  submitBtn: {
    width: 150,
    bgcolor: "#135099",
    color: "#fff",
    mt: 2,
    textTransform: "capitalize",
    "&:hover": {
      bgcolor: "#135099",
    },
  },

  cancelBtn: {
    textTransform: "capitalize",
    color: "#000",
    bgcolor: "#E8EEF5",
    width: { md: 120, xs: 55 },
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,

    minWidth: { xs: 95 },
    "&:hover": {
      color: "#000",
      bgcolor: "#E8EEF5",
      opacity: 0.8,
    },
  },

  nextBtn: {
    textTransform: "capitalize",
    color: "#fff",
    bgcolor: "#135099",
    minWidth: { md: 120, xs: 55 },
    height: 45,
    borderRadius: 2.5,
    fontWeight: 600,

    "&:hover": {
      color: "#fff",
      bgcolor: "#135099",
      opacity: 0.8,
    },
  },

  purchaseBtn: {
    textTransform: "capitalize",
    height: "45px",
    whiteSpace: "nowrap",
    width: "100% !important",
  },
  cardElementWrapper: {
    border: "1px solid #C9C9C9",
    borderRadius: "10px",
    padding: "15px",
    height: 50,
    // borderRadius: "5px",
  },
  errorMessage: {
    color: "#d32f2f",
    fontSize: "14px",
  },
};

const initialState = {
  address1: "",
  address2: "",
};
const libraries = ["places"];
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
const cardElementOptions = {
  // hidePostalCode: true,
};

const CheckOutStep = ({
  handleNext,
  handleBack,
  selectedLocation,
  setSelectedLocation,
  address,
  setAddress,
  orderCardsData,
  setOrderCardsData,
  totalCards,
  setOrderData,
  isPersonalCard = false,
}) => {
  const [errors, setErrors] = useState({});

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Box sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography variant="h3">Shipping Details</Typography>

            <Box mt={2}>
              <FormLabel label="Shipping Address" withasterisk />
              {isLoaded && (
                <LocationAutoComplete
                  label="Address"
                  placeholder="Enter Shipping Address"
                  locationType="address"
                  types={["establishment"]}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  address={address}
                  setAddress={setAddress}
                  data={orderCardsData}
                  setData={setOrderCardsData}
                  isCheckOut={true}
                  error={errors?.shippingAddress ? true : false}
                  helperText={errors?.shippingAddress || ""}
                />
              )}
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h3">Card Details</Typography>

            <Box mt={2}>
              <FormLabel label="Payment" withasterisk />
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  orderCardsData={orderCardsData}
                  handleNext={handleNext}
                  totalCards={totalCards}
                  errors={errors}
                  setErrors={setErrors}
                  setOrderData={setOrderData}
                  isPersonalCard={isPersonalCard}
                />
              </Elements>
            </Box>
          </Grid>
        </Grid>
        {/* <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            checked={true}
            // onChange={handleChange}
          />
          <Typography component="label">Ship on Billing Address</Typography>
        </Stack> */}

        {/* <Divider sx={styles.divider} /> */}

        {/* <Typography variant="h3">Payment Method</Typography> */}

        {/* <Stack direction="row" spacing={2} alignItems="center" mt={2}>
          <Stripe />
          <Stack direction="column" sx={styles.card}>
            <Typography component="label">Digital Card No.</Typography>
            <Typography component="h3">**** **** **** 1234</Typography>
          </Stack>
        </Stack> */}

        {/* <Typography component="h5">+Add Another Card</Typography> */}
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Button
          variant="contained"
          sx={styles.cancelBtn}
          onClick={handleBack}
          disableElevation
        >
          <RightArrow className="mr-1" />
          Back
        </Button>

        {/* <Button variant="contained" sx={styles.nextBtn} onClick={handleNext}>
          Continue
        </Button> */}
      </Stack>
    </>
  );
};

const CheckoutForm = ({
  orderCardsData,
  handleNext,
  totalCards,
  errors,
  setErrors,
  setOrderData,
  isPersonalCard,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const validateForm = () => {
    let newErrors = validator(orderCardsData, PrintOrderSchema);

    if (errors.stripeError) {
      newErrors.stripeError = errors.stripeError;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;
    return true;
  };

  // const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const handleFormRequest = async (paymentToken) => {
    const { status, message, data } = await post(CUSTOMER_ORDER_PLACE, {
      ...orderCardsData,
      paymentMethod: "stripe",
      paymentType: "card",
      stripeId: orderCardsData?.totalPrice < 1 ? "" : paymentToken,
    });
    if (status) {
      setOrderData(data);
      handleNext();
      enqueueSnackbar(message, { variant: "success" });
      // setLoggedUser(data);
      // await navigate(routes.SELLER_HOME);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const formIsValid = validateForm();
    console.log("formIsValid", formIsValid);

    const paymentToken = await stripe.createToken(
      elements.getElement(CardElement)
    );
    console.log("paymentToken", paymentToken);
    if (paymentToken?.error) {
      setErrors((prev) => ({
        ...prev,
        stripeError: paymentToken?.error?.message,
      }));
      return;
    }

    if (!formIsValid && paymentToken?.error) {
      return;
    }

    mutation.mutate(paymentToken.token.id);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        {/* <PaymentElement /> */}

        <Box style={styles.cardElementWrapper}>
          <CardElement options={cardElementOptions} />
        </Box>

        <FormHelperText sx={styles.errorMessage}>
          {errors?.stripeError}
        </FormHelperText>
        <Box mt={2} sx={{ textAlign: "start" }}>
          <LoadingButton
            variant="contained"
            disabled={!stripe || !elements}
            sx={styles.nextBtn}
            type="submit"
            isLoading={mutation.isLoading}
          >
            Checkout
            {`${
              isPersonalCard
                ? ""
                : `(${orderCardsData?.orderCards?.length} / ${totalCards})`
            }`}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default CheckOutStep;
