import { useState } from "react";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import PageTitle from "../../../components/common/PageTitle";
import {
  Box,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Button,
} from "@mui/material";
import BasicIcon from "../../../assets/svg/BasicIcon.svg";
import ProIcon from "../../../assets/svg/ProIcon.svg";
import PremiumIcon from "../../../assets/svg/PremiumIcon.svg";
import StripeModal from "../../../components/modal/StripeModal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { get,post } from "../../../server";
import { SUBSCRIPTION_LISTING,PURCHASE_SUBSCRIPTION,CUSTOMER_PROFILE_GET } from "../../../data/constants/apiRoutes";
import Loader from "../../../components/Loader";
import { useSnackbar } from "notistack";
import checkImage from "../../../assets/images/white-check.png";
import _ from "lodash";
const styles = {
  card: { bgcolor: "#fff", px: 2, py: 3, borderRadius: 2 },
  header: {
    bgcolor: "#135099",
    p: 1,
    borderRadius: 2,
    maxWidth: 120,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: 1,
    mb: 2,
    "& p": {
      color: "#fff",
      fontWeight: 500,
      fontSize: 16,
    },
    "& img": {
      width: 15,
      height: 15,
    },
  },
  box: {
    width: "70px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    bgcolor: "#135099",
    "& img": {
      width: 25,
      height: 25,
    },
  },
  price: {
    fontSize: 22,
    fontWeight: 700,
    textAlign: "center",
    color: "#135099",
    "& span": {
      fontSize: 22,
      fontWeight: 500,
    },
  },
  list: {
    width: "100%",
    listStyleType: "disc",
    pl: 2,
    "& .MuiListItem-root": {
      display: "list-item",
      paddingLeft: "0px",
      color: "#135099",
    },
    "& h6": {
      fontWeight: 500,
    },
  },
  btnWrapper: {
    borderTop: "1px solid #636363",
    pt: 2,
  },
  btn: {
    bgcolor: "#135099",
    textTransform: "capitalize",
    height: 45,
    boxShadow: "none",
    fontWeight: 600,

    "&:hover": {
      bgcolor: "#135099",
    },
  },
};

const subscriptions = [
  {
    id: 1,
    package_name: "Basic",
    price_monthly: 0,
    price_yearly: 0,
    cards: 1,
    community: "Yes",
    e_commerce: "No",
  },
  {
    id: 2,
    package_name: "Premium",
    price_monthly: 4.99,
    price_yearly: 55,
    cards: 5,
    community: "Yes",
    e_commerce: "Yes",
  },
  {
    id: 3,
    package_name: "Corporate",
    price_monthly: 6.99,
    price_yearly: 80,
    cards: "Unlimited",
    community: "Yes",
    e_commerce: "Yes",
  },
];

const Subscription = () => {
  const [modal, setModal] = useState(false);
  const [freePackage, setFreePackage] = useState(false);
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const [open, setOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (subscriptionData, isYearlySubscription) => {
    setSelectedSubscription({
      ...subscriptionData,
      isYearlySubscription: isYearlySubscription,
    });

    if(loggedUser.data.package_id > subscriptionData.id) {
      setModal(true);
    } else {
      setOpen(true);
    }
  };
  
  const handleFreePurchae = (subscriptionData, isYearlySubscription) => {
    setSelectedSubscription({
      ...subscriptionData,
      isYearlySubscription: isYearlySubscription,
    });
    
    if(loggedUser.data.package_id > subscriptionData.id) {
      setFreePackage(true);
      setModal(true);
    };
  };

  const handleClose = () => {
    setSelectedSubscription({});
    setOpen(false);
  };

  const fetchSubscriptions = async () => {
    const { status, message, data } = await get(SUBSCRIPTION_LISTING);
    if (status) {
      setSubscriptions(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const subsriptionQuery = useQuery(
    queryNames.SUBSCRIPTION,
    fetchSubscriptions,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (subsriptionQuery.isFetching) {
    return <Loader />;
  }

  // OPEN CONFIRM MODAL
  const handleConfirm = async (e) => {
    e.preventDefault();
    setModal(close);
    if (selectedSubscription.id === 1) {
      // purchase free package
      const { status, message, data } = await post(PURCHASE_SUBSCRIPTION, {
        package_id: selectedSubscription.id,
        total_price: 0,
        stripe_token: null,
        is_downgrade:selectedSubscription.id < loggedUser.data.package_id ? true : false
      });
  
      if (status) {
        enqueueSnackbar(message, { variant: "success" });

        const userData = await get(CUSTOMER_PROFILE_GET);
        let user = _.cloneDeep(loggedUser);
        user.data.package = userData.data.package;
        user.data.package_id = userData.data.package_id;
        setLoggedUser(user);
        handleClose();
        setSelectedSubscription({});
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } else {
      setOpen(true);
    }
  };
  
  // CLOSE CONFIRM MODAL
  const handleConfirmationClose = (e) => {
    e.preventDefault();
    setSelectedSubscription({});
    setModal(false);
  };

  return (
    <>
      <PageTitle title="Subscriptions" />
      <Box mt={1} mb={3}>
        <Grid container spacing={2}>
          {subscriptions.map((subscription) => {
            return (
              <Grid item md={4} xs={12} key={subscription.id}>
                <Box sx={styles.card}>
                  <Box sx={styles.header}>
                    <Typography component="p">
                      {subscription.package_name}
                    </Typography>
                    {/* <Box
                      component="img"
                      src={
                        subscription.package_name === "Basic"
                          ? BasicIcon
                          : subscription.package_name === "Premium"
                          ? ProIcon
                          : PremiumIcon
                      }
                    /> */}
                  </Box>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    mb={1}
                  >
                    <Box sx={styles.box}>
                      <Box
                        component="img"
                        src={
                          subscription.package_name === "Basic"
                            ? BasicIcon
                            : subscription.package_name === "Premium"
                            ? ProIcon
                            : PremiumIcon
                        }
                      />
                    </Box>
                  </Stack>
                  <Typography component="h5" sx={styles.price}>
                    {subscription.price_monthly === 0
                      ? "Free"
                      : `$ ${subscription.price_monthly}`}
                    {subscription.package_name === "Basic" ? null : (
                      <Box component="span"> / month</Box>
                    )}
                  </Typography>

                  <List sx={styles.list}>
                    <ListItem disablePadding mb={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography component="h6">KnocKnoc Card</Typography>
                        <Typography textTransform="capitalize">
                          {subscription.limit_card_create}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem disablePadding mb={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography component="h6">Community</Typography>
                        <Typography>
                          {subscription.community === 1 ? "Yes" : "No"}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem disablePadding mb={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography component="h6">E-Commerce</Typography>
                        <Typography>
                          {subscription.e_commerce === 1 ? "Yes" : "No"}
                        </Typography>
                      </Stack>
                    </ListItem>

                    <ListItem disablePadding mb={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography component="h6">Team</Typography>
                        <Typography>
                          {subscription.teams === 1 ? "Yes" : "No"}
                        </Typography>
                      </Stack>
                    </ListItem>
                    <ListItem disablePadding mb={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography component="h6">
                          Video Attachment
                        </Typography>
                        <Typography>
                          {subscription.video_capacity === 1 ? "Yes" : "No"}
                        </Typography>
                      </Stack>
                    </ListItem>
                  </List>
                  <Box mt={1} sx={styles.btnWrapper}>

                    {
                      loggedUser?.data?.package_id === subscription.id ?
                      <>
                        <Button
                        variant="contained"
                        fullWidth
                        sx={styles.btn}
                        >
                          <span>Active</span> &nbsp;
                          <img src={checkImage} alt="" />
                        </Button>
                      </> 
                      : subscription.package_name === "Basic" ? 
                      <Button
                        variant="contained"
                        fullWidth
                        sx={styles.btn}
                        onClick={() => handleFreePurchae(subscription, false)}
                      > Enable Free Mode</Button>
                      : <Button
                      variant="contained"
                      fullWidth
                      sx={styles.btn}
                      onClick={() => handleOpen(subscription, false)}
                    > Buy Now</Button>
                    }
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <StripeModal
        open={open}
        handleClose={handleClose}
        plan={selectedSubscription}
      />

      <ConfirmationModal
        header="Downgrade Package"
        title={` If you choose to downgrade, please note that any excess functions beyond the limit of the new package will be deleted permanently.To proceed with the package downgrade, click confirm`}
        confirmButton="Confirm"
        confirmButtonColor="#E53935"
        open={modal}
        handleClose={handleConfirmationClose}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default Subscription;
