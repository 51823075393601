import {
	createRoutesFromElements,
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import CustomerRoutes from "./data/routes/CustomerRoutes";
import AdminRoutes from "./data/routes/AdminRoutes";
import MiscRoutes from "./data/routes/MiscRoutes";  
import { useAtom } from "jotai";
import { mobileViewAtom,loggedUserAtom,appPackagesAtom,featureModal} from "./data/store";
import { useEffect } from "react";
import {get } from "./server";
import {
	CUSTOMER_PROFILE_GET,
	SUBSCRIPTION_LISTING
  } from "./data/constants/apiRoutes";
  import _ from "lodash";
import { useState } from "react";
import FeatureModal from "./components/modal/FeatureModal";
import { useSnackbar } from "notistack";

const router = createBrowserRouter(
	createRoutesFromElements([AdminRoutes, CustomerRoutes, MiscRoutes])
);

function App() {
	const [mobileView, setMobileView] = useAtom(mobileViewAtom);
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const [packages, setPackages] = useAtom(appPackagesAtom);
	const [globalFeatureModal, setGlobalFeatureModal] = useAtom(featureModal);
	const [openModal, setModalOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const resizeFunction = () => {
		if (window.innerWidth >= 900) {
			setMobileView(false);
		} else {
			setMobileView(true);
		}
	};

	useEffect(() => {
		// set global feature modal empty
		setGlobalFeatureModal(false);
		window.addEventListener("resize", resizeFunction);
		if (window.innerWidth >= 960) {
			setMobileView(false);
		} else {
			setMobileView(true);
		}
		return function cleanup() {
			window.removeEventListener("resize", resizeFunction);
		};
	}, []);

	useEffect(() => {
		if (loggedUser && Object.keys(loggedUser).length > 0) {
			refreshUser();
			getPackagesListing();
		}
	}, [loggedUser?.data?.id]);
	
	useEffect(() => {
		if(globalFeatureModal == true){
			// enqueueSnackbar("The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features", { variant: "error" });
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [globalFeatureModal]);

	const refreshUser = async () => {
		const { status, message, data } = await get(CUSTOMER_PROFILE_GET);
		let user = _.cloneDeep(loggedUser);
		user.data = data;
		setLoggedUser(user);
	};

	const getPackagesListing = async () => {
		const { status, message, data } = await get(SUBSCRIPTION_LISTING);
		if (status) {
			setPackages(data);
		}
	};
	
	const handleClose = () => {
		setGlobalFeatureModal(false);
	};

	// const handleUpgrade = () => {

	// };
	return(
		<>
	<RouterProvider router={router} />
	<FeatureModal
		header="Feature Not Available"
		title={`The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features`}
		confirmButton="Confirm"
		confirmButtonColor="#E53935"
		open={openModal}
		handleClose={handleClose}
		// handleConfirm={""}
	/>
		</>
	)
}

export default App;
