import Joi from "joi";

export const ProductSchema = Joi.object({
    name: Joi.string().required().messages({
        "string.empty": "Product name is required",
        "any.required": "Product name is required",
    }),
    brand: Joi.string().required().messages({
        "string.empty": "Product brand is required",
        "any.required": "Product brand is required",
    }),
    description: Joi.string().max(2000).required().messages({
        "string.empty": "Product description is required",
        "any.required": "Product description is required",
        "string.max": "Product description should be less than 2000 characters",
    }),
    price: Joi.number().required().messages({
        "number.empty": "Product price is required",
        "number.base": "Product price is required",
        "any.required": "Product price is required",
    }),
    product_url: Joi.string().required().messages({
        "string.empty": "Product url is required",
        "any.required": "Product url is required",
    }),
    // category_id: Joi.number().required().messages({
    //     "number.empty": "Please select a product category",
    //     "number.base": "Please select a product category",
    //     "any.required": "Please select a product category",
    // }),
    // colors: Joi.array().min(1).required().messages({
    //     "array.min": "Product color is required",
    //     "any.required": "Product color is required",
    // }),
});