import React, { useState } from "react";
import {
	AppBar,
	Stack,
	Avatar,
	Menu,
	MenuItem,
	IconButton,
	Toolbar,
	Typography,
	Button,
	Box,
	Badge,
	ListItem,
	List,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Contact } from "../assets/svg/navbarIcons/Contact.svg";
import { ReactComponent as Team } from "../assets/svg/navbarIcons/Team.svg";
import { ReactComponent as ExpandLess } from "../assets/svg/navbarIcons/ExpandLess.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ReactComponent as Cart } from "../assets/svg/navbarIcons/Cart.svg";
import { useAtom } from "jotai";
import { cartAtom, loggedUserAtom } from "../data/store";
import CartModal from "../components/modal/CartModal";
import routes from "../data/constants/routes";
import {SUBSCRIPTION_KEY} from "../data/constants/feature";
import { get, post } from "../server";
import {
	NOTIFICATION_LISTING,
	NOTIFICATION_READ,
} from "../data/constants/apiRoutes";
import { useQuery, useQueryClient } from "react-query";
import queryNames from "../data/constants/queryNames";
import moment from "moment";
import useFeature from "../hooks/useFeature";
import { ContactPageSharp } from "@mui/icons-material";

const messages = [
	{
		id: 1,
		name: "Brunch this week?",
		desc: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
		person: "/static/images/avatar/5.jpg",
	},
	{
		id: 2,
		name: "Birthday Gift",
		desc: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
		person: "/static/images/avatar/1.jpg",
	},
	{
		id: 3,
		name: "Recipe to try",
		desc: "I am try out this new BBQ recipe, I think this might be amazing",
		person: "/static/images/avatar/2.jpg",
	},
	{
		id: 4,
		name: "Yes!",
		desc: "I have the tickets to the ReactConf for this year.",
		person: "/static/images/avatar/3.jpg",
	},
];

const styles = {
	appbar: {
		boxShadow: "0px 0px 0px #E6EAF2;",
		backgroundColor: "#F9FAFC",
	},
	searchInput: {
		maxWidth: 500,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: "6px",
		"& .MuiOutlinedInput-notchedOutline": {
			borderRadius: "6px",
			border: 0,
		},
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#2B2A69 !important",
			borderWidth: "1px !important",
		},
		"& input": {
			py: "13px",
			px: "16px",
			"&::placeholder": {
				color: "#767D90",
				fontWeight: 400,
				opacity: 1,
				fontSize: 14,
			},
		},
	},
	menuBtn: {
		transition: "all 0.2s ease",
		cursor: "pointer",
		borderRadius: "5px",
		px: "8px",
		"& .MuiAvatar-root": {
			width: 36,
			height: 36,
		},
		// "&:hover": {
		// 	backgroundColor: "rgba(0, 0, 0, 0.04)",
		// },
		flexDirection: "row",
		gap: { md: 1.5, xs: 1 },
		alignItems: "center",
		"& h3": {
			fontSize: 14,
			fontWeight: 600,
			color: "#1E1E1F",
			textWrap: "nowrap",
		},
		"& h6": {
			fontSize: 12,
			color: "#767D90",
		},
	},
	buttons: {
		btn: {
			maxHeight: 40,
			bgcolor: "#FFFFFF",
			color: "#1E1E1F",
			fontWeight: 500,
			textTransform: "capitalize",
			borderRadius: "10px",
			boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.06)",
			fontSize: { md: 13, sm: 13, xs: 13 },
			"& span": {
				whiteSpace: "nowrap",
				display: { md: "block", xs: "none" },
			},
			"& svg": {
				height: 17,
				marginRight: { md: "10px", xs: 0 },
				fill: "#135099",
			},
			"&:hover": {
				bgcolor: "#FFFFFF",
				color: "#1E1E1F",
			},
		},

		activeBtn: {
			maxHeight: 40,
			bgcolor: "#D3A23B",
			color: "#fff",
			fontWeight: 500,
			textTransform: "capitalize",
			borderRadius: "10px",
			boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.06)",
			fontSize: { md: 13, sm: 13, xs: 13 },
			"& span": {
				whiteSpace: "nowrap",
				display: { md: "block", xs: "none" },
			},
			"& svg ": {
				height: 17,
				marginRight: { md: "10px", xs: 0 },
			},
			"& svg > path": {
				height: 17,
				marginRight: "10px",
				fill: "#fff",
			},
			"&:hover": {
				bgcolor: "#D3A23B",
				color: "#fff",
			},
		},

		// "& button": {
		//   maxHeight: 40,
		//   bgcolor: "#FFFFFF",
		//   color: "#1E1E1F",
		//   fontWeight: 500,
		//   textTransform: "capitalize",
		//   borderRadius: "10px",
		//   boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.06)",
		//   fontSize: { md: 13, sm: 13, xs: 13 },
		//   "& span": {
		//     whiteSpace: "nowrap",
		//   },
		//   "& svg": {
		//     height: 17,
		//     marginRight: "10px",
		//     fill: "#135099",
		//   },
		//   "&:hover": {
		//     bgcolor: "#FFFFFF",
		//     color: "#1E1E1F",
		//   },
		// },
	},
	cart: {
		bgcolor: "#FFFFFF",
		height: { md: 45, xs: 35 },
		width: { md: 45, xs: 35 },
	},
	notificationMenu: {
		mt: 5,
		"& .MuiListItem-root": {
			py: 0,
			pb: 1,
			borderBottom: "1px solid #E0E0E0",
			"&:last-child": {
				borderBottom: "none",
			},
			"&:nth-last-child(3)": {
				borderBottom: "none",
			},
		},
		"& .MuiMenu-paper": {
			borderRadius: 1,
		},
		"& .MuiMenu-list": {
			width: 320,
			maxHeight: 340,
			"& p": {
				display: "-webkit-box",
				"-webkitLineClamp": "2",
				"-webkitBoxOrient": "vertical",
				overflow: "hidden",
			},
		},
		"& label": {
			color: "#1E1E1F",
			fontWeight: 500,
			px: 2,
			cursor: "pointer",
		},
	},
};

const settings = ["Profile", "Logout"];

function Header({ drawerWidth, mobileOpen, setMobileOpen }) {
	const [cart, setCart] = useAtom(cartAtom);
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const [modal, setModal] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const { compareLimits} = useFeature();

	const client = useQueryClient();
	const { pathname } = useLocation();

	const [profileMenu, setProfileMenu] = useState(null);
	const [notificationMenu, setNotificationMenu] = useState(false);
	const navigate = useNavigate();

	const handleOpenProfileMenu = (e) => {
		setProfileMenu(e.currentTarget);
	};

	const handleCloseProfileMenu = () => {
		setProfileMenu(null);
	};

	const handleOpenNotificationMenu = (e) => {
		setNotificationMenu(e.currentTarget);
		handleNotificationRead();
	};

	const handleCloseNotificationMenu = () => {
		setNotificationMenu(null);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	styles.appbar.width = { sm: `calc(100% - ${drawerWidth}px)` };
	styles.appbar.ml = { sm: `${drawerWidth}px` };

	const handleCartOpen = () => {
		setModal(true);
	};

	const handleLogout = (setting) => {
		if (setting === "Logout") {
			setLoggedUser({});
			navigate(routes.LOGIN);
		} else if (setting === "Profile") {
			navigate(routes.CUSTOMER_PROFILE);
		}
	};

	const handleNotificationRead = async () => {
		const unReadNotifications = notifications
			.filter((notification) => notification.is_read === false)
			.map((notification) => notification.id);
		const { status, message, data } = await post(NOTIFICATION_READ, {
			notificationIds: unReadNotifications,
		});
		if (status) handleInvalidate();
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.NOTIFICATION);
	};

	const fetchNotification = async () => {
		let url;
		url = new URL(NOTIFICATION_LISTING);
		url.searchParams.append("page", 1);
		url.searchParams.append("limit", 5);

		const { status, message, data } = await get(url.toString());
		if (status) {
			setNotifications(data.data);
		}
	};

	const notificationQuery = useQuery(
		queryNames.NOTIFICATION,
		fetchNotification,
		{
			enabled: loggedUser?.data?.is_superadmin !== "1",
			refetchInterval: 30000,
		}
	);

	const handleReadAllNotifications = async () => {
		const unReadNotifications = notifications
			.filter((notification) => notification.is_read === false)
			.map((notification) => notification.id);
		const { status, message, data } = await post(NOTIFICATION_READ, {
			notificationIds: unReadNotifications,
		});
		if (status) handleInvalidate();
	};

	const handleClick = (type) => {
		if (type === "order" || type === "order_created")
			navigate(routes.CUSTOMER_CARD_ORDER_HISTORY);
		else if (type === "added_to_team")
			navigate(`${routes.CUSTOMER_TEAMS}?type=myCard`);
		else if (type === "general") navigate(routes.CUSTOMER_NOTIFICATION);
	};

	const handleCheckAccess = (path,type) => {
		let res = compareLimits(type);
		if(res) {
			navigate(path)
		} else {
			return false;
		}
	};

	return (
		<>
			<AppBar position="fixed" sx={styles.appbar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{
							mr: { md: 2, xs: 0 },
							display: { sm: "none" },
							color: "black",
						}}
					>
						<MenuIcon />
					</IconButton>

					<Stack
						direction="row"
						justifyContent={
							loggedUser?.data?.is_superadmin === "1"
								? "flex-end"
								: "space-between"
						}
						width="100%"
						spacing={2}
					>
						{loggedUser?.data?.is_superadmin !== "1" && (
							<Stack
								direction="row"
								spacing={{ md: 2.5, xs: 1 }}
								sx={styles.buttons}
								flex={1}
							>
								<Button
									variant="contained"
									onClick={() => handleCheckAccess(routes.CUSTOMER_CONNECTIONS,SUBSCRIPTION_KEY.community)}
									sx={
										pathname.includes("connection")
											? styles.buttons.activeBtn
											: styles.buttons.btn
									}
								>
									<Contact />
									<Box component="span">Your Community</Box>
								</Button>

								<Button
									variant="contained"
									onClick={() => navigate(routes.CUSTOMER_TEAMS)}
									// onClick={() => handleCheckAccess(routes.CUSTOMER_TEAMS,SUBSCRIPTION_KEY.team)}
									sx={
										pathname.includes("team")
											? styles.buttons.activeBtn
											: styles.buttons.btn
									}
								>
									<Team />
									<Box component="span">Team</Box>
								</Button>
							</Stack>
						)}

						<Stack direction="row" alignItems="center" spacing={1}>
							{/* <Box>
								<Cart />
							</Box> */}

							{loggedUser?.data?.is_superadmin !== "1" && cart?.length > 0 && (
								<Badge
									badgeContent={cart.length}
									color="primary"
									sx={{
										mr: 2,
										"& .MuiBadge-badge": {
											transform: "scale(1) translate(25%, 25%)",
										},
									}}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
								>
									<IconButton sx={styles.cart} onClick={handleCartOpen}>
										<Cart />
									</IconButton>
								</Badge>
							)}

							{/* <Box position="relative"> */}
							{loggedUser?.data?.role?.includes('customer') &&
								notifications.length > 0 && (
									<IconButton
										size="large"
										aria-label={`show ${notifications.length} new notifications`}
										color="inherit"
										onClick={handleOpenNotificationMenu}
									>
										<Badge
											badgeContent={
												notifications.filter(
													(notification) => notification.is_read === false
												).length
											}
											color="error"
										>
											<NotificationsIcon sx={{ color: "#1976d2" }} />
										</Badge>
									</IconButton>
								)}

							<Menu
								sx={styles.notificationMenu}
								id="menu-appbar"
								anchorEl={notificationMenu}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(notificationMenu)}
								onClose={handleCloseNotificationMenu}
							>
								<Box>
									<Typography variant="h6" sx={{ px: 2, py: 0.5 }}>
										Notifications
									</Typography>
								</Box>
								{notifications?.map(
									({ id, title, description, person, type, created_at }) => (
										<Box key={id} position="relative">
											<ListItem button onClick={() => handleClick(type)}>
												<ListItemAvatar>
													<Avatar alt="Profile Picture" src={person} />
												</ListItemAvatar>
												<ListItemText primary={title} secondary={description} />
											</ListItem>
											<Typography
												component="label"
												sx={{
													fontSize: 11,
													fontWeight: 400,
													color: "rgba(0, 0, 0, 0.6)",
													position: "absolute",
													right: 0,
													bottom: 3,
												}}
											>
												{moment(created_at).fromNow()}
											</Typography>
										</Box>
									)
								)}
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									sx={
										{
											// position: "absolute",
											// bottom: 0,
											// left: 0,
											// width: "100%",
											// backgroundColor: "#fff",
											// padding: "8px",
											// display: "flex",
											// justifyContent: "space-between",
											// zIndex: 1,
										}
									}
								>
									<Typography
										component="label"
										onClick={() => {
											handleReadAllNotifications();
										}}
									>
										Read All
									</Typography>
									<Typography
										component="label"
										onClick={() => {
											navigate(routes.CUSTOMER_NOTIFICATION);
											handleCloseNotificationMenu();
										}}
									>
										View All
									</Typography>
								</Stack>
							</Menu>

							{/* </Box> */}

							<Stack onClick={handleOpenProfileMenu} sx={styles.menuBtn}>
								<Avatar
								// alt="Admin-profile"
								// src="/static/images/avatar/2.jpg"
								/>
								<ExpandLess />
							</Stack>

							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={profileMenu}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(profileMenu)}
								onClose={handleCloseProfileMenu}
							>
								{settings.map((setting) => (
									<MenuItem key={setting} onClick={handleCloseProfileMenu}>
										<Typography
											textAlign="center"
											onClick={() => handleLogout(setting)}
										>
											{setting}
										</Typography>
									</MenuItem>
								))}
							</Menu>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>

			<CartModal modal={modal} setModal={setModal} />
		</>
	);
}

export default Header;
