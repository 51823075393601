const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const PUBLIC_URL = import.meta.env.VITE_API_PUBLIC_URL;

const ADMIN = "admin";
const USER = "user";
const LOGIN = "login";
const SIGNUP = "signup";
const FORGET_PASSWORD = "forget-password";
const FORGOT_PASSWORD = "forgot-password";
const RESET_PASSWORD = "reset-password";
const VERIFY = "verify";
const OTP = "otp";
const DETAIL = "detail";
const UPDATE = "update";
const PROFILE = "profile";
const UPLOAD_FILE = "upload-file";
const CARD = "card";
const DUPLICATE = "duplicate";
const IMAGE_UPLOAD = "imageUpload";
const SETTING = "/setting";
const PAGE = "/page";
const CONTACTUS = "/contactUs";
const CONTACT = "contact";
const CHANGE_STATUS = "/changeStatus";
const CHANGE_PASSWORD = "/changePassword";
const USER_NOTIFICATION = "/userNotification";
const COUSTOMER = "customer";
const PRODUCT = "product";
const ALL = "all";
const ADD_OR_UPDATE = "addOrUpdate";
const DELETE = "delete";
const CATEGORY = "category";
const SHARE = "share";
const FORM = "form";
const REGISTERATION = "registration";
const SAVE = "save";
const TEAM = "team";
const LISTING = "listing";
const SEARCH = "search";
const MEMBER = "member";
const GENERAL = "general";
const NOTIFICATIONS = "notifications";
const PRICING = "pricing";
const ORDER = "order";
const ALLORDERS = "allOrders";
const PURCHASE = "purchase";

//  Order

export const CUSTOMER_ORDER_DETAIL = `${BASE_URL}/${ORDER}`;
export const CUSTOMER_ORDER_PLACE = `${BASE_URL}/${ORDER}/place`;
export const CUSTOMER_ALL_ORDERS = `${BASE_URL}/${ORDER}/${ALLORDERS}`;
export const CUSTOMER_CHANGE_ORDER_STATUS = `${BASE_URL}/${ORDER}/change/status`;
export const CUSTOMER_EXPORT_ORDERS = `${BASE_URL}/exportWithImages`;

// UPLOAD AND GET IMAGE
export const UPLOAD_IMAGE = `${BASE_URL}/${UPLOAD_FILE}`;

export const IMAGE_UPLOAD_URL = `${BASE_URL}/${IMAGE_UPLOAD}`;

export const GET_IMAGE = `${PUBLIC_URL}/`;

export const GET_LISTING_BG = `${PUBLIC_URL}`;

// CUSTOMER AUTH

export const CUSTOMER_LOGIN = `${BASE_URL}/${LOGIN}`;

export const CUSTOMER_SIGNUP = `${BASE_URL}/${SIGNUP}`;

export const CUSTOMER_FORGET_PASSWORD = `${BASE_URL}/${FORGET_PASSWORD}`;

export const CUSTOMER_FORGET_PASSWORD_VERIFY_OTP = `${BASE_URL}/${FORGOT_PASSWORD}/${VERIFY}/${OTP}`;

export const CUSTOMER_RESET_PASSWORD = `${BASE_URL}/${RESET_PASSWORD}`;
export const CUSTOMER_CHANGE_PASSWORD = `${BASE_URL}${SETTING}${CHANGE_PASSWORD}`;
export const CUSTOMER_USER_NOTIFICATION = `${BASE_URL}${SETTING}${USER_NOTIFICATION}`;

export const CUSTOMER_VERIFY_EMAIL = `${BASE_URL}/${VERIFY}-${OTP}`;

export const CUSTOMER_RESEND_OTP = `${BASE_URL}/resend-otp`;

// PROFILE

export const CUSTOMER_PROFILE_GET = `${BASE_URL}/${USER}/${DETAIL}`;

export const CUSTOMER_PROFILE_UPDATE = `${BASE_URL}/${UPDATE}/${PROFILE}`;

// ADMIN AUTH

export const ADMIN_LOGIN = `${BASE_URL}/adminLogin`;
export const ADMIN_CHANGE_STATUS = `${BASE_URL}${SETTING}${CONTACTUS}${CHANGE_STATUS}`;
export const ADMIN_COUSTOMER_LISTING = `${BASE_URL}/${ADMIN}/${COUSTOMER}`;
export const ADMIN_COUSTOMER_ADD = `${BASE_URL}/${ADMIN}/${COUSTOMER}`;
export const ADMIN_COUSTOMER_EDIT = `${BASE_URL}/${ADMIN}/${COUSTOMER}`;
export const ADMIN_COUSTOMER_DETAIL = `${BASE_URL}/${ADMIN}/${COUSTOMER}`;
export const ADMIN_COUSTOMER_DELETE = `${BASE_URL}/${ADMIN}/${COUSTOMER}`;
export const ADMIN_COUSTOMER_CHANGE_STATUS = `${BASE_URL}/${ADMIN}/${COUSTOMER}${CHANGE_STATUS}`;

// BUSINESS CARDS

export const CARDS_LISTING = `${BASE_URL}/${CARD}`;
export const CARDS_LISTING_DUPLICATE = `${BASE_URL}/${CARD}/${DUPLICATE}`;
export const CARDS_DELETE = `${BASE_URL}/${CARD}`;
export const CARDS_DETAIL = `${BASE_URL}/${CARD}`;
export const CARDS_CREATE = `${BASE_URL}/${CARD}`;

export const SAVE_CARD = `${BASE_URL}/createVCFFile`;

// Common Routes
export const PAGES = `${BASE_URL}${SETTING}${PAGE}`;
export const CONTACT_US = `${BASE_URL}${SETTING}${CONTACTUS}`;

// Public Routes
export const SHARE_CARD = `${BASE_URL}/${SHARE}/${CARD}`;
// Color Listing

export const COLORS = `${BASE_URL}/colors`;

//PRODUCT

export const PRODUCT_LISTING = `${BASE_URL}/${PRODUCT}/${LISTING}`;

export const PRODUCT_DETAIL = `${BASE_URL}/${PRODUCT}`;

export const PRODUCT_CREATE = `${BASE_URL}/${PRODUCT}/${ADD_OR_UPDATE}`;

export const PRODUCT_UPDATE = `${BASE_URL}/${PRODUCT}/${ADD_OR_UPDATE}`;

export const PRODUCT_DELETE = `${BASE_URL}/${PRODUCT}/${DELETE}`;

export const PRODUCT_STATUS = `${BASE_URL}/${PRODUCT}${CHANGE_STATUS}`;

export const PRODUCT_CUSTOMER_LISTING = `${BASE_URL}/${PRODUCT}/allProducts`;
export const POPULAR_PRODUCT_CUSTOMER_LISTING = `${BASE_URL}/${PRODUCT}/popularProducts`;

//CATEGORIES

export const CATEGORY_LISTING = `${BASE_URL}/${CATEGORY}/${ALL}`;

export const CATEGORY_DETAIL = `${BASE_URL}/${CATEGORY}`;

export const CATEGORY_CREATE = `${BASE_URL}/${CATEGORY}/${ADD_OR_UPDATE}`;

export const CATEGORY_UPDATE = `${BASE_URL}/${CATEGORY}/${ADD_OR_UPDATE}`;

export const CATEGORY_DELETE = `${BASE_URL}/${CATEGORY}/${DELETE}`;

//Register Form

export const REGISTER_FORM = `${BASE_URL}/${FORM}/${REGISTERATION}`;

export const SAVE_CONTACT = `${BASE_URL}/${CARD}/${SAVE}/${CONTACT}`;

export const MY_CARD_EXISTS = `${BASE_URL}/${CARD}/exist`;

// CONNECTIONS

export const COMMUNITY_LISTING = `${BASE_URL}/${CONTACT}`;

export const COMMUNITY_DETAIL = `${BASE_URL}/${CONTACT}`;

export const COMMUNITY_DELETE = `${BASE_URL}/${CONTACT}`;

//TEAMS

export const TEAM_LISTING = `${BASE_URL}/${TEAM}/${LISTING}`;

export const TEAM_ADD = `${BASE_URL}/${TEAM}/${ADD_OR_UPDATE}`;

export const TEAM_UPDATE = `${BASE_URL}/${TEAM}/${ADD_OR_UPDATE}`;

export const TEAM_DETAIL = `${BASE_URL}/${TEAM}`;

export const TEAM_DELETE = `${BASE_URL}/${TEAM}`;

export const TEAM_MEMBERS = `${BASE_URL}/${SEARCH}/${MEMBER}`;

export const TEAM_LEAVE = `${BASE_URL}/${TEAM}/leave`;

export const TEAM_MY_ALL_LISTING = `${BASE_URL}/${TEAM}/${ALL}/${USER}`;

export const TEAM_REMOVE_MEMBER = `${BASE_URL}/${TEAM}/${MEMBER}/remove`;

// NOTIFICATION

export const NOTIFICATION_SEND = `${BASE_URL}/${GENERAL}/sendPushNotification`;

export const NOTIFICATION_LISTING = `${BASE_URL}/${GENERAL}/userNotification`;

export const NOTIFICATION_READ = `${BASE_URL}/${NOTIFICATIONS}/change-status`;

export const PRINT_ORDER_CARD_PRICING_LISTING = `${BASE_URL}/${PRICING}`;
export const PRINT_ORDER_CARD_PRICING_UPDATE = `${BASE_URL}/${PRICING}/${UPDATE}`;

// SUBSCRIPTION

export const SUBSCRIPTION_LISTING = `${BASE_URL}/${PURCHASE}/${LISTING}`;

export const SUBSCRIPTION_PURCHASE = `${BASE_URL}/${PURCHASE}/purchasePackage`;
export const PURCHASE_SUBSCRIPTION = `${BASE_URL}/transaction/processPayment`;
