import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import CustomInputField from "../../../components/forms/CustomInputField";
import { useNavigate, useParams } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "../../../components/button/LoadingButton";
import { useSnackbar } from "notistack";
import { get, post, put } from "../../../server";
import CustomerSchema from "../../../utils/schemas/CustomerSchema";
import { validator } from "../../../utils/helpers/validator";
import {
  ADMIN_COUSTOMER_ADD,
  ADMIN_COUSTOMER_EDIT,
  ADMIN_COUSTOMER_DETAIL,
} from "../../../data/constants/apiRoutes";
import { useMutation, useQuery } from "react-query";
import routes from "../../../data/constants/routes";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";
import _ from "lodash";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
};

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: null,
  confirm_password: null,
};

const AddEditCustomer = () => {
  const [customer, setCustomer] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { customerId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isId = customerId !== null && typeof customerId !== "undefined";

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "email") {
      if (value.length > 70) {
        value = value.slice(0, 70);
      }
    }

    setCustomer({ ...customer, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = validator(
      { id: customerId ? customerId : null, ...customer },
      CustomerSchema
    );

    if (!isId && !customer?.confirm_password) {
      newErrors = {
        ...newErrors,
        confirm_password: "Please enter confirm password",
      };
    }
    if (isId && customer?.password && customer?.password.length < 8) {
      newErrors = {
        ...newErrors,
        password: "Password must be at least 8 characters long",
      };
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleFormRequest = async () => {
    let payload = _.cloneDeep(customer);
    if (isId) {
      delete payload["confirm_password"];
    }

    const { status, message } = await (isId ? put : post)(
      isId ? `${ADMIN_COUSTOMER_EDIT}/${customerId}` : ADMIN_COUSTOMER_ADD,
      payload
    );

    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      navigate(routes.ADMIN_CUSTOMERS);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const fetchContactUsDetail = async () => {
    const { status, message, data } = await get(
      `${ADMIN_COUSTOMER_DETAIL}/${customerId}`
    );

    if (status) {
      setCustomer({
        ...customer,
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        email: data.email || "",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const singleCustomerQuery = useQuery(
    [queryNames.CUSTOMER, customerId],
    fetchContactUsDetail,
    { enabled: isId, refetchOnWindowFocus: false }
  );

  if (singleCustomerQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={`${isId ? "Edit" : "Add"} Customer`} />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInputField
                label="First Name"
                placeholder="First Name"
                name="first_name"
                value={customer.first_name}
                onChange={handleChange}
                error={errors?.first_name ? true : false}
                helperText={errors?.first_name || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputField
                type="text"
                label="Last Name"
                placeholder="Last Name"
                name="last_name"
                value={customer.last_name}
                onChange={handleChange}
                error={errors?.last_name ? true : false}
                helperText={errors?.last_name || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputField
                label="Email"
                placeholder="Email"
                name="email"
                value={customer.email}
                onChange={handleChange}
                error={errors?.email ? true : false}
                helperText={errors?.email || ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputField
                withasterisk={isId ? false : true}
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                value={customer.password || ""}
                onChange={handleChange}
                error={errors?.password ? true : false}
                helperText={errors?.password || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isId ? null : (
              <Grid item xs={12} md={6}>
                <CustomInputField
                  withasterisk={isId ? false : true}
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  name="confirm_password"
                  value={customer.confirm_password || ""}
                  onChange={handleChange}
                  error={errors?.confirm_password ? true : false}
                  helperText={errors?.confirm_password || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <LoadingButton
          variant="contained"
          disableElevation
          sx={styles.submitBtn}
          type="submit"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Save
        </LoadingButton>
      </Box>
    </>
  );
};

export default AddEditCustomer;
