import AWS from 'aws-sdk';
// import { CognitoIdentityCredentials } from 'aws-sdk';
// import { config } from "aws-sdk/global"

const awsFileUpload = async (file, folderName, setUploadProgress) => {

    const S3_BUCKET = "narebapp";
    const REGION = "us-east-2";

    AWS.config.update({
        accessKeyId: "AKIAV42YMPS76OL7LYES",
        secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const FolderName = folderName;
    const fileName = file.name;
    const objectKey = `knocknock/${FolderName}/${fileName}`;

    const params = {
        Bucket: S3_BUCKET,
        Key: objectKey,
        Body: file,
    };

    var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
            if (setUploadProgress)
                setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [file.name]: (evt.loaded * 100) / evt.total,
                }));
        })
        .promise();

    try {
        await upload;
        const objectUrl = `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${objectKey}`;
        return { status: true, location: objectUrl };
    } catch (err) {
        console.error('Error', err);
        return { status: false, location: null };
    }
}

export default awsFileUpload;
