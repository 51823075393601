import { useState, useRef, useEffect } from "react";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import { ReactComponent as ProfileCamera } from "../../../assets/svg/ProfileCamera.svg";
import { ReactComponent as Cross } from "../../../assets/svg/businesscard/Cross.svg";

import { ReactComponent as Email } from "../../../assets/svg/createCard/Email.svg";
import { ReactComponent as Phone } from "../../../assets/svg/createCard/Phone.svg";
import { ReactComponent as Check } from "../../../assets/svg/createCard/Check.svg";
import { ReactComponent as Address } from "../../../assets/svg/createCard/Address.svg";
import { ReactComponent as Website } from "../../../assets/svg/createCard/Website.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/createCard/Instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/createCard/Facebook.svg";
import { ReactComponent as LinkedIn } from "../../../assets/svg/createCard/LinkedIn.svg";
import { ReactComponent as Tiktok } from "../../../assets/svg/createCard/Tiktok.svg";
import { ReactComponent as PInterest } from "../../../assets/svg/createCard/PInterest.svg";
import { ReactComponent as Threads } from "../../../assets/svg/createCard/Threads.svg";
import { ReactComponent as Clubhouse } from "../../../assets/svg/createCard/Clubhouse.svg";
import { ReactComponent as ColorPickker } from "../../../assets/svg/createCard/ColorPickker.svg";
import { ReactComponent as Vimeo } from "../../../assets/svg/createCard/Vimeo.svg";
import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import PageTitle from "../../../components/common/PageTitle";
import CropAvatar from "../../../components/modal/CropAvatar";
import AddIcon from "@mui/icons-material/Add";
import { useJsApiLoader } from "@react-google-maps/api";
import LocationAutoComplete from "../../../components/forms/LocationAutoComplete";

import Template1 from "../../../assets/images/Temp1.png";
import Template2 from "../../../assets/images/Temp2.png";
import Template3 from "../../../assets/images/Temp3.png";
import Template4 from "../../../assets/images/Temp4.png";

import { validator } from "../../../utils/helpers/validator";
import {
  FirstStepSchema,
  ThirdStepSchema,
  FourthStepSchema,
} from "../../../utils/schemas/CardSchema";
import CheckIcon from "@mui/icons-material/Check";

import {
  Stack,
  Box,
  Grid,
  Divider,
  Typography,
  Fab,
  Button,
  IconButton,
  MenuItem,
} from "@mui/material";
import { styled, lighten, darken } from "@mui/material/styles";
import CustomInputField from "../../../components/forms/CustomInputField";
import PhoneNumberInput from "../../../components/forms/PhoneNumberInput";
import _, { set } from "lodash";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { get, post, put } from "../../../server";
import {
  CARDS_DETAIL,
  CARDS_CREATE,
  TEAM_LISTING,
  TEAM_MY_ALL_LISTING,
} from "../../../data/constants/apiRoutes";
import routes from "../../../data/constants/routes";
import CloseIcon from "@mui/icons-material/Close";
import { Close, RemoveRedEyeOutlined } from "@mui/icons-material";
import Loader from "../../../components/Loader";
import { useAtom } from "jotai";
import { loggedUserAtom, mobileViewAtom } from "../../../data/store";
import styles from "../../../assets/styles/common/createCardAndConnection.styles";
import {
  coverImages,
  logoImages,
  PrimaryColor,
  tabs,
} from "../../../data/constants/createCardAndConnectionData";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import ColorPickerModal from "../../../components/modal/ColorPickerModal";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import FormAutoComplete from "../../../components/forms/FormAutoComplete";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import blueLogo from "../../../assets/images/LOGO.png";
import whiteLogo from "../../../assets/images/shareLogo.png";
import SelectInput from "../../../components/forms/SelectInput";
import ReactImageAppear from "react-image-appear";
import useFeature from "../../../hooks/useFeature";
import { SUBSCRIPTION_KEY } from "../../../data/constants/feature";

const schemas = [FirstStepSchema, null, ThirdStepSchema, FourthStepSchema];

const drawerWidth = 350;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: { md: -drawerWidth, xs: 0 },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const GroupHeader = styled("div")({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  backgroundColor: "#135099",
  color: "#fff",
  // color: "#1E1E1F",
  // fontWeight: 600,
});

const GroupItems = styled("ul")({
  padding: 0,
});

const items = [
  {
    id: 1,
    icon: <Email />,
    name: "Email",
  },
  {
    id: 2,
    icon: <Phone />,
    name: "Phone",
  },
  {
    id: 3,
    icon: <Website />,
    name: "Website",
  },
  {
    id: 4,
    icon: <Address />,
    name: "Address",
  },

  {
    id: 5,
    icon: <Facebook />,
    name: "Facebook",
  },
  {
    id: 6,
    icon: <LinkedIn />,
    name: "LinkedIn",
  },
  {
    id: 7,
    icon: <Instagram />,
    name: "Instagram",
  },
  {
    id: 8,
    icon: <PInterest />,
    name: "Pinterest",
  },

  {
    id: 9,
    icon: <Clubhouse />,
    name: "Clubhouse",
  },
  {
    id: 10,
    icon: <CalendarMonthIcon sx={styles.socialIconsColor} />,
    name: "Calendar",
  },
  {
    id: 11,
    icon: <Threads />,
    name: "Threads",
  },
];

const videoItems = [
  {
    id: 1,
    icon: <YouTubeIcon sx={styles.socialIconsColor} />,
    name: "YouTube",
  },
  // {
  //   id: 2,
  //   icon: <Vimeo style={{ fill: "rgb(28, 95, 174)" }} />,
  //   name: "Vimeo",
  // },
  {
    id: 3,
    icon: <Tiktok />,
    name: "TikTok",
  },
];

const initialCardState = {
  templateNo: 1,
  fullName: "",
  title: "",
  department: "",
  headline: "",
  profileImage: "",
  backgroundImage: coverImages[0].src,
  // logoImage: logoImages[0].src,
  logoImage: "",
  cardEmails: [],
  cardPhones: [],
  cardWebsites: [],
  address: "",
  company: "",
  primaryColor: PrimaryColor[1].bgcolor,
  instagram: "",
  facebook: "",
  tiktok: "",
  pinterest: "",
  threads: "",
  clubhouse: "",
  linkedin: "",
  cardName: "",
  teamId: null,
  userId: null,
};
const regexToRemoveWhiteSpaces = /^\s*$/;

const options = [
  { value: "personal", label: "Personal" },
  { value: "team", label: "Team" },
];

const CreateCard = () => {
  const [mobileView, setMobileView] = useAtom(mobileViewAtom);
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const [open, setOpen] = useState(() => (mobileView ? false : true));
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openColorPickerModal, setOpenColorPickerModal] = useState(false);
  const { compareLimits,updateCount } = useFeature();
  const [tabNo, setTabNo] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [cardID, setCardID] = useState(null);
  const [card, setCard] = useState(initialCardState);
  const [isCustomBgImg, setIsCustomBgImg] = useState(false);
  const [showItems, setShowItems] = useState({
    address: false,
    facebook: false,
    linkedin: false,
    instagram: false,
  });

  const [showVideoItems, setShowVideoItems] = useState({
    youtube: false,
    vimeo: false,
    tiktok: false,
  });
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [cardType, setCardType] = useState("personal");
  const [team, setTeam] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const profileRef = useRef();
  const logoRef = useRef();
  const customBgImgRef = useRef();

  const [profileImg, setProfileImg] = useState(null);
  const [logoImg, setLogoImg] = useState(null);

  const [newBackground, setNewBackground] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  let teamId = parseInt(searchParams.get("teamId"), 10);
  let userId = parseInt(searchParams.get("userId"), 10);
  let ownerId = parseInt(searchParams.get("ownerId"), 10);
  let type = searchParams.get("type");

  const [enableMember, setEnableMember] = useState(false);

  let editTeamId = null;
  let editUserId = null;

  const duplicate = new URLSearchParams(location.search).get("duplicate");
  const otherCard = new URLSearchParams(location.search).get("otherCard");

  const handleProfileClick = () => {
    profileRef.current.click();
  };

  const handleSizeCheck = (e) => {
    const file = e.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 20 * 1024 * 1024; // 10MB
    if (fileSizeInBytes > maxSizeInBytes) {
      alert(
        "File size exceeds the maximum allowed (20MB). Please choose a smaller file."
      );
      e.target.value = null;
    }
  };

  const handleProfileChange = (e) => {
    setProfileImg(URL.createObjectURL(e.target.files[0]));
    setModal("profileImage");
  };

  const handleProfileFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleSizeCheck;
      handleProfileChange(e);
    }
  };

  const handleLogoClick = () => {
    logoRef.current.click();
  };

  const handleRemoveLogo = () => {
    setCard({ ...card, logoImage: null });
  };

  const handleRemoveCustomBgImg = () => {
    setCard({ ...card, backgroundImage: null });
    setIsCustomBgImg(false);
    setNewBackground(null);
  };

  const handleRemoveProfile = () => {
    setCard({ ...card, profileImage: null });
  };

  const handleCustomBgImgClick = () => {
    customBgImgRef.current.click();
  };

  const handleLogoChange = (e) => {
    setCard({ ...card, logoImage: e.target.files[0] });
  };

  const handleLogoFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleSizeCheck;
      handleLogoChange(e);
    }
  };

  const handleCustomBgImgChange = (e) => {
    setCard({ ...card, backgroundImage: e.target.files[0] });
    setNewBackground(e.target.files[0]);
    setIsCustomBgImg(true);
  };

  const handleBgFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleSizeCheck;
      handleCustomBgImgChange(e);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const validateForm = () => {
    if (schemas[tabNo]) {
      let newErrors = validator(card, schemas[tabNo]);
      setErrors(newErrors);

      if (Object.keys(newErrors).length) return false;
    }

    return true;
  };

  const handleNextClick = async () => {
    if (!validateForm()) return;
    let newStatus = true;

    if (cardType === "team" && !card.teamId) {
      enqueueSnackbar("Please select a team", { variant: "error" });
      return;
    }

    setLoading(true);
    if (cardID) {
      let payload = _.cloneDeep(card);
      if (tabNo === 0) {
        if (regexToRemoveWhiteSpaces.test(card.title)) {
          payload.title = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.department)) {
          payload.department = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.company)) {
          payload.company = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.headline)) {
          payload.headline = "";
        }
      }

      if (tabNo === 2) {
        const emptyCardEmails = payload.cardEmails.filter(
          (item) => item.email !== null && item.email !== ""
        );

        const emptyCardPhones = payload.cardPhones.filter(
          (item) => item.phone !== null && item.phone !== ""
        );
        const emptyCardWebsites = payload.cardWebsites.filter(
          (item) =>
            (item.title !== null && item.title !== "") ||
            (item.url !== null && item.url !== "")
        );

        payload.cardEmails = emptyCardEmails;
        payload.cardPhones = emptyCardPhones;
        payload.cardWebsites = emptyCardWebsites;
      }
      if (card?.profileImage && typeof card?.profileImage === "object") {
        const data = await awsFileUpload(
          card.profileImage,
          uploadImageType.createCard
        );
        if (data.status) payload.profileImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (card?.backgroundImage && typeof card?.backgroundImage === "object") {
        const data = await awsFileUpload(
          card.backgroundImage,
          uploadImageType.createCard
        );
        if (data.status) payload.backgroundImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (card?.logoImage && typeof card?.logoImage === "object") {
        const data = await awsFileUpload(
          card.logoImage,
          uploadImageType.createCard
        );
        if (data.status) payload.logoImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (newStatus) {
        const { status, message, data } = await put(
          `${CARDS_CREATE}/${cardID}`,
          {
            ...payload,
            cardPhones: payload.cardPhones.map((item) => ({
              phone: item?.phone?.slice(item?.country_code?.length),
              country_code: item?.country_code,
            })),
            userId: card?.userId === loggedUser?.data?.id ? null : card?.userId,
          }
        );
        if (status) {
          setCard(payload);
          setCardID(data?.id);
        } else {
          enqueueSnackbar(message, { variant: "error" });
          newStatus = false;
        }
      }
    } else {
      let payload = _.cloneDeep(card);

      if (tabNo === 0) {
        if (regexToRemoveWhiteSpaces.test(card.title)) {
          payload.title = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.department)) {
          payload.department = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.company)) {
          payload.company = "";
        }

        if (regexToRemoveWhiteSpaces.test(card.headline)) {
          payload.headline = "";
        }
      }

      if (card?.profileImage && typeof card?.profileImage === "object") {
        const data = await awsFileUpload(
          card.profileImage,
          uploadImageType.createCard
        );
        if (data.status) payload.profileImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (card?.backgroundImage && typeof card?.backgroundImage === "object") {
        const data = await awsFileUpload(
          card.backgroundImage,
          uploadImageType.createCard
        );
        if (data.status) payload.backgroundImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (card?.logoImage && typeof card?.logoImage === "object") {
        const data = await awsFileUpload(
          card.logoImage,
          uploadImageType.createCard
        );
        if (data.status) payload.logoImage = data.location;
        else {
          enqueueSnackbar("Error Uploading Image", { variant: "error" });
          newStatus = false;
        }
      }

      if (newStatus) {
        const { status, message, data } = await post(CARDS_CREATE, {
          ...payload,
          logoImage: payload.logoImage
            ? payload.logoImage
            : "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/blueLogo.png",
          cardPhones: payload.cardPhones.map((item) => ({
            phone: item?.phone?.slice(item?.country_code?.length),
            country_code: item?.country_code,
          })),
          userId: card?.userId === loggedUser?.data?.id ? null : card?.userId,
        });
        if (status) {
          setCard({ ...payload, id: data?.id });
          setCardID(data?.id);
          // manage increment card
          updateCount("add");
        } else {
          enqueueSnackbar(message, { variant: "error" });
          newStatus = false;
        }
      }
    }

    setLoading(false);

    if (tabs.length - 1 > tabNo && newStatus) {
      setTabNo(tabNo + 1);
    }
  };

  const handleSaveClick = async () => {
    if (!validateForm()) return;

    const { status, message } = await put(`${CARDS_CREATE}/${cardID}`, {
      ...card,
      logoImage: card.logoImage
        ? card.logoImage
        : "https://narebapp.s3.us-east-2.amazonaws.com/knocknock/images/blueLogo.png",
      cardPhones: card.cardPhones.map((item) => ({
        phone: item?.phone?.slice(item?.country_code?.length),
        country_code: item?.country_code,
      })),
      userId: card?.userId === loggedUser?.data?.id ? null : card?.userId,
    });
    if (status) {
      if (duplicate) {
        enqueueSnackbar("Card duplicated successfully", { variant: "success" });
        if (teamId)
          return navigate(
            `${routes.CUSTOMER_TEAM_MEMBERS}/${teamId}/teamMembers`
          );
        else {
          return navigate(`${routes.CUSTOMER_BUSINESS_CARD}`);
        }
      } else {
        enqueueSnackbar(message, { variant: "success" });
        if (teamId && userId)
          if (ownerId)
            return navigate(
              `${routes.CUSTOMER_TEAM_MEMBERS}/${teamId}/teamMembers/${ownerId}`
            );
          else
            return navigate(
              `${routes.CUSTOMER_TEAM_MEMBERS}/${teamId}/teamMembers`
            );
        else if (teamId) return navigate(routes.CUSTOMER_TEAMS);
        else if (type === "member")
          return navigate(
            `${routes.CUSTOMER_TEAM_MEMBERS}/${card.teamId}/teamMembers`
          );
        else if (type === "myCard")
          return navigate(`${routes.CUSTOMER_TEAMS}?type=myCard`);
      }
      navigate(`${routes.CUSTOMER_BUSINESS_CARD}`);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const handlePreviousClick = () => {
    if (tabNo <= 0) {
      navigate(-1);
    } else {
      setTabNo(tabNo - 1);
    }
  };

  const isValidYouTubeLink = (url) => {
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    return youtubeRegex.test(url);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "youtube") {
      if (!isValidYouTubeLink(value))
        setErrors({ ...errors, [name]: "Please enter a valid YouTube link" });
      else setErrors({ ...errors, [name]: "" });
    }

    setCard({ ...card, [name]: value });
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;

    setCardType(value);
    if (value === "personal") {
      setCard({
        ...card,
        fullName:
          loggedUser?.data?.first_name + " " + loggedUser?.data?.last_name,
        teamId: null,
        userId: null,
      });
      setTeam(null);
    }
  };

  const handleDesignChange = (design) => {
    setCard({ ...card, backgroundImage: design });
    // setIsCustomBgImg(false);
  };

  const handleLogoDesignChange = (design) => {
    setCard({ ...card, logoImage: design });
    // setIsCustomBgImg(false);
  };

  const handlePrimaryColorChange = (color) => {
    setCard({ ...card, primaryColor: color });
  };

  const addNewEmail = () => {
    const newEmails = _.cloneDeep(card.cardEmails);
    newEmails.push({ email: "" });
    setCard({ ...card, cardEmails: newEmails });
  };

  const handleRemoveEmail = (index) => {
    const newEmails = _.cloneDeep(card.cardEmails);
    newEmails.splice(index, 1);
    setCard({ ...card, cardEmails: newEmails });
  };
  const addNewWebsite = () => {
    const newWebsites = _.cloneDeep(card.cardWebsites);

    newWebsites.push({ title: "", url: "" });
    setCard({ ...card, cardWebsites: newWebsites });
  };

  const handleRemoveWebsite = (index) => {
    const newWebsites = _.cloneDeep(card.cardWebsites);
    newWebsites.splice(index, 1);
    setCard({ ...card, cardWebsites: newWebsites });
  };

  const handleEmailChange = (e, index) => {
    let newEmails = _.cloneDeep(card.cardEmails);
    newEmails[index].email = e.target.value;
    if (newEmails[index].email.length > 70) {
      newEmails[index].email = newEmails[index].email.slice(0, 70);
    }
    setCard({ ...card, cardEmails: newEmails });
  };
  const handleWebsiteChange = (e, index) => {
    let newWebsites = _.cloneDeep(card.cardWebsites);
    newWebsites[index] = {
      ...newWebsites[index],
      [e.target.name]: e.target.value,
    };
    setCard({ ...card, cardWebsites: newWebsites });
  };

  const addNewPhone = () => {
    const newNumbers = _.cloneDeep(card.cardPhones);
    newNumbers.push({ phone: "", country_code: "+1" });
    setCard({ ...card, cardPhones: newNumbers });
  };
  const handleRemovePhone = (index) => {
    const newNumbers = _.cloneDeep(card.cardPhones);
    newNumbers.splice(index, 1);
    setCard({ ...card, cardPhones: newNumbers });
  };

  const handlePhoneChange = (value, country, index) => {
    let newNumbers = _.cloneDeep(card.cardPhones);

    newNumbers[index].phone = value;
    newNumbers[index].country_code = country.dialCode;

    setCard({ ...card, cardPhones: newNumbers });
  };

  const handleAddItem = (name) => {
    if (!["email", "phone", "website"].includes(name.toLowerCase())) {
      setShowItems({ ...showItems, [name.toLowerCase()]: true });
    }
  };
  const handleRemoveItem = (name) => {
    if (!["email", "phone", "website"].includes(name.toLowerCase())) {
      setShowItems({ ...showItems, [name.toLowerCase()]: false });
      setCard({ ...card, [name.toLowerCase()]: "" });
    }
    if (name === "address") {
      setAddress("");
      setSelectedLocation(null);
    }
  };

  const handleAddVideo = (name) => {
    let res = compareLimits(SUBSCRIPTION_KEY.video);
		if(!res) {
			return false;
		}
    
    if (selectedVideo && Object.values(showVideoItems).some((value) => value)) {
      // Check if a video is already selected
      enqueueSnackbar("Only one video at a time is allowed", {
        variant: "error",
      });
      return;
    }

    const updatedShowItems = { youtube: false, vimeo: false, tiktok: false };
    updatedShowItems[name.toLowerCase()] = true;
    setSelectedVideo(name.toLowerCase());
    setShowVideoItems(updatedShowItems);
  };

  const handleRemoveVideo = (name) => {
    const updatedShowItems = { ...videoItems };
    updatedShowItems[name.toLowerCase()] = false;
    setSelectedVideo(null);
    console.log(updatedShowItems);
    // setShowVideoItems(updatedShowItems);
    setShowVideoItems({ ...showVideoItems, [name.toLowerCase()]: false });
    setCard({ ...card, [name.toLowerCase()]: "" });
  };

  const getCardDetail = async () => {
    setLoading(true);
    if (id) {
      const { status, message, data } = await get(`${CARDS_DETAIL}/${id}`);
      if (status) {
        let newEmails = [];
        let newPhones = [];
        let newWebsites = [];
        data.cardEmails.forEach((email) =>
          newEmails.push({ email: email.email })
        );

        data.cardPhones.forEach((phone) =>
          newPhones.push({
            phone: `${phone.countryCode}${phone.phone}`,
            country_code: phone.countryCode,
          })
        );
        data?.cardWebsites?.forEach((website) =>
          newWebsites.push({ title: website.title, url: website.url })
        );

        if (duplicate === null) {
          setCardID(id);
        }
        setAddress(data.address || "");

        const addressObj = {
          address: data?.address || "",
          lat: data?.lat || null,
          long: data?.long || null,
        };
        if (!data?.backgroundImage?.includes("CardBg")) {
          setIsCustomBgImg(true);
          setNewBackground(data?.backgroundImage);
        }
        setCard({
          templateNo: data.templateNo || 1,
          fullName: data.fullName || "",
          department: data.department || "",
          title: data.title || "",
          headline: data.headline || "",
          profileImage: data.profileImage || "",
          instagram: data.instagram || "",
          website: data.website || "",
          backgroundImage: data.backgroundImage || "",
          primaryColor: data.primaryColor || "",
          logoImage: data.logoImage || "",
          address: addressObj,
          company: data.company || "",
          facebook: data.facebook || "",
          linkedin: data.linkedin || "",
          cardEmails: newEmails,
          cardPhones: newPhones,
          cardWebsites: newWebsites,
          cardName: data.cardName || "",
          tiktok: data.tiktok || "",
          youtube: data.youtube || "",
          vimeo: data.vimeo || "",
          pinterest: data.pinterest || "",
          threads: data.threads || "",
          clubhouse: data.clubhouse || "",
          calendar: data.calendar || "",
          id: duplicate === null && id,
          teamId: data.team_id ? data.team_id : null,
          userId: data.team_id ? data.userId : null,
        });

        setShowItems({
          ...showItems,
          instagram: data.instagram ? true : false,
          linkedin: data.linkedin ? true : false,
          facebook: data.facebook ? true : false,
          address: data.address ? true : false,
          website: data.website ? true : false,
          pinterest: data.pinterest ? true : false,
          threads: data.threads ? true : false,
          clubhouse: data.clubhouse ? true : false,
          calendar: data.calendar ? true : false,
        });

        setShowVideoItems({
          ...showVideoItems,
          youtube: data.youtube ? true : false,
          tiktok: data.tiktok ? true : false,
          vimeo: data.vimeo ? true : false,
        });

        setCardType(!data?.team_id ? "personal" : "team");
        editTeamId = data?.team_id;
        editUserId = data?.userId;
        // teamId = data?.team_id;
        // userId = data?.userId;
      }
    }
    setLoading(false);
  };

  const getTeams = async () => {
    const user = JSON.parse(localStorage.getItem("loggedUser")).data;
    const { status, message, data } = await get(TEAM_MY_ALL_LISTING);
    if (status) {
      const filteredTeams = data.myTeam.filter((team) =>
        team.members.some((member) => member.card === null)
      );

      const teamOwnerData = filteredTeams.map((item) => ({
        ...item,
        type: "owner",
        label: item.name,
      }));
      let newTeam;
      if ((teamId && type === "my-card") || type === "my-card") {
        const myTeamData = data.otherTeams.map((item) => ({
          ...item,
          type: "my-team",
          label: item.name,
        }));
        newTeam = [...teamOwnerData, ...myTeamData];
      } else if (teamId && !userId) {
        newTeam = teamOwnerData;
      } else {
        if (duplicate && otherCard) {
          let myTeamData = [];
          for (let i = 0; i < data.otherTeams.length; i++) {
            const team = data.otherTeams[i];
            let hasInvalidMember = false;

            for (let j = 0; j < team.members.length; j++) {
              const member = team.members[j];
              if (member.id === 102 && member.card === null) {
                hasInvalidMember = true;
                break;
              }
            }

            if (hasInvalidMember) {
              team.type = "my-team";
              team.label = team.name;
              myTeamData.push(team);
            }
          }
          newTeam = [...teamOwnerData, ...myTeamData];
        } else {
          const isTeamCard = data.otherTeams.some((item) =>
            item.members.some((member) => member.id === user?.id && member.card)
          );
          // data.otherTeams.some((item) =>
          // 	item.members.some((member) =>
          // 		console.log(member, user, member.card)
          // 	)
          // );
          if (!isTeamCard) {
            const myTeamData = data.otherTeams.map((item) => ({
              ...item,
              type: "my-team",
              label: item.name,
            }));
            newTeam = [...teamOwnerData, ...myTeamData];
          } else newTeam = teamOwnerData;
        }
      }
      setTeamOptions(newTeam);
      if (teamId) {
        setCardType("team");
        const selectedTeam = newTeam.find((item) => item?.id === teamId);
        setTeam(selectedTeam);
        const newMembers = selectedTeam.members
          ?.filter((item) => !item.card)
          ?.map((item) => ({
            ...item,
            label: `${item.first_name} ${item.last_name} - ${item.email}`,
          }));
        setMemberOptions(newMembers);
        if (userId && !duplicate) {
          const selectedMember = newMembers.find((item) => item?.id === userId);
          if (selectedMember) {
            setCard((prev) => ({
              ...prev,
              userId: selectedMember?.id,
              teamId,
              fullName: `${selectedMember?.first_name} ${selectedMember?.last_name}`,
              cardEmails: [{ email: selectedMember?.email }],
            }));
          } else
            enqueueSnackbar("Member's Card already exists", {
              variant: "error",
            });
        } else if (duplicate)
          setCard((prev) => ({
            ...prev,
            teamId,
            fullName: "",
            cardEmails: [],
            cardName: "",
            profileImage: "",
          }));
        else {
          setCard((prev) => ({
            ...prev,
            teamId,
            // fullName: `${loggedUser?.data?.first_name} ${loggedUser?.data?.last_name}`,
            // cardEmails: [{ email: loggedUser?.data?.email }],
          }));
        }
        // else if (type === "my-card")
        // 	setCard((prev) => ({
        // 		...prev,
        // 		teamId,
        // 		fullName: `${loggedUser?.data?.first_name} ${loggedUser?.data?.last_name}`,
        // 		cardEmails: [{ email: loggedUser?.data?.email }],
        // 	}));
      } else if (editTeamId) {
        const selectedTeam = newTeam.find((item) => item?.id === editTeamId);
        setTeam(selectedTeam);
        const newMembers = selectedTeam.members
          ?.filter((item) => !item.card)
          ?.map((item) => ({
            ...item,
            label: `${item.first_name} ${item.last_name} - ${item.email}`,
          }));
        const currentMember = selectedTeam.members.find(
          (item) => item?.id === editUserId
        );
        newMembers.push({
          ...currentMember,
          label: `${currentMember.first_name} ${currentMember.last_name} - ${currentMember.email}`,
        });
        setMemberOptions(newMembers);
      }
    }
  };

  const teamQuery = useQuery(queryNames.TEAM, getTeams, {
    enabled: loggedUser?.token?.length > 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    getCardDetail();
    getTeams();
  }, []);

  useEffect(() => {
    if (!id && Object.keys(loggedUser).length > 0) {
      setCard({
        ...card,
        fullName:
          loggedUser?.data?.first_name + " " + loggedUser?.data?.last_name,
        cardEmails: [{ email: loggedUser?.data?.email }],
      });
      // setShowItems({
      // 	...showItems,
      // 	email: true,
      // });
    }
  }, [loggedUser]);

  useEffect(() => {
    if (type === "my-card") {
      setCard((prev) => ({
        ...prev,
        teamId,
        fullName: `${loggedUser?.data?.first_name} ${loggedUser?.data?.last_name}`,
        cardEmails: [{ email: loggedUser?.data?.email }],
      }));
    }
  }, [loggedUser, type]);

  const hasCustomeColor = PrimaryColor.some(
    (item) => item.bgcolor === card.primaryColor
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row">
        <Main open={open}>
          <Box sx={styles.pageTitle}>
            <PageTitle title={`${id ? "Edit" : "Create"} Card`} />
          </Box>

          <Box sx={styles.card}>
            <Box sx={styles.tabWrapper}>
              {tabs.map((tab, index) => (
                <Typography
                  sx={[styles.tab, tabNo === index && styles.tabActive]}
                  key={tab.id}
                >
                  {tab.name}
                </Typography>
              ))}
            </Box>

            <Box
              component="form"
              noValidate
              style={tabNo !== 0 ? { display: "none" } : null}
            >
              <Typography component="h2">Choose A Template</Typography>
              <Stack direction="row" gap={2} flexWrap="wrap">
                <Box sx={{ position: "relative" }}>
                  {card.templateNo === 1 ? (
                    <Box sx={styles.templateOverlay}>
                      <Check />
                    </Box>
                  ) : null}
                  <Box
                    component="img"
                    src={Template1}
                    sx={styles.templateWrapper}
                    onClick={() => setCard({ ...card, templateNo: 1 })}
                  />
                </Box>
                <Box sx={{ position: "relative" }}>
                  {card.templateNo === 2 ? (
                    <Box sx={styles.templateOverlay}>
                      <Check />
                    </Box>
                  ) : null}
                  <Box
                    component="img"
                    src={Template2}
                    sx={styles.templateWrapper}
                    onClick={() => setCard({ ...card, templateNo: 2 })}
                  />
                </Box>
                <Box sx={{ position: "relative" }}>
                  {card.templateNo === 3 ? (
                    <Box sx={styles.templateOverlay}>
                      <Check />
                    </Box>
                  ) : null}
                  <Box
                    component="img"
                    src={Template3}
                    sx={styles.templateWrapper}
                    onClick={() => setCard({ ...card, templateNo: 3 })}
                  />
                </Box>
                <Box sx={{ position: "relative" }}>
                  {card.templateNo === 4 ? (
                    <Box sx={styles.templateOverlay}>
                      <Check />
                    </Box>
                  ) : null}
                  <Box
                    component="img"
                    src={Template4}
                    sx={styles.templateWrapper}
                    onClick={() => setCard({ ...card, templateNo: 4 })}
                  />
                </Box>
              </Stack>

              <Grid container mt={2} spacing={2}>
                {!teamId && (
                  <>
                    <Grid item md={6} xs={12}>
                      {/* <FormAutoComplete
                        placeholder="Select type"
                        options={options}
                        value={options.find((item) => item.value === cardType)}
                        onChange={(e, value) => {
                          if (value) {
                            setCardType(value.value);
                            if (value.value === "personal") {
                              setCard({
                                ...card,
                                fullName:
                                  loggedUser?.data?.first_name +
                                  " " +
                                  loggedUser?.data?.last_name,
                                teamId: null,
                                userId: null,
                              });
                              setTeam(null);
                            }
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        disableClearable
                        disabled={
                          id & !otherCard ? true : teamId ? true : false
                        }
                        autoComplete="off"
                      /> */}

                      <SelectInput
                        placeholder="Select type"
                        value={cardType}
                        onChange={handleTypeChange}
                        height={56}
                      >
                        {options.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </SelectInput>
                    </Grid>

                    <Grid item md={6} xs={12}></Grid>
                  </>
                )}
                {cardType === "team" && (
                  <Grid item md={6} xs={12}>
                    <FormAutoComplete
                      placeholder="Select team"
                      options={teamOptions}
                      value={
                        teamOptions?.find(
                          (item) => item?.id === card?.teamId
                        ) || null
                      }
                      onChange={(e, value) => {
                        if (value) {
                          setTeam(value);
                          if (value.type === "my-team") {
                            setCard({
                              ...card,
                              fullName:
                                loggedUser?.data?.first_name +
                                " " +
                                loggedUser?.data?.last_name,
                              cardEmails: [{ email: loggedUser?.data?.email }],
                              teamId: value.id,
                            });
                            setEnableMember(false);
                          } else {
                            const newMembers = value.members
                              ?.filter((item) => !item.card)
                              ?.map((item) => ({
                                ...item,
                                label: `${item.first_name} ${item.last_name} - ${item.email}`,
                              }));
                            setMemberOptions(newMembers);
                            setCard({
                              ...card,
                              teamId: value.id,
                            });
                            setEnableMember(true);
                          }
                        } else {
                          setTeam(null);
                          setEnableMember(false);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      groupBy={(option) => option.type}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader>
                            {params.group === "owner"
                              ? "My Teams"
                              : "Other Teams"}
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      disableClearable
                      disabled={id && !otherCard ? true : teamId ? true : false}
                      freeSolo={id && !otherCard ? true : teamId ? true : false}
                    />
                  </Grid>
                )}
                {cardType === "team" && <Grid item md={6} xs={12}></Grid>}

                {((team?.type === "owner" &&
                  cardType === "team" &&
                  !isNaN(teamId)) ||
                  enableMember) && (
                  <>
                    <Grid item md={6} xs={12}>
                      <FormAutoComplete
                        placeholder="Select member"
                        options={memberOptions}
                        value={memberOptions.find(
                          (item) =>
                            // userId
                            // 	? item?.id === userId
                            // 	: item?.id === card?.userId
                            item?.id === card?.userId
                        )}
                        onChange={(e, value) => {
                          if (value) {
                            setCard({
                              ...card,
                              fullName:
                                value.first_name + " " + value.last_name,
                              cardEmails: [{ email: value.email }],
                              userId: value.id,
                            });
                          } else {
                            setCard({
                              ...card,
                              fullName:
                                loggedUser?.data?.first_name +
                                " " +
                                loggedUser?.data?.last_name,
                              cardEmails: [{ email: loggedUser?.data?.email }],
                              userId: null,
                            });
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disabled={
                          duplicate
                            ? false
                            : id
                            ? true
                            : teamId
                            ? userId && teamId
                            : false
                        }
                        freeSolo={
                          duplicate
                            ? false
                            : id
                            ? true
                            : teamId
                            ? userId && teamId
                            : false
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                  </>
                )}
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Full Name"
                    name="fullName"
                    type="text"
                    value={card.fullName}
                    onChange={handleChange}
                    error={errors?.fullName}
                    helperText={errors?.fullName || ""}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Affiliation</Typography>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Title"
                    name="title"
                    value={card.title}
                    onChange={handleChange}
                    error={errors?.title}
                    helperText={errors?.title || ""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Department"
                    name="department"
                    value={card.department}
                    onChange={handleChange}
                    error={errors?.department}
                    helperText={errors?.department || ""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Company"
                    name="company"
                    value={card.company}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Headline"
                    name="headline"
                    value={card.headline}
                    onChange={handleChange}
                    error={errors?.headline}
                    helperText={errors?.headline || ""}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Profile</Typography>
              {card.profileImage ? (
                <Box sx={styles.crossWrapper}>
                  <Box
                    component="img"
                    sx={styles.profileImg}
                    src={
                      card?.profileImage
                        ? typeof card?.profileImage === "object"
                          ? URL.createObjectURL(card?.profileImage)
                          : card?.profileImage
                        : null
                    }
                  />
                  {/* <ReactImageAppear
										src={
											card?.profileImage
												? typeof card?.profileImage === "object"
													? URL.createObjectURL(card?.profileImage)
													: card?.profileImage
												: null
										}
                    
									/> */}
                  <Close onClick={handleRemoveProfile} />
                </Box>
              ) : (
                <ProfileCamera
                  onClick={handleProfileClick}
                  className="pointer"
                />
              )}
              <input
                type="file"
                accept="image/*"
                hidden
                ref={profileRef}
                id="profile"
                onChange={handleProfileChange}
                onInput={handleProfileFileInput}
                key={card?.profileImage}
              />
            </Box>

            <Box style={tabNo !== 1 ? { display: "none" } : null}>
              <Typography component="h2">Background</Typography>
              <Stack direction="row" gap={1} flexWrap="wrap">
                {coverImages.map((img, index) => (
                  <Box sx={{ position: "relative" }} key={index}>
                    {img.src === card.backgroundImage ? (
                      <Box sx={styles.coverImgOverlay}>
                        <Check />
                      </Box>
                    ) : null}
                    <Box
                      key={img.id}
                      sx={styles.coverImg}
                      onClick={() => handleDesignChange(img.src)}
                      component="img"
                      src={img.src}
                    />
                  </Box>
                ))}
                {newBackground && isCustomBgImg ? (
                  <Box sx={styles.crossWrapper}>
                    <Box
                      component="img"
                      sx={styles.coverImg}
                      src={
                        newBackground
                          ? typeof newBackground === "object"
                            ? URL.createObjectURL(newBackground)
                            : newBackground
                          : null
                      }
                      onClick={() => handleDesignChange(newBackground)}
                    />
                    <Close onClick={handleRemoveCustomBgImg} />
                  </Box>
                ) : (
                  <ProfileCamera
                    onClick={handleCustomBgImgClick}
                    className="pointer customBgImg"
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  ref={customBgImgRef}
                  onChange={handleCustomBgImgChange}
                  onInput={handleBgFileInput}
                  key={card?.backgroundImage}
                />
              </Stack>

              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Button Colors</Typography>
              <Stack direction="row" gap={1} pb={2} flexWrap="wrap">
                {PrimaryColor.map((color) => (
                  <Box
                    key={color.id}
                    sx={{
                      bgcolor: color.bgcolor,
                      ...styles.colorBox,
                    }}
                    onClick={() => handlePrimaryColorChange(color.bgcolor)}
                  >
                    {color.bgcolor === card.primaryColor ? (
                      <Box sx={styles.overlay}>
                        <Check />
                      </Box>
                    ) : null}
                  </Box>
                ))}
                {hasCustomeColor ? null : (
                  <Box
                    sx={{
                      bgcolor: card.primaryColor,
                      ...styles.colorBox,
                    }}
                  >
                    {hasCustomeColor ? null : (
                      <Box sx={styles.overlay}>
                        <Check />
                      </Box>
                    )}
                  </Box>
                )}

                <Box
                  sx={styles.colorPickerBox}
                  onClick={() => setOpenColorPickerModal(true)}
                >
                  <ColorPickker />
                </Box>
              </Stack>
              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Logo</Typography>
              <Stack direction="row" gap={2}>
                {card.logoImage ? (
                  <Box sx={styles.crossWrapper}>
                    <Box
                      component="img"
                      sx={styles.profileImg}
                      src={
                        card?.logoImage
                          ? typeof card?.logoImage === "object"
                            ? URL.createObjectURL(card?.logoImage)
                            : card?.logoImage
                          : null
                      }
                    />
                    <CloseIcon onClick={handleRemoveLogo} />
                  </Box>
                ) : (
                  <ProfileCamera
                    onClick={handleLogoClick}
                    className="pointer"
                  />
                )}

                {/* {logoImages.map((img, index) => (
									<Box sx={{ position: "relative" }} key={index}>
										{img.src === card.logoImage ? (
											<Box sx={styles.logoOverlay}>
												<Check />
											</Box>
										) : null}
										<Box
											component="img"
											sx={{
												...styles.profileImg,
												width: 150,
												objectFit: "contain",
											}}
											src={img.src}
											onClick={() => handleLogoDesignChange(img.src)}
										/>
									</Box>
								))} */}
              </Stack>
              <input
                type="file"
                accept="image/*"
                hidden
                ref={logoRef}
                onChange={handleLogoChange}
                onInput={handleLogoFileInput}
                key={card?.logoImage}
              />
            </Box>

            <Box style={tabNo !== 2 ? { display: "none" } : null}>
              <Typography component="h2">Email</Typography>
              <Grid container>
                <Grid item md={8} xs={12}>
                  {card?.cardEmails?.length
                    ? card.cardEmails.map((single, index) => (
                        <Stack
                          direction="row"
                          columnGap={1.5}
                          alignItems="center"
                          pt={1}
                          key={index}
                        >
                          <CustomInputField
                            label="Email"
                            value={single.email}
                            onChange={(e) => handleEmailChange(e, index)}
                            error={
                              errors[`cardEmails[${index}].email`]
                                ? true
                                : false
                            }
                            helperText={
                              errors[`cardEmails[${index}].email`] || ""
                            }
                          />
                          <Cross
                            className="pointer"
                            onClick={() => handleRemoveEmail(index)}
                          />
                        </Stack>
                      ))
                    : null}
                  <Typography sx={styles.add} onClick={addNewEmail}>
                    +Add an Email
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Phone</Typography>
              <Grid container>
                <Grid item md={8} xs={12} sx={styles.phoneWrapper}>
                  {card?.cardPhones?.length
                    ? card.cardPhones.map((single, index) => (
                        <Stack
                          direction="row"
                          columnGap={1.5}
                          alignItems="center"
                          pt={1}
                          key={single.id}
                        >
                          <PhoneNumberInput
                            value={single.phone}
                            onChange={(value, country, e, formattedValue) =>
                              handlePhoneChange(value, country, index)
                            }
                            error={
                              errors[`cardPhones[${index}].phone`]
                                ? true
                                : false
                            }
                            helperText={
                              errors[`cardPhones[${index}].phone`] || ""
                            }
                          />
                          <Cross
                            onClick={() => handleRemovePhone(index)}
                            className="pointer"
                          />
                        </Stack>
                      ))
                    : null}
                  <Typography sx={styles.add} onClick={addNewPhone}>
                    +Add a Phone
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Typography component="h2">Website</Typography>
              <Grid container>
                <Grid item md={12} xs={12}>
                  {card?.cardWebsites?.length
                    ? card.cardWebsites.map((single, index) => (
                        <Stack
                          direction="row"
                          columnGap={1.5}
                          alignItems="center"
                          pt={1}
                          key={single.id}
                        >
                          <CustomInputField
                            label="Title"
                            name="title"
                            value={single.title}
                            onChange={(e) => handleWebsiteChange(e, index)}
                            error={
                              errors[`cardWebsites[${index}].title`]
                                ? true
                                : false
                            }
                            helperText={
                              errors[`cardWebsites[${index}].title`] || ""
                            }
                          />
                          <CustomInputField
                            label="URL"
                            name="url"
                            value={single.url}
                            onChange={(e) => handleWebsiteChange(e, index)}
                            error={
                              errors[`cardWebsites[${index}].url`]
                                ? true
                                : false
                            }
                            helperText={
                              errors[`cardWebsites[${index}].url`] || ""
                            }
                          />
                          <Cross
                            className="pointer mw-32"
                            onClick={() => handleRemoveWebsite(index)}
                          />
                        </Stack>
                      ))
                    : null}
                  <Typography sx={styles.add} onClick={addNewWebsite}>
                    +Add a Website
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              {Object.keys(showItems).length &&
                Object.keys(showItems).map((single) => {
                  return (
                    <>
                      {showItems[single] && single === "address" && isLoaded ? (
                        <>
                          <Typography
                            component="h2"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {single}
                          </Typography>

                          <Grid container>
                            <Grid item md={8} xs={12}>
                              <Stack
                                direction="row"
                                columnGap={1.5}
                                alignItems="center"
                                pt={1}
                              >
                                <LocationAutoComplete
                                  label="Address"
                                  locationType="address"
                                  data={card}
                                  setData={setCard}
                                  // error={errors?.address ? true : false}
                                  // helperText={errors?.address || ""}
                                  types={["establishment"]}
                                  selectedLocation={selectedLocation}
                                  setSelectedLocation={setSelectedLocation}
                                  address={address}
                                  setAddress={setAddress}
                                />
                                <Cross
                                  onClick={() => handleRemoveItem(single)}
                                  className="pointer"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider sx={{ my: 3 }} />
                        </>
                      ) : showItems[single] ? (
                        <Box key={single.id}>
                          <Typography
                            component="h2"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {single.toLowerCase() === "calendar"
                              ? "Book Me"
                              : single}
                          </Typography>
                          <Grid container>
                            <Grid item md={8} xs={12}>
                              <Stack
                                direction="row"
                                columnGap={1.5}
                                alignItems="center"
                                pt={1}
                              >
                                <CustomInputField
                                  label={
                                    single.toLowerCase() === "calendar"
                                      ? "Book Me"
                                      : single
                                  }
                                  value={card[single]}
                                  onChange={handleChange}
                                  name={single}
                                  error={errors[single] ? true : false}
                                  helperText={errors[single] || ""}
                                />
                                <Cross
                                  onClick={() =>
                                    single.toLowerCase() === "youtube" ||
                                    single.toLowerCase() === "vimeo" ||
                                    single.toLowerCase() === "tiktok"
                                      ? handleRemoveVideo(single)
                                      : handleRemoveItem(single)
                                  }
                                  className="pointer"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider sx={{ my: 3 }} />
                        </Box>
                      ) : null}

                      {/* {showItems[single] ? (
                        <Box key={single.id}>
                          <Typography
                            component="h2"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {single}
                          </Typography>
                          <Grid container>
                            <Grid item md={6} xs={12}>
                              <Stack
                                direction="row"
                                columnGap={1.5}
                                alignItems="center"
                                pt={1}
                              >
                                <CustomInputField
                                  label={single}
                                  value={card[single]}
                                  onChange={handleChange}
                                  name={single}
                                  error={errors[single] ? true : false}
                                  helperText={errors[single] || ""}
                                />
                                <Cross
                                  onClick={() => handleRemoveItem(single)}
                                  className="pointer"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                          <Divider sx={{ my: 3 }} />
                        </Box>
                      ) : null} */}
                    </>
                  );
                })}

              {Object.keys(showVideoItems).length &&
                Object.keys(showVideoItems).map((single) =>
                  showVideoItems[single] ? (
                    <Box key={single.id}>
                      <Typography
                        component="h2"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {single}
                      </Typography>
                      <Grid container>
                        <Grid item md={8} xs={12}>
                          <Stack
                            direction="row"
                            columnGap={1.5}
                            alignItems="center"
                            pt={1}
                          >
                            <CustomInputField
                              label={single}
                              value={card[single]}
                              onChange={handleChange}
                              name={single}
                              error={errors[single] ? true : false}
                              helperText={errors[single] || ""}
                            />
                            <Cross
                              onClick={() => handleRemoveVideo(single)}
                              className="pointer"
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 3 }} />
                    </Box>
                  ) : null
                )}

              <Typography component="h2">Add Items</Typography>
              <Box sx={styles.itemWrapper}>
                {items.map((item) => (
                  <Box
                    sx={styles.item}
                    key={item.id}
                    onClick={() => handleAddItem(item.name)}
                  >
                    <Stack direction="row" alignItems="center" columnGap={1.5}>
                      <Box sx={styles.iconBox}>{item.icon}</Box>
                      <Typography>
                        {item.name.toLowerCase() === "calendar"
                          ? "Book Me"
                          : item.name}
                      </Typography>
                    </Stack>

                    {showItems[item.name.toLowerCase()] ? (
                      <Box sx={styles.iconBox}>
                        <CheckIcon sx={{ fill: "rgb(28, 95, 174)" }} />
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </Box>
              <Typography component="h2" mt={1}>
                Video
              </Typography>
              <Box sx={styles.itemWrapper}>
                {videoItems.map((item) => (
                  <Box
                    sx={styles.item}
                    key={item.id}
                    onClick={() => handleAddVideo(item.name)}
                  >
                    <Stack direction="row" alignItems="center" columnGap={1.5}>
                      <Box sx={styles.iconBox}>{item.icon}</Box>
                      <Typography>{item.name}</Typography>
                    </Stack>

                    {showVideoItems[item.name.toLowerCase()] ? (
                      <Box sx={styles.iconBox}>
                        <CheckIcon sx={{ fill: "rgb(28, 95, 174)" }} />
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box style={tabNo !== 3 ? { display: "none" } : null}>
              <Typography component="h2">Card Name</Typography>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <CustomInputField
                    label="Work"
                    name="cardName"
                    value={card.cardName}
                    onChange={handleChange}
                    error={errors.cardName ? true : false}
                    helperText={errors.cardName || ""}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Stack direction="row" justifyContent="space-between" pt={3} pb={5}>
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handlePreviousClick}
              disableElevation
            >
              <RightArrow className="mr-1" />
              Back
            </Button>

            <Stack direction="row" columnGap={2}>
              {tabNo !== 0 ? (
                <Button
                  variant="contained"
                  sx={styles.cancelBtn}
                  disableElevation
                  onClick={() => setOpenCancelModal(true)}
                >
                  Cancel
                </Button>
              ) : null}

              <Button
                variant="contained"
                sx={styles.nextBtn}
                disableElevation
                onClick={
                  tabNo === tabs.length - 1 ? handleSaveClick : handleNextClick
                }
              >
                {tabNo === tabs.length - 1 ? "Save" : "Next"}
              </Button>
            </Stack>
          </Stack>
        </Main>
        <RightBusinessDrawer
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          showActionBtn={false}
          {...card}
        />
      </Stack>
      <CropAvatar
        modal={modal}
        setModal={setModal}
        // image={
        // 	modal === "profileImage"
        // 		? card?.profileImage
        // 			? typeof card?.profileImage === "object"
        // 				? URL.createObjectURL(card?.profileImage)
        // 				:   card?.profileImage
        // 			: null
        // 		: card?.logoImage
        // 		? typeof card?.logoImage === "object"
        // 			? URL.createObjectURL(card?.logoImage)
        // 			:   card?.logoImage
        // 		: null
        // }
        image={modal === "profileImage" ? profileImg : logoImg}
        user={card}
        cropType=""
        aspectRatio={modal === "profileImage" ? 4 / 4 : 6 / 1}
        setUser={setCard}
      />

      <IconButton sx={styles.floatBtn} onClick={handleDrawerOpen}>
        <RemoveRedEyeOutlined />
      </IconButton>
      <ConfirmationModal
        header="Are you sure?"
        title={`Are you sure you want to cancel? Any unsaved data will be lost.`}
        confirmButton="Confirm"
        confirmButtonColor="#E53935"
        open={openCancelModal}
        handleClose={() => setOpenCancelModal(false)}
        handleConfirm={() => navigate(routes.CUSTOMER_BUSINESS_CARD)}
      />

      <ColorPickerModal
        open={openColorPickerModal}
        setOpen={setOpenColorPickerModal}
        header="Custom Color Picker"
        data={card}
        setData={setCard}
        handleClose={() => {
          setOpenColorPickerModal(false);
        }}
      />
    </>
  );
};

export default CreateCard;
