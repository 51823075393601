import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import {
	Box,
	Button,
	Divider,
	FormHelperText,
	Grid,
	LinearProgress,
	Paper,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import CkEditor from "../../../components/forms/CkEditor";
import { ReactComponent as Upload } from "../../../assets/svg/UploadPic.svg";
import Remove from "../../../assets/svg/Remove.svg";
import FormAutoComplete from "../../../components/forms/FormAutoComplete";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "../../../components/button/LoadingButton";
import { validator } from "../../../utils/helpers/validator";
import { ProductSchema } from "../../../utils/schemas/ProductSchema";
import { get, post } from "../../../server/index";
import {
	COLORS,
	PRODUCT_CREATE,
	CATEGORY_LISTING,
	PRODUCT_DETAIL,
	PRODUCT_UPDATE,
} from "../../../data/constants/apiRoutes";
import { useMutation, useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../data/constants/routes";
import _ from "lodash";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import LoadingOverlay from "../../../components/overlay/LoadingOverlay";
import ImagePreviewModal from "../../../components/modal/ImagePreviewModal";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import VideoThumbnail from "react-video-thumbnail";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { useRef } from "react";
import VideoSnapshot from "video-snapshot";

const styles = {
	container: {
		bgcolor: "#fff",
		borderRadius: "20px",
		py: "20px",
		px: 5,
		"& h2": {
			color: "#1E1E1F",
			fontSize: 20,
			fontWeight: 500,
			lineHeight: "36px",
			mb: 2,
		},
	},
	divider: {
		color: "#EEE",
		my: 2,
	},
	imageUploadContainer: {
		height: 100,
		width: 100,
		borderRadius: "20px",
		bgcolor: "#FBFCFF",
		border: "2px dashed #E2E3E4",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer",
		"& p": {
			color: "#1E1E1F",
			fontSize: 14,
			fontWeight: 400,
			lineHeight: "26px",
			textAlign: "center",
		},
		"& svg": {
			width: 45,
			height: 35,
		},
		"& path": {
			fill: "#135099",
		},
	},
	imageContainer: {
		height: 100,
		width: 100,
		borderRadius: "20px",
		position: "relative",
		objectFit: "cover",
		border: "1px solid #eee",
		cursor: "pointer",
	},
	removeIcon: {
		position: "absolute",
		right: -3,
		top: -3,
		cursor: "pointer",
		width: 20,
		height: 20,
	},
	imageGrid: {
		display: "flex",
		flexWrap: "wrap",
		gap: "14px",
	},
	addDiscount: {
		color: "#135099",
		fontSize: 14,
		fontWeight: 600,
		lineHeight: "20px",
		cursor: "pointer",
		mt: 1,
	},
	colorContainer: {
		display: "flex",
		flexWrap: "wrap",
		gap: "12px",
	},
	color: {
		width: "46px",
		height: "46px",
		borderRadius: "50%",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	checkIcon: {
		color: "white",
	},
	saveBtn: {
		bgcolor: "#135099",
		color: "#fff",
		borderRadius: "10px",
		textTransform: "capitalize",
		fontSize: 16,
		fontWeight: 500,
		width: 200,
		height: 50,
		mt: 3,
		"&:hover": {
			bgcolor: "#135099",
		},
	},
	error: {
		fontSize: 14,
		color: "#d32f2f",
		ml: "14px",
		mt: 1,
	},
	backBtn: {
		mt: 3,
		bgcolor: "#E8EEF5",
		color: "#000000",
		px: 2,
		height: "50px",
		whiteSpace: "nowrap",
		minWidth: 150,
		textTransform: "capitalize",
		fontWeight: 600,
		borderRadius: "10px",
		"&:hover": {
			bgcolor: "#E8EEF5",
		},
	},
	videoIcon: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 30,
		height: 30,
		fill: "#fff",
		cursor: "pointer",
	},
};

const initialState = {
	name: "",
	brand: "",
	description: "",
	images: [],
	price: "",
	product_url: "",
	// category_id: "",
	// colors: [],
	delete_images: [],
};

const AddEditProduct = () => {
	const [product, setProduct] = useState(initialState);
	// const [colors, setColors] = useState([]);
	// const [categories, setCategories] = useState([]);

	const [modal, setModal] = useState(false);
	const [image, setImage] = useState();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { productId } = useParams();

	const [errors, setErrors] = useState({});

	const [uploadProgress, setUploadProgress] = useState({});

	const isId = productId !== null && typeof productId !== "undefined";

	const handleChange = (e) => {
		const name = e.target.name;
		let value = e.target.value;

		if (name === "price") {
			const regex = /^(\d{1,6}(\.\d{0,2})?)?$/;

			if (!regex.test(value)) {
				return;
			}
		}

		setProduct({
			...product,
			[name]: value,
		});
	};

	const handleDescriptionChange = (data) => {
		setProduct((prevProduct) => ({
			...prevProduct,
			description: data,
		}));
	};

	// const handleCategoryChange = (e, value) => {
	// 	if (value) {
	// 		setProduct({
	// 			...product,
	// 			category_id: value.value,
	// 		});
	// 	} else {
	// 		setProduct({
	// 			...product,
	// 			category_id: "",
	// 		});
	// 	}
	// };

	// const handleColorClick = (color) => {
	// 	const colorIndex = product.colors.findIndex(
	// 		(selectedColor) => selectedColor === color.id
	// 	);

	// 	if (colorIndex === -1) {
	// 		setProduct((prevProduct) => ({
	// 			...prevProduct,
	// 			colors: [...prevProduct.colors, color.id],
	// 		}));
	// 	} else {
	// 		const updatedColors = [...product.colors];
	// 		updatedColors.splice(colorIndex, 1);
	// 		setProduct((prevProduct) => ({
	// 			...prevProduct,
	// 			colors: updatedColors,
	// 		}));
	// 	}
	// };

	const generateThumbnail = async (videoFile) => {
		const fileNameWithoutExtension = videoFile.name
			.split(".")
			.slice(0, -1)
			.join(".");

		const snapshoter = new VideoSnapshot(videoFile);

		try {
			const previewSrc = await snapshoter.takeSnapshot();
			const thumbnailFile = base64ToFile(
				previewSrc,
				`thumbnail-${fileNameWithoutExtension}.jpg`
			);
			return thumbnailFile;
		} catch (error) {
			console.error("Error generating thumbnail:", error);
			throw error;
		}
	};

	function base64ToFile(base64Image, fileName) {
		const byteString = atob(base64Image.split(",")[1]);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([ab], { type: "image/jpeg" });

		return new File([blob], fileName, { type: "image/jpeg" });
	}

	const handleImageChange = async (e) => {
		const files = e.target.files;
		const newImages = Array.from(files);

		const currentImageCount = product.images.length;

		const remainingSlots = 5 - currentImageCount;

		const imagesToAdd = newImages.slice(
			0,
			Math.min(remainingSlots, newImages.length)
		);

		if (imagesToAdd.length < newImages.length) {
			enqueueSnackbar("Only 5 Items can be uploaded", { variant: "error" });
		  }

		const newMedia = await Promise.all(
			imagesToAdd.map(async (file) => {
				const isVideo = file.type.includes("video");
				let thumbnailUrl;

				if (isVideo) {
					thumbnailUrl = await generateThumbnail(file);
				}

				return {
					path: file,
					type: isVideo ? "video" : "image",
					thumbnail: thumbnailUrl,
				};
			})
		);

		const initialProgress = newMedia.reduce((progress, file) => {
			progress[file.name] = 0;
			return progress;
		}, {});

		setUploadProgress((prevProgress) => ({
			...prevProgress,
			...initialProgress,
		}));

		setProduct((prevProduct) => ({
			...prevProduct,
			images: [...prevProduct.images, ...newMedia],
		}));
	};

	const handleRemoveImage = (index) => {
		const updatedImages = [...product.images];
		const removedImage = updatedImages.splice(index, 1)[0];

		if (removedImage.id) {
			setProduct((prevProduct) => ({
				...prevProduct,
				images: updatedImages,
				delete_images: [...prevProduct.delete_images, removedImage.id],
			}));
		} else {
			setProduct({
				...product,
				images: updatedImages,
			});
		}
	};

	const validateForm = () => {
		const newErrors = validator(product, ProductSchema);

		setErrors(newErrors);
		if (Object.keys(newErrors).length === 0) return true;
		return false;
	};

	const fetchProduct = async () => {
		const { status, message, data } = await get(
			`${PRODUCT_DETAIL}/${productId}`
		);
		if (status === true) {
			// const color = data?.colors?.map((item) => item.id);
			setProduct((prevProduct) => ({
				...prevProduct,
				...data,
				delete_images: [],
				// category_id: data?.category?.id,
				// colors: color,
			}));
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const productQuery = useQuery(
		[queryNames.PRODUCTS, productId],
		fetchProduct,
		{
			enabled: isId,
			refetchOnWindowFocus: false,
		}
	);

	// const fetchColors = async () => {
	// 	const { status, message, data } = await get(COLORS);
	// 	if (status === true) {
	// 		setColors(data);
	// 	} else {
	// 		enqueueSnackbar(message, { variant: "error" });
	// 	}
	// };

	// const colorQuery = useQuery([queryNames.COLORS], fetchColors, {
	// 	refetchOnWindowFocus: false,
	// });

	// const fetchCategories = async () => {
	// 	const { status, message, data } = await get(CATEGORY_LISTING);
	// 	if (status === true) {
	// 		const newCategories = data?.data?.map((item) => ({
	// 			label: item.name,
	// 			value: item.id,
	// 		}));
	// 		setCategories(newCategories);
	// 	} else {
	// 		enqueueSnackbar(message, { variant: "error" });
	// 	}
	// };

	// const categoryQuery = useQuery([queryNames.CATEGORIES], fetchCategories, {
	// 	refetchOnWindowFocus: false,
	// });

	const handleSaveProduct = async () => {
		let payload = _.cloneDeep(product);

		let newStatus = true;

		if (
			Array.isArray(payload.images) &&
			payload.images.some(
				(item) =>
					typeof item.path !== "string" || typeof item.thumbnail !== "string"
			)
		) {
			for (let i = 0; i < payload.images.length; i++) {
				const media = payload.images[i];
				if (!media?.id) {
					if (media.thumbnail && typeof media.thumbnail !== "string") {
						const data = await awsFileUpload(
							media.thumbnail,
							uploadImageType.createProducts,
							setUploadProgress
						);
						if (data.status === true) {
							payload.images[i].thumbnail = data.location;
						} else {
							enqueueSnackbar("Error uploading images", {
								variant: "error",
							});
							return (newStatus = false);
						}
					} else delete media.thumbnail;
					const data = await awsFileUpload(
						media.path,
						uploadImageType.createProducts,
						setUploadProgress
					);
					if (data.status === true) {
						payload.images[i].path = data.location;
					} else {
						enqueueSnackbar("Error uploading images", { variant: "error" });
						return (newStatus = false);
					}
				}
			}
		}

		if (newStatus) {
			const { status, message } = await post(
				isId ? `${PRODUCT_UPDATE}/${productId}` : PRODUCT_CREATE,
				payload
			);
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				navigate(routes.CUSTOMER_PRODUCT_LISTING);
			} else {
				enqueueSnackbar(message, { variant: "error" });
			}
		}
	};

	const mutation = useMutation(handleSaveProduct);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	// const ColorSkeleton = () => {
	// 	return (
	// 		<>
	// 			{Array.from({ length: 10 }).map((_, index) => (
	// 				<Skeleton
	// 					key={index}
	// 					variant="circle"
	// 					width={46}
	// 					height={46}
	// 					sx={{ borderRadius: "50%", marginRight: "12px" }}
	// 				/>
	// 			))}
	// 		</>
	// 	);
	// };

	const handleClose = () => {
		setModal(false);
		setImage();
	};

	const handleImagePreviewClick = (previewImage, type) => {
		setModal(type);
		if (previewImage?.name) setImage(URL.createObjectURL(previewImage));
		else
			setImage(
				typeof previewImage === "string" ? previewImage : previewImage?.path
			);
		// setImage(image);
	};

	return (
		<>
			<PageTitle title="Add Product" />

			<LoadingOverlay
				loading={
					productQuery.isLoading
					// ||
					// colorQuery.isLoading ||
					// categoryQuery.isLoading
				}
			/>

			<Box sx={styles.container}>
				<Typography component="h2">Basic information</Typography>

				<Grid container spacing={3}>
					<Grid item md={6} sm={12} xs={12}>
						<FormInput
							label="Item Name"
							placeholder="Item Name"
							name="name"
							value={product.name}
							onChange={handleChange}
							error={errors.name}
							helperText={errors.name || ""}
						/>
					</Grid>

					<Grid item md={6} sm={12} xs={12}>
						<FormInput
							label="Brand"
							placeholder="Brand"
							name="brand"
							value={product.brand}
							onChange={handleChange}
							error={errors.brand}
							helperText={errors.brand || ""}
						/>
					</Grid>

					<Grid item md={12} sm={12} xs={12}>
						<CkEditor
							label="Description"
							placeholder="Description"
							value={product.description}
							name="description"
							handleChange={handleDescriptionChange}
							error={errors.description}
						/>
					</Grid>
				</Grid>

				<Divider sx={{ ...styles.divider, mt: 4.5, mb: 2.5 }} />

				<Typography component="h2">Images / Videos</Typography>

				<Box sx={styles.imageGrid}>
					<Box
						component="label"
						sx={styles.imageUploadContainer}
						htmlFor="imageInput"
					>
						<Upload />
						<Typography component="p">Upload</Typography>
					</Box>

					<input
						type="file"
						id="imageInput"
						accept="image/*, video/*"
						multiple
						onChange={handleImageChange}
						style={{ display: "none" }}
						key={product.images.length}
					/>

					{product.images.map((media, index) => (
						<Box key={index} position="relative">
							{media.type === "image" ? (
								<Box
									component="img"
									src={
										typeof media.path === "string"
											? media.path
											: URL.createObjectURL(media.path)
									}
									alt={`Product Image ${index}`}
									sx={styles.imageContainer}
									onClick={() => handleImagePreviewClick(media.path, "image")}
								/>
							) : media.type === "video" ? (
								<Box
									position="relative"
									onClick={() => handleImagePreviewClick(media.path, "video")}
								>
									<Box
										component="img"
										src={
											typeof media.thumbnail === "string"
												? media.thumbnail
												: URL.createObjectURL(media?.thumbnail)
										}
										alt={`Product Image ${index}`}
										sx={styles.imageContainer}
										onClick={() => handleImagePreviewClick(media.path, "image")}
									/>
									<PlayCircleOutlinedIcon sx={styles.videoIcon} />
								</Box>
							) : null}

							<Box
								component="img"
								src={Remove}
								sx={styles.removeIcon}
								onClick={() => handleRemoveImage(index)}
							/>
							{uploadProgress[media.name] > 0 && (
								<LinearProgress
									variant="determinate"
									value={uploadProgress[media.name] || 0}
									sx={{ mt: 1 }}
								/>
							)}
						</Box>
					))}
				</Box>

				<Divider sx={{ ...styles.divider, mt: 4, mb: 2.5 }} />

				<Typography component="h2">Pricing</Typography>

				<Grid container spacing={3}>
					<Grid item md={6} sm={12} xs={12}>
						<FormInput
							label="Price"
							placeholder="Price"
							name="price"
							type="number"
							value={product.price}
							onChange={handleChange}
							error={errors.price}
							helperText={errors.price || ""}
							inputProps={{ min: 0, max: 9999 }}
						/>
					</Grid>

					<Grid item md={6} sm={12} xs={12}>
						<FormInput
							label="URL"
							placeholder="Product Link"
							name="product_url"
							type="url"
							value={product.product_url}
							onChange={handleChange}
							error={errors.product_url}
							helperText={errors.product_url || ""}
						/>
					</Grid>
				</Grid>

				{/* <Divider sx={{ ...styles.divider, mt: 4, mb: 2.5 }} />

				<Typography component="h2">Categories</Typography>

				<Grid container>
					<Grid item md={6} sm={12} xs={12}>
						<FormAutoComplete
							label="Category"
							placeholder="Select Category"
							name="category_id"
							value={
								categories?.find(
									(item) => item.value === product.category_id
								) || ""
							}
							onChange={handleCategoryChange}
							options={categories}
							error={errors.category_id}
							helperText={errors.category_id || ""}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							disableClearable
						/>
					</Grid>
				</Grid>

				<Divider sx={{ ...styles.divider, mt: 4, mb: 2.5 }} />

				<Typography component="h2">Colors</Typography>

				<Box sx={styles.colorContainer}>
					{colorQuery.isLoading ? (
						<ColorSkeleton />
					) : (
						colors.map((color) => {
							const colorIndex = product.colors.findIndex(
								(selectedColor) => selectedColor === color.id
							);
							const isSelected = colorIndex !== -1;

							return (
								<Tooltip title={color.name} key={color.id}>
									<Paper
										sx={{
											...styles.color,
											backgroundColor: color.code,
											border: isSelected
												? "2px solid #135099"
												: "2px solid transparent", // Add border if selected
										}}
										onClick={() => handleColorClick(color)}
									>
										{isSelected && <CheckIcon sx={styles.checkIcon} />}
									</Paper>
								</Tooltip>
							);
						})
					)}
				</Box>

				<FormHelperText sx={styles.error}>{errors.colors || ""}</FormHelperText> */}
			</Box>

			<Stack direction="row" justifyContent="space-between">
				<Button
					variant="contained"
					sx={styles.backBtn}
					disableElevation
					onClick={() => navigate(-1)}
				>
					<RightArrow className="mr-1" />
					Back
				</Button>
				<LoadingButton
					variant="contained"
					sx={styles.saveBtn}
					onClick={handleSubmit}
					isLoading={mutation.isLoading}
				>
					Save Product
				</LoadingButton>
			</Stack>

			<ImagePreviewModal
				modal={modal}
				handleClose={handleClose}
				media={image}
			/>
		</>
	);
};

export default AddEditProduct;
