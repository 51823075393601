import { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import styles from "../../assets/styles/modal/qrCodeModal.styles";
import { Close } from "@mui/icons-material";
import QrCodeBorder from "../../assets/svg/QrCodeBorder.svg";
import { QRCode } from "react-qrcode-logo";
import ShareIcon from "@mui/icons-material/Share";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router";

const QrCodeModel = ({ open, handleClose, uuid, personName }) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const navigate = useNavigate();

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleOpenToolTip = () => {
    const encodedPersonName = encodeURIComponent(personName);
    navigator.clipboard.writeText(
      `${
        import.meta.env.VITE_SHARE_URL
      }/card/${uuid}?personName=${encodedPersonName}`
    );
    // navigate(`/share/card/${uuid}?personName=${encodedPersonName}`);
    setOpenToolTip(true);
  };

  const handleCloseToolTip = () => {
    setOpenToolTip(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={styles.qrWrapper}>
            <Box component="img" src={QrCodeBorder} alt="" />
            <Box sx={styles.qrCode}>
              <QRCode
                value={`${
                  import.meta.env.VITE_SHARE_URL
                }/card/${uuid}?personName=${personName}`}
                id={"qr-code"}
              />
            </Box>
          </Box>
          <Typography sx={styles.title}>Qr Code</Typography>
          <Typography sx={styles.subTitle}>
            Share or Download <br /> Your QR Code
          </Typography>
          <Box sx={styles.shareBtnWrapper}>
            <Tooltip
              open={openToolTip}
              onClose={handleCloseToolTip}
              onClick={handleOpenToolTip}
              title="Link Copied"
              placement="top"
            >
              <Button
                disableElevation
                variant="outlined"
                startIcon={<ShareIcon />}
              >
                Share
              </Button>
            </Tooltip>
          </Box>
          <Divider />
          <Box sx={styles.btnsWrapper}>
            <Button
              variant="contained"
              sx={styles.downloadBtn}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default QrCodeModel;
