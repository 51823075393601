const routes = {
  UNAUTHORIZE: "/unauthorize",
  NOTFOUND: "/notFound",
  CUSTOMER_LOGIN: "/login",
  CUSTOMER_SIGNUP: "/signup",
  CUSTOMER_FORGOT_PASSWORD: "/forgotPassword",
  CUSTOMER_VERIFY_EMAIL: "/verifyEmail",
  CUSTOMER_VERIFY_OTP: "/verifyOTP",
  CUSTOMER_VERIFY_ACCOUNT: "/verifyAccount",
  CUSTOMER_RESET_PASSWORD: "/resetPassword",
  CUSTOMER_CREATE_NEW_PASSWORD: "/createNewPassword",
  CUSTOMER_PROFILE: "/profile",
  CUSTOMER_PROFILE_ADD: "/profile/add",
  CUSTOMER_PROFILE_EDIT: "/profile/edit/",
  CUSTOMER_PRODUCT: "/product",
  CUSTOMER_PRODUCT_DETAIL: "/product/detail",
  CUSTOMER_PRODUCT_ADD: "/myProduct/add",
  CUSTOMER_PRODUCT_EDIT: "/myProduct/edit",
  CUSTOMER_PRODUCT_LISTING: "/myProduct",
  CUSTOMER_PAYMENT: "/payment",
  CUSTOMER_PAYMENT_CARD_ADD: "/payment/card/add",
  CUSTOMER_PAYMENT_CARD_LISTING: "/payment/card/listing",
  CUSTOMER_BUSINESS_CARD: "/knocknockCard",
  CUSTOMER_CREATE_CARD: "/knocknockCard/createCard",
  CUSTOMER_EDIT_CARD: "/knocknockCard/editCard",
  CUSTOMER_PUBLIC_CARD: "/share/card",
  CUSTOMER_BUSINESS_CARD_ORDER: "/knocknockCard/orderCard",
  CUSTOMER_CONTACT: "/contact",
  CUSTOMER_CONNECTIONS: "/connections",
  CUSTOMER_CREATE_CONNECTION: "/connections/createConnection",
  CUSTOMER_EDIT_CONNECTION: "/connections/editConnection",
  CUSTOMER_CARD_ORDER_HISTORY: "/cardOrderHistory",
  CUSTOMER_SETTINGS: "/settings",
  CUSTOMER_CONTACT_US: "/contactUs",
  CUSTOMER_ABOUT_US: "/aboutUs",
  CUSTOMER_PRIVACY_POLICY: "/privacyPolicy",
  CUSTOMER_TERMS_AND_CONDITIONS: "/termsAndConditions",
  CUSTOMER_DASHBOARD: "/dashboard",
  CUSTOMER_CHECKOUT: "/checkout",
  CUSTOMER_TEAMS: "/teams",
  CUSTOMER_TEAM_MEMBERS: "/teams",
  CUSTOMER_CREATE_TEAM: "/teams/createTeam",
  CUSTOMER_EDIT_TEAM: "/teams/editTeam",
  CUSTOMER_TEAM_MEMBER_EDIT_CARD: "/teams/editCard",
  CUSTOMER_TEAM_MEMBER_CREATE_CARD: "/teams/createCard",
  CUSTOMER_FORM_REGISTRATION: "/formRegistration",
  CUSTOMER_ORDERCARD: "/teams/orderCard",
  CUSTOMER_NOTIFICATION: "/notification",
  CUSTOMER_SUBSCRIPTION: "/subscription",

  PUBLIC_ABOUT_US: "/publicAboutUs",
  PUBLIC_CONTACT_US: "/publicContactUs",
  PUBLIC_PRIVACY_POLICY: "/publicPrivacyPolicy",
  PUBLIC_TERMS_AND_CONDITIONS: "/publicTermsAndConditions",

  ADMIN_LOGIN: "/admin/login",
  ADMIN_FORGOT_PASSWORD: "/admin/forgotPassword",
  ADMIN_VERIFY_EMAIL: "/admin/verifyEmail",
  ADMIN_VERIFY_OTP: "/admin/verifyOTP",
  ADMIN_RESET_PASSWORD: "/admin/resetPassword",
  ADMIN_CREATE_NEW_PASSWORD: "/admin/createNewPassword",
  ADMIN_CUSTOMERS: "/admin/customers",
  ADMIN_CUSTOMERS_ADD: "/admin/customers/add",
  ADMIN_CUSTOMERS_EDIT: "/admin/customers/edit",
  ADMIN_BUSINESS_CARD: "/admin/knocknockCard",
  ADMIN_CARD_ORDERS: "/admin/cardOrders",
  ADMIN_CATEGORY: "/admin/category",
  ADMIN_CATEGORY_ADD: "/admin/category/add",
  ADMIN_CATEGORY_EDIT: "/admin/category/edit",
  ADMIN_PRODUCT: "/admin/product",
  ADMIN_PRODUCT_ADD: "/admin/product/add",
  ADMIN_PRODUCT_EDIT: "/admin/product/edit",
  ADMIN_REPORTS: "/admin/reports",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_ABOUT_US: "/admin/aboutUs",
  ADMIN_PRIVACY_POLICY: "/admin/privacyPolicy",
  ADMIN_TERMS_AND_CONDITIONS: "/admin/termsAndConditions",
  ADMIN_CONTACT_US: "/admin/contactUs",
  ADMIN_CONTACT_US_DETAIL: "/admin/contactUs/detail",
  ADMIN_SEND_NOTIFICATION: "/admin/sendNotification",
  ADMIN_PRICING: "/admin/pricing",
  ADMIN_TEAMS: "/admin/teams",
  ADMIN_EDIT_TEAM: "/admin/teams/teamId",
  ADMIN_TEAM_DETAIL: "/admin/teams/teamDetail",
};

export default routes;
