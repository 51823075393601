import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import styles from "../../assets/styles/modal/confirmationModal.styles";
import { Close } from "@mui/icons-material";
import LoadingButton from "../button/LoadingButton";

const FeatureModal = ({
  open,
  handleClose,
  // handleConfirm,
  header,
  title,
  confirmButton,
  confirmButtonColor,
  isLoading,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box component="div" sx={styles.iconBox}>
              <Close sx={styles.crossIcon} />
            </Box>
            <Typography sx={styles.headerText}>
              {header ? header : "Are you sure?"}
            </Typography>
          </Stack>
          <Typography
            id="modal-modal-description"
            py={3.5}
            fontSize={17}
            textAlign="center"
            sx={styles.confirmTitle}
          >
            {title}
          </Typography>
          {/* <Stack
            direction="row"
            sx={styles.btnsWrapper}
            justifyContent="center"
            spacing={2}
            textAlign="justify"
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={{
                height: 40,
                textTransform: "capitalize",
                bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
                "&:hover": {
                  bgcolor: confirmButtonColor ? confirmButtonColor : "#E53935",
                },
              }}
              isLoading={isLoading}
            >
              {confirmButton}
            </LoadingButton>
          </Stack> */}
        </Box>
      </Modal>
    </>
  );
};

export default FeatureModal;
