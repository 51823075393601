import { useEffect, useMemo, useState } from "react";
import styles from "../../../assets/styles/customer/teamsListing.styles";
import PageTitle from "../../../components/common/PageTitle";
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import { MoreVertOutlined, Search } from "@mui/icons-material";
import {
	CARDS_DELETE,
	TEAM_DELETE,
	TEAM_LEAVE,
	TEAM_LISTING,
} from "../../../data/constants/apiRoutes";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";
import TeamPlaceholderImage from "../../../assets/images/teamPlaceholder.png";
import { useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import FormTab from "../../../components/tabs/FormTab";
import TabPanel from "../../../components/tabs/TabPanel";
import queryNames from "../../../data/constants/queryNames";
import { useQuery, useQueryClient } from "react-query";
import { destroy, get, post } from "../../../server";
import { useSnackbar } from "notistack";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import Loader from "../../../components/Loader";
import Pagination from "../../../components/pagination/Pagination";
import { loggedUserAtom } from "../../../data/store";
import { useAtom } from "jotai";
import RightBusinessDrawer from "../../../components/customer/RightBusinessDrawer";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import QrCodeModel from "../../../components/modal/QrCodeModel";
import { SUBSCRIPTION_KEY } from "../../../data/constants/feature";
import useFeature from "../../../hooks/useFeature";

const TeamsListing = () => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const [teams, setTeams] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [id, setId] = useState(null);
	const [tabValue, setTabValue] = useState(0);
	const [team_id, setTeamId] = useState(null);
	const [open, setOpen] = useState(false);
	const [currentCard, setCurrentCard] = useState({});
	const [menu, setMenu] = useState(null);
	const [modal, setModal] = useState(false);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [totalCount, setTotalCount] = useState(0);
	const [openQrModal, setOpenQrModal] = useState(false);
	const [filterText, setFilterText] = useState("");
	const { compareLimits,updateCount} = useFeature();

	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();
	const client = useQueryClient();

	const searchParams = new URLSearchParams(location.search);
	let type = searchParams.get("type");

	const menuOpen = Boolean(anchorEl);

	const handleFilter = (e) => {
		setFilterText(e.target.value);
	};

	const filteredItems = useMemo(() => {
		const filtered = teams.filter((item) =>
			`${item.name || ""} `.toLowerCase().includes(filterText.toLowerCase())
		);

		return filtered;
	}, [filterText, teams]);

	useEffect(() => {
		if (type === "myCard") setTabValue(1);
	}, [type]);

	useEffect(() => {
		if (type === "myCard" && tabValue === 0) navigate(routes.CUSTOMER_TEAMS);
	}, [type, tabValue]);

	const handleClick = (e, card, isMyTeam = false) => {
		if (isMyTeam) {
			setCurrentCard(card);
		}
		setMenu(
			card?.members?.find(
				(member) => member.card?.userId === loggedUser?.data?.id
			)
		);
		setAnchorEl(e.currentTarget);
		setId(card.id);

		const memberCard = card?.members?.find(
			(member) => member.id === loggedUser?.data?.id
		)?.card;
		if (memberCard) setCurrentCard(memberCard);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
		setAnchorEl(null);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleClose = () => {
		setAnchorEl(null);
		// setId(null);
	};

	const handleOpenDelete = () => {
		setModal("delete");
	};

	const handleLeaveTeam = (card) => {
		setModal("leave");
		setTeamId(card.id);
	};

	const handleCloseModal = () => {
		setModal(false);
	};

	const handleCreateCard = () => {
		navigate(`${routes.CUSTOMER_TEAM_MEMBER_CREATE_CARD}?teamId=${id}`);
	};

	const handleDeleteCard = () => {
		setModal("deleteCard");
	};

	const handleInvalidate = () => {
		client.invalidateQueries(queryNames.TEAM);
	};

	const handleDelete = async () => {
		const { status, message } = await destroy(`${TEAM_DELETE}/${id}`);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			setId(null);
		}
		handleCloseModal();
		handleClose();
	};

	const handleDeleteCardFunc = async () => {
		const { status, message } = await destroy(
			`${CARDS_DELETE}/${currentCard.id}`
		);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			// manage delete card
			updateCount("delete");
			setId(null);
		}
		handleCloseModal();
		handleClose();
		setCurrentCard(null);
	};

	const handleLeave = async () => {
		const { status, message } = await post(TEAM_LEAVE, {
			team_id: team_id,
		});
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			handleInvalidate();
			setId(null);
			setTeamId(null);
		}
		handleCloseModal();
		handleClose();
	};

	const getListing = async () => {
		let url;

		url = new URL(TEAM_LISTING);
		url.searchParams.append("page", paginationModel.page + 1);
		url.searchParams.append("limit", paginationModel.pageSize);
		if (tabValue === 1) url.searchParams.append("filterBy", "others");
		if (type === "myCard") url.searchParams.append("filterBy", "others");

		const { status, data } = await get(url.toString());
		if (status) {
			const newData = data?.data?.map((item) => ({
				...item,
				totalMembers: item?.members?.length + 1,
			}));
			setTeams(newData);
			setTotalCount(data.totalCount);
		}
	};

	const listingQuery = useQuery(
		[queryNames.TEAM, paginationModel.page, tabValue],
		getListing,
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (tabValue === 0) setOpen(false);
	}, [tabValue]);

	if (listingQuery.isLoading || listingQuery.isFetching) {
		return <Loader />;
	}

	const handleCheckAccess = (path,type) => {
		let res = compareLimits(type);
		if(res) {
			navigate(path)
		} else {
			return false;
		}
	};

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
			>
				<PageTitle title="Teams" />

				<Box>
					<Stack
						direction="row"
						alignItems="center"
						flexWrap={{ md: "nowrap", xs: "wrap" }}
						gap={2}
					>
						<FormInput
							placeholder="Search..."
							value={filterText}
							onChange={handleFilter}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton>
											<Search />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{/* {tabValue === 0 && (
							<Button sx={[styles.btn, styles.addMemberBtn]}>
								+ Add Members
							</Button>
						)} */}
						{tabValue === 0 && (
							<Button
								sx={[styles.btn, styles.addTeamBtn]}
								variant="contained"
								onClick={() => handleCheckAccess(routes.CUSTOMER_CREATE_TEAM,SUBSCRIPTION_KEY.team)}
							>
								Create Team
							</Button>
						)}
					</Stack>
				</Box>
			</Stack>

			<Grid container spacing={2} mt={2}>
				<Box>
					<FormTab
						tabValue={tabValue}
						setTabValue={setTabValue}
						tabs={["My Teams", "Other Teams"]}
						centered
					/>
				</Box>
			</Grid>

			<TabPanel value={tabValue} index={0}>
				{filteredItems.length > 0 ? (
					<Box sx={[styles.gridWrapper, styles.gridViewBorderWrapper]}>
						{filteredItems &&
							filteredItems?.map((card) => (
								<Box
									sx={styles.card}
									key={card.id}
									onClick={() => {
										if (!menuOpen)
											navigate(
												`${routes.CUSTOMER_TEAM_MEMBERS}/${card.id}/teamMembers`
											);
									}}
								>
									<Box>
										<Box sx={styles.profileWrapper}>
											{card.logo ? (
												<Box component="img" src={card.logo} />
											) : (
												<Box component="img" src={TeamPlaceholderImage} />
											)}
										</Box>
									</Box>

									<IconButton
										sx={styles.menuBtn}
										aria-controls={menuOpen ? "menu" : undefined}
										aria-haspopup="true"
										aria-expanded={menuOpen ? "true" : undefined}
										onClick={(e) => {
											e.stopPropagation();
											handleClick(e, card, true);
										}}
									>
										<MoreVertOutlined />
									</IconButton>
									<Menu
										anchorEl={anchorEl}
										id="menu"
										open={menuOpen}
										onClose={handleClose}
										onClick={handleClose}
										PaperProps={{
											elevation: 0,
											sx: styles.menu,
										}}
										transformOrigin={{ horizontal: "right", vertical: "top" }}
										anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
									>
										<MenuItem
											onClick={() =>
												navigate(`${routes.CUSTOMER_EDIT_TEAM}/${id}`)
											}
										>
											Edit
										</MenuItem>
										<Divider />
										<MenuItem onClick={handleOpenDelete}>Delete</MenuItem>
										<Divider />
										<MenuItem
											onClick={() =>
												navigate(
													`${routes.CUSTOMER_TEAM_MEMBERS}/${id}/teamMembers`
												)
											}
										>
											View Team Details
										</MenuItem>

										<Divider />
										<MenuItem
											onClick={() => handleCreateCard(card)}
											disabled={currentCard?.ownerData?.card ? true : false}
										>
											Create Card
										</MenuItem>
									</Menu>
									<Typography component="h3">{card.name}</Typography>
									<Typography component="p">
										Members: {card.totalMembers}
									</Typography>
									<Button
										variant="contained"
										sx={styles.orderPrintBtn}
										onClick={(e) => {
											e.stopPropagation();
											navigate(`${routes.CUSTOMER_ORDERCARD}/${card.id}`);
										}}
									>
										Order Card print
									</Button>
								</Box>
							))}
					</Box>
				) : (
					<Box sx={styles.noData}>
						<Typography component="h1">
							{teams.length > 0 ? "No result found" : "No record found"}
						</Typography>
					</Box>
				)}
			</TabPanel>

			<TabPanel value={tabValue} index={1}>
				{filteredItems.length > 0 ? (
					<Box sx={[styles.gridWrapper, styles.gridViewBorderWrapper]}>
						{filteredItems &&
							filteredItems?.map((card) => (
								<Box
									sx={styles.card}
									key={card.id}
									// onClick={() => {
									//   if (!menuOpen) {
									//     const memberCard = card?.members?.find(
									//       (member) => member.id === loggedUser?.data?.id
									//     )?.card;
									//     if (memberCard) {
									//       setCurrentCard(memberCard);
									//       // setOpen(true);
									//     }
									//   }
									// }}

									onClick={() => {
										if (!menuOpen)
											navigate(
												`${routes.CUSTOMER_TEAM_MEMBERS}/${card.id}/teamMembers/${card?.ownerData?.id}`
											);
									}}
								>
									<Box>
										<Box sx={styles.profileWrapper}>
											{card.logo ? (
												<Box component="img" src={card.logo} />
											) : (
												<Box
													component="img"
													src={TeamPlaceholderImage}
													sx={{ border: "1px solid #eee !important" }}
												/>
											)}
										</Box>
									</Box>

									<IconButton
										sx={styles.menuBtn}
										aria-controls={menuOpen ? "menu" : undefined}
										aria-haspopup="true"
										aria-expanded={menuOpen ? "true" : undefined}
										onClick={(e) => {
											e.stopPropagation();
											handleClick(e, card);
										}}
									>
										<MoreVertOutlined />
									</IconButton>
									{menu?.card ? (
										<Menu
											anchorEl={anchorEl}
											id="menu"
											open={menuOpen}
											onClose={handleClose}
											onClick={handleClose}
											PaperProps={{
												elevation: 0,
												sx: styles.menu,
											}}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
											key={card.id}
										>
											<MenuItem onClick={handleDrawerOpen}>View Card</MenuItem>
											<Divider />
											<MenuItem
												onClick={() =>
													navigate(
														`${routes.CUSTOMER_TEAM_MEMBER_EDIT_CARD}/${currentCard.id}?type=myCard`
													)
												}
											>
												Edit Card
											</MenuItem>
											<Divider />
											<MenuItem onClick={() => setOpenQrModal(true)}>
												Download QR
											</MenuItem>
											{/* <Divider />

											<MenuItem
												onClick={() =>
													navigate(
														`${routes.CUSTOMER_TEAM_MEMBER_EDIT_CARD}/${currentCard?.id}?duplicate=true&otherCard=true`
													)
												}
											>
												Duplicate Card
											</MenuItem> */}
											<Divider />
											<MenuItem onClick={handleDeleteCard}>
												Delete Card
											</MenuItem>

											<Divider />
											<MenuItem
												onClick={() => {
													navigate(
														`${routes.CUSTOMER_TEAM_MEMBERS}/${card.id}/teamMembers/${card?.ownerData?.id}`
													);
												}}
											>
												View Team Details
											</MenuItem>
											<Divider />
											<MenuItem onClick={() => handleLeaveTeam(card)}>
												Leave Team
											</MenuItem>
										</Menu>
									) : (
										<Menu
											anchorEl={anchorEl}
											id="menu"
											open={menuOpen}
											onClose={handleClose}
											onClick={handleClose}
											PaperProps={{
												elevation: 0,
												sx: styles.menu,
											}}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
											key={card.id}
										>
											<MenuItem
												onClick={() => {
													navigate(
														`${routes.CUSTOMER_TEAM_MEMBERS}/${currentCard.id}/teamMembers/${currentCard?.ownerData?.id}`
													);
												}}
											>
												View Team Details
											</MenuItem>
											<Divider />
											<MenuItem onClick={() => handleLeaveTeam(card)}>
												Leave Team
											</MenuItem>
											<Divider />
											<MenuItem
												onClick={() =>
													navigate(
														`${routes.CUSTOMER_TEAM_MEMBER_CREATE_CARD}?teamId=${id}&type=my-card`
													)
												}
											>
												Create Card
											</MenuItem>
										</Menu>
									)}
									<Typography component="h3">{card.name}</Typography>
									<Typography component="p">
										Members: {card.totalMembers}
									</Typography>
									{!card?.members?.find(
										(member) => member.card?.userId === loggedUser?.data?.id
									)?.card && (
										// <Typography component="p">No Card</Typography>
										<Tooltip title="No Card">
											<NoAccountsIcon sx={{ ...styles.noCard, top: 20 }} />
										</Tooltip>
									)}
								</Box>
							))}
					</Box>
				) : (
					<Box sx={styles.noData}>
						<Typography component="h1">
							{teams.length > 0 ? "No result found" : "No record found"}
						</Typography>
					</Box>
				)}
			</TabPanel>

			{teams.length > 0 && (
				<Pagination
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					totalCount={totalCount}
				/>
			)}

			<RightBusinessDrawer
				open={open}
				inViewMode={true}
				handleDrawerClose={handleDrawerClose}
				handleOpenDelete={handleDeleteCard}
				type="myCard"
				{...currentCard}
			/>

			<QrCodeModel
				open={openQrModal}
				handleClose={() => setOpenQrModal(false)}
				uuid={currentCard?.uuid}
				personName={currentCard?.fullName}
			/>

			<ConfirmationModal
				header="Are you sure?"
				title={
					tabValue === 0
						? "Do you really want to delete this record. This process cannot be undone."
						: modal === "deleteCard"
						? "Do you really want to delete your card? This process cannot be undone."
						: "Do you really want to leave this team? This process cannot be undone."
				}
				confirmButton={modal === "leave" ? "Leave" : "Delete"}
				confirmButtonColor="#E53935"
				open={modal}
				handleClose={handleCloseModal}
				handleConfirm={
					modal === "delete"
						? handleDelete
						: modal === "deleteCard"
						? handleDeleteCardFunc
						: handleLeave
				}
			/>
		</>
	);
};
export default TeamsListing;
