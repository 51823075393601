import Joi from "joi";

export const TeamSchema = Joi.object({
    name: Joi.string().max(50).required().messages({
        "string.empty": "Team name is required",
        "any.required": "Team name is required",
        "string.max": "Team name should be less than 50 characters",
    }),
})

export const TeamEmailSchema = Joi.object({
    email: Joi.string()
        .max(70)
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": `Please enter email address`,
            "string.email": `Please enter valid email address`,
            "string.max": `Email must be maximum 70 characters!`,
        }),
})
