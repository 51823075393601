import React, { useState } from "react";
import BusinessCardPreview from "../../../components/customer/BusinessCardPreview";
import { useSnackbar } from "notistack";
import { get } from "../../../server";
import { MY_CARD_EXISTS, SHARE_CARD } from "../../../data/constants/apiRoutes";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import routes from "../../../data/constants/routes";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import PageTitle from "../../../components/common/PageTitle";

const styles = {
	wrapper: {
		minHeight: "100vh",
		maxWidth: { md: 360, xs: 300 },
		margin: "auto",
		py: "30px",
	},
	btnWrapper: {
		mx: 2,
		"& button": {
			height: 45,
			width: "100%",
			boxShadow: "none",
			textTransform: "capitalize",
			borderRadius: "10px",
			bgcolor: "#D3A23B",
			"&:hover": {
				bgcolor: "#D3A23B",
				boxShadow: "none",
			},
		},
	},
	cancelBtn: {
		textTransform: "capitalize",
		color: "#000",
		bgcolor: "#E8EEF5",
		width: { md: 120, xs: 55 },
		height: 45,
		borderRadius: 2.5,
		fontWeight: 600,

		minWidth: { xs: 95 },
		"&:hover": {
			color: "#000",
			bgcolor: "#E8EEF5",
			opacity: 0.8,
		},
	},
};

const PublicCard = () => {
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
	const [card, setCard] = useState({});
	const { uuid } = useParams();
	const [myCard, setMyCard] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const personName = queryParams.get("personName");
	const superAdmin = queryParams.get("superAdmin");

	const { enqueueSnackbar } = useSnackbar();

	const isId = uuid !== null && typeof uuid !== "undefined";

	const fetchPublicCard = async () => {
		const { status, message, data } = await get(`${SHARE_CARD}/${uuid}`);

		if (status) {
			let newEmails = [];
			let newPhones = [];
			let newWebsites = [];

			data?.cardEmails.forEach((email) =>
				newEmails.push({ email: email.email })
			);

			data?.cardPhones.forEach((phone) =>
				newPhones.push({
					phone: `${phone.countryCode}${phone.phone}}`,
					country_code: phone.countryCode,
				})
			);

			data?.cardWebsites?.forEach((website) =>
				newWebsites.push({ title: website.title, url: website.url })
			);

			setCard({
				templateNo: data.templateNo || 1,
				fullName: data.fullName || "",
				department: data.department || "",
				title: data.title || "",
				headline: data.headline || "",
				profileImage: data.profileImage || "",
				instagram: data.instagram || "",
				website: data.website || "",
				backgroundImage: data.backgroundImage || "",
				primaryColor: data.primaryColor || "",
				logoImage: data.logoImage || "",
				address: data.address || "",
				company: data.company || "",
				facebook: data.facebook || "",
				linkedin: data.linkedin || "",
				youtube: data.youtube || "",
				calendar: data.calendar || "",
				cardEmails: newEmails,
				cardPhones: newPhones,
				cardWebsites: newWebsites,
				cardName: data.cardName || "",
				tiktok: data.tiktok || "",
				pinterest: data.pinterest || "",
				threads: data.threads || "",
				clubhouse: data.clubhouse || "",
				id: data.id || null,
				vimeo: data.vimeo || "",
			});
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}

		const myCard = await get(`${MY_CARD_EXISTS}/${uuid}`);
		if (myCard.status) {
			setMyCard(myCard.data.myCard);
		}
	};

	const publicCardQuery = useQuery([queryNames.CARD, uuid], fetchPublicCard, {
		enabled: isId,
		refetchOnWindowFocus: false,
	});

	const handleSave = () => {
		if (!loggedUser?.token)
			window.open(
				`${routes.CUSTOMER_FORM_REGISTRATION}?cardId=${card.id}`,
				"_blank"
			);
		else {
			sessionStorage.setItem("cardId", card.id);
			navigate(routes.CUSTOMER_BUSINESS_CARD);
		}
	};

	if (publicCardQuery.isLoading) {
		return <Loader />;
	}

	return (
		<>
			{superAdmin ? (
				<Box mb={1.5}>
					<Button
						variant="contained"
						sx={styles.cancelBtn}
						onClick={() => navigate(routes.ADMIN_BUSINESS_CARD)}
						disableElevation
					>
						<RightArrow className="mr-1" />
						Back
					</Button>
				</Box>
			) : null}
			{superAdmin && <PageTitle title="Knocknoc Card Detail" />}
			<Box py={5} sx={styles.wrapper}>
				<BusinessCardPreview {...card} inViewMode={true} />

				{!myCard && personName && (
					<Box mt={2} sx={styles.btnWrapper}>
						<Button variant="contained" onClick={handleSave}>
							Save Contact
						</Button>
					</Box>
				)}
			</Box>
		</>
	);
};

export default PublicCard;
