import { useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { useSnackbar } from "notistack";
import { validator } from "../../../utils/helpers/validator";
import NotificationSchema from "../../../utils/schemas/NotificationSchema";
import { post } from "../../../server";
import { NOTIFICATION_SEND } from "../../../data/constants/apiRoutes";
import { useMutation } from "react-query";
import { Box, Grid } from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import LoadingButton from "../../../components/button/LoadingButton";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
};

const initialState = {
  title: "",
  description: "",
};

const AddNotification = () => {
  const [notification, setNotification] = useState(initialState);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "title") {
      if (value.length > 300) {
        value = value.slice(0, 300);
      }
    } else if (name === "description") {
      if (value.length > 5000) {
        value = value.slice(0, 5000);
      }
    }

    setNotification({ ...notification, [name]: value });
  };

  const validateForm = () => {
    let newErrors = validator(notification, NotificationSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSendNotification = async () => {
    const { status, message, data } = await post(
      NOTIFICATION_SEND,
      notification
    );
    if (status) {
      enqueueSnackbar(message, { variant: "success" });
      setNotification(initialState);
    } else enqueueSnackbar(message || data, { variant: "error" });
  };

  const mutation = useMutation(handleSendNotification);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  return (
    <>
      <PageTitle title="Send Notification" />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormInput
                label="Title"
                placeholder="Title"
                name="title"
                value={notification.title}
                onChange={handleChange}
                error={errors?.title}
                helperText={errors?.title || ""}
              />
            </Grid>

            <Grid item sx={12} md={12}>
              <FormInput
                label="Description"
                placeholder="Description"
                name="description"
                value={notification.description}
                onChange={handleChange}
                error={errors?.description}
                helperText={errors?.description || ""}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </Box>
        <LoadingButton
          variant="contained"
          disableElevation
          sx={styles.submitBtn}
          type="submit"
          onClick={handleSubmit}
          isLoading={mutation.isLoading}
        >
          Send
        </LoadingButton>
      </Box>
    </>
  );
};

export default AddNotification;
