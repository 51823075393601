import { useState } from "react";
import { get, post } from "../../../server";
import {
  PRINT_ORDER_CARD_PRICING_LISTING,
  PRINT_ORDER_CARD_PRICING_UPDATE,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";

import Loader from "../../../components/Loader";
import { useMutation, useQuery, useQueryClient } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import PageTitle from "../../../components/common/PageTitle";
import DataGridListing from "../../../components/listing/DataGridListing";
import { Box, Button, IconButton, Modal, Stack, Tooltip } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

import FormInput from "../../../components/forms/FormInput";
import { validator } from "../../../utils/helpers/validator";
import PricingSchema from "../../../utils/schemas/PricingSchema";
import LoadingButton from "../../../components/button/LoadingButton";

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: 360, xs: 310 },
    // height: 280,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 15,
    p: 2,
    "& button": {
      width: 120,
      boxShadow: "none",
      fontWeight: 600,
    },
  },
  iconBox: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    textAlign: "center",
    border: "3px solid #E53935",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  crossIcon: {
    width: 40,
    height: 40,
    color: "#E53935",
  },
  closeIconBtnWrapper: {
    textAlign: "end",
  },
  closeIconBtn: {
    textAlign: "end",
    width: "40px !important",
    height: 40,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    flexGrow: 1,
    textAlign: "center",
    color: "#636363",
  },
  confirmTitle: {
    color: "#838383",
    marginInline: "10px",
    fontSize: 14,
  },
  cancelBtn: {
    bgcolor: "#c1c1c1",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    "&:hover": { bgcolor: "#c1c1c1" },
  },
  confirmBtn: {
    bgcolor: "#D3A23B",
    color: "#ffffff",
    height: 45,
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      bgcolor: "#D3A23B",
    },
  },

  btnsWrapper: {
    mt: 4,
    mb: 2,
  },
};

const PricingListing = () => {
  const [prices, setPrices] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errors, setErrors] = useState({});

  const client = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedData({});
    setErrors({});
    setOpen(false);
  };

  const handleChange = (e) => {
    let value = e.target.value;

    const regex = /^(\d{1,6}(\.\d{0,2})?)?$/;

    if (!regex.test(value)) {
      return;
    }
    setSelectedData({ ...selectedData, price: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = validator(selectedData, PricingSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const fetchPricing = async () => {
    let { status, message, data } = await get(PRINT_ORDER_CARD_PRICING_LISTING);

    if (status) {
      setPrices(data);
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const pricingQuery = useQuery(queryNames.PRICING, fetchPricing, {
    refetchOnWindowFocus: false,
  });

  const handleFormRequest = async () => {
    const { status, message } = await post(
      `${PRINT_ORDER_CARD_PRICING_UPDATE}/${selectedData.id}`,
      selectedData
    );

    if (status) {
      setPrices(
        prices.map((price) => {
          if (price.id === selectedData.id) {
            return {
              ...price,
              price: selectedData.price,
            };
          }
          return price;
        })
      );
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleFormRequest, {
    onSuccess: () => {
      client.invalidateQueries([queryNames.PRICING]);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  const columns = [
    {
      field: "name",
      headerName: "Type",
      sortable: false,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) =>
        params.row.name === "basic" ? "Basic" : "Premium",
    },

    {
      field: "price",
      headerName: "Price",
      sortable: false,
      flex: 1,
      valueGetter: (params) => `$ ${params.row.price}`,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tooltip title="Edit Price" placement="top">
                <IconButton
                  disableRipple
                  onClick={() => handleOpen(params.row)}
                  size="small"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];

  if (pricingQuery.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="KnocKnoc Print Card Pricing" />
      <DataGridListing rows={prices} columns={columns} mode="client" />

      <Modal
        open={open}
        aria-describedby="modal-modal-description"
        size="small"
        onClose={handleClose}
      >
        <Box sx={styles.container}>
          <Box sx={styles.closeIconBtnWrapper}>
            <IconButton onClick={handleClose} sx={styles.closeIconBtn}>
              <Close />
            </IconButton>
          </Box>

          <FormInput
            type="text"
            label="Type"
            withasterisk
            placeholder="Enter name"
            name="name"
            value={selectedData.name}
            onChange={handleChange}
            error={errors?.name}
            helperText={errors?.name || ""}
            disabled
          />
          <Box mt={2}>
            <FormInput
              type="number"
              label="Price"
              withasterisk
              placeholder="Enter Price"
              name="price"
              value={selectedData.price}
              onChange={handleChange}
              InputProps={{
                inputProps: { min: 0 },
              }}
              error={errors?.price}
              helperText={errors?.price || ""}
            />
          </Box>
          <Stack
            direction="row"
            sx={styles.btnsWrapper}
            justifyContent="center"
            spacing={2}
            textAlign="justify"
          >
            <Button
              variant="contained"
              sx={styles.cancelBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={styles.confirmBtn}
              onClick={handleSubmit}
              isLoading={mutation.isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default PricingListing;
