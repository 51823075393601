import { useRef, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import styles from "../../../assets/styles/customer/addEditTeam.styles";
import { ReactComponent as RightArrow } from "../../../assets/svg/RightArrow.svg";
import {
	Avatar,
	Box,
	Button,
	Divider,
	FormHelperText,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import FormInput from "../../../components/forms/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import {
	TEAM_ADD,
	TEAM_DETAIL,
	TEAM_MEMBERS,
	TEAM_UPDATE,
} from "../../../data/constants/apiRoutes";
import { useMutation, useQuery } from "react-query";
import LoadingButton from "../../../components/button/LoadingButton";
import awsFileUpload from "../../../utils/helpers/awsFileUpload";
import { uploadImageType } from "../../../data/constants/uploadImageTypes";
import { useSnackbar } from "notistack";
import { get, post } from "../../../server";
import routes from "../../../data/constants/routes";
import { validator } from "../../../utils/helpers/validator";
import { TeamEmailSchema, TeamSchema } from "../../../utils/schemas/TeamSchema";
import FormAutoComplete from "../../../components/forms/FormAutoComplete";
import { ReactComponent as PlaceholderImage } from "../../../assets/svg/createCard/PlaceholderImage.svg";
import { ReactComponent as Cross } from "../../../assets/svg/businesscard/Cross.svg";
import _ from "lodash";
import queryNames from "../../../data/constants/queryNames";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import CropAvatar from "../../../components/modal/CropAvatar";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";

const initialState = {
	name: "",
	logo: "",
	members: [],
	inviteMembers: [],
};

const AddEditTeam = () => {
	const [initialFormState, setInitialFormState] = useState(initialState);
	const [team, setTeam] = useState(initialState);
	const [email, setEmail] = useState("");
	const [members, setMembers] = useState([]);
	const [search, setSearch] = useState(false);
	const [selectedMembers, setSelectedMembers] = useState([]);
	const [newSelectedMembers, setNewSelectedMembers] = useState([]);
	const [modal, setModal] = useState(false);
	const [cropModal, setCropModal] = useState(false);
	const [image, setImage] = useState(null);
	const [loggedUser] = useAtom(loggedUserAtom);

	const { teamId } = useParams();
	const isId = teamId !== null && typeof teamId !== "undefined";
	const imageRef = useRef();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;

		if (name === "image") {
			// setTeam({ ...team, logo: e.target.files[0] });
			setImage(URL.createObjectURL(e.target.files[0]));
			setCropModal("logo");
		} else {
			setTeam({ ...team, [name]: value });
		}
	};

	const handleAvatarClick = () => {
		imageRef.current.click();
	};

	const fetchTeam = async () => {
		const { status, message, data } = await get(`${TEAM_DETAIL}/${teamId}`);
		if (status === true) {
			setTeam({
				id: data.id,
				logo: data.logo,
				name: data.name,
			});
			setSelectedMembers(data.members);
			setNewSelectedMembers(data.invited_members);
			delete data.first_name;
			delete data.last_name;
			delete data.ownerData;
			delete data.status;
			setInitialFormState(data);
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const teamQuery = useQuery([queryNames.TEAM, teamId], fetchTeam, {
		enabled: isId,
		refetchOnWindowFocus: false,
	});

	const validateEmail = () => {
		let newErrors = validator({ email: email }, TeamEmailSchema);

		if (
			selectedMembers.some((item) => item.email === email) ||
			newSelectedMembers.some((item) => item.email === email)
		) {
			newErrors = { email: "Email already added" };
		}

		setErrors(newErrors);
		if (Object.keys(newErrors).length === 0) return true;
		return false;
	};

	const handlSearch = async () => {
		let url;

		if (email.length > 0) {
			url = new URL(TEAM_MEMBERS);
			url.searchParams.append("email", email);
			const { status, message, data } = await get(url.toString());
			if (status) {
				const newData = {
					id: data.id,
					label: `${data?.first_name} ${data?.last_name} - ${data?.email}`,
					email: data.email,
					image: data?.image,
					first_name: data?.first_name,
					last_name: data?.last_name,
				};
				setMembers([newData]);
				setSearch(true);
			} else {
				if (message === "Record Not Found") {
					const newData = {
						email: email,
						label: email,
						newUser: true,
					};
					setMembers([newData]);
					setSearch(true);
				}
			}
		}
	};

	const emailMutation = useMutation(handlSearch);

	const handlemailSearch = (e) => {
		e.preventDefault();
		if (!validateEmail()) return;
		emailMutation.mutate();
	};

	const validateForm = () => {
		let newErrors = validator(team, TeamSchema);

		setErrors(newErrors);
		if (Object.keys(newErrors).length === 0) return true;
		return false;
	};

	const handleSaveTeam = async () => {
		let payload = _.cloneDeep(team);

		let newStatus = true;
		if (typeof payload.logo === "object") {
			const data = await awsFileUpload(
				payload.logo,
				uploadImageType.createTeam
			);
			if (data.status === true) {
				payload.logo = data.location;
			} else {
				enqueueSnackbar("Error uploading Logo", { variant: "error" });
				return (newStatus = false);
			}
		}

		if (newStatus) {
			const { status, message } = await post(
				isId ? `${TEAM_UPDATE}/${teamId}` : TEAM_ADD,
				{
					...payload,
					members: selectedMembers.map((item) => item.id),
					inviteMembers: newSelectedMembers.map((item) => ({
						email: item.email,
						...(item.id && { id: item.id }),
					})),
				}
			);
			if (status === true) {
				enqueueSnackbar(message, { variant: "success" });
				navigate(routes.CUSTOMER_TEAMS);
			} else {
				enqueueSnackbar(message, { variant: "error" });
			}
		}
	};

	const mutation = useMutation(handleSaveTeam);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateForm()) return;
		mutation.mutate();
	};

	const hanldeEmailSelect = (e, value) => {
		if (value) {
			if (value?.newUser) {
				setNewSelectedMembers([...newSelectedMembers, value]);
			} else {
				setSelectedMembers([...selectedMembers, value]);
			}
			setEmail("");
			setSearch(false);
		}
	};

	const handleRemove = (index) => {
		const newMembers = [...selectedMembers];
		newMembers.splice(index, 1);
		setSelectedMembers(newMembers);
	};

	const handleRemoveInvited = (index) => {
		const newMembers = [...newSelectedMembers];
		newMembers.splice(index, 1);
		setNewSelectedMembers(newMembers);
	};

	const handleCanel = () => {
		const newState = {
			...team,
			members: selectedMembers,
		};
		newState.invited_members =
			newSelectedMembers.length > 0 ? newSelectedMembers : [];
		console.log(initialFormState, newState);
		if (!_.isEqual(initialFormState, newState)) {
			setModal(true);
		} else navigate(routes.CUSTOMER_TEAMS);
	};

	if (teamQuery.isLoading || teamQuery.isFetching) {
		return <Loader />;
	}

	return (
		<>
			<PageTitle title={`${isId ? "Edit" : "Create"} Team`} />

			<Box sx={styles.mainWrapper}>
				<Box mt={2}>
					<Avatar
						sx={styles.avatar}
						onClick={handleAvatarClick}
						src={
							team?.logo
								? typeof team?.logo === "string"
									? team?.logo
									: URL.createObjectURL(team?.logo)
								: null
						}
					>
						<CameraAltOutlinedIcon />
					</Avatar>
					<input
						type="file"
						accept="image/*"
						hidden
						name="image"
						onChange={handleChange}
						id="avatarUpload"
						ref={imageRef}
						key={team?.logo}
					/>
				</Box>

				<Box sx={styles.wrapper}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<FormInput
								value={team.name}
								label="Team Name"
								withasterisk
								placeholder="Team Name"
								name="name"
								onChange={handleChange}
								error={errors.name}
								helperText={errors.name}
							/>
						</Grid>
						<Grid item xs={12} md={6}></Grid>
						<Grid item xs={12} md={12}>
							<Divider />
						</Grid>
						<Grid item xs={12} md={8}>
							<Typography variant="h5" sx={styles.heading}>
								Manage team members
							</Typography>
							<Typography component="p" sx={styles.subHeading}>
								Add new or remove existing members in your team.
							</Typography>
							<Stack
								direction="row"
								alignItems="center"
								flexWrap={{ md: "nowrap", xs: "wrap" }}
								// rowGap={2}
								spacing={{ md: 2, xs: 1 }}
							>
								{!search ? (
									<FormInput
										placeholder="Search or add team member"
										vaue={email}
										onChange={(e) => setEmail(e.target.value)}
										error={errors.email}
										// InputProps={{
										// 	endAdornment: (
										// 		<InputAdornment position="end">
										// 			<IconButton onClick={handlemailSearch}>
										// 				<SearchIcon />
										// 			</IconButton>
										// 		</InputAdornment>
										// 	),
										// }}
										onKeyDown={(e) => {
											if (e.key === "Enter") handlemailSearch(e);
										}}
									/>
								) : (
									<FormAutoComplete
										fullWidth
										name="email"
										options={members}
										inputValue={email}
										open
										sx={{
											"& .MuiOutlinedInput-root": {
												borderRadius: "10px",
												height: 57,
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #E2E3E4",
											},
										}}
										onChange={hanldeEmailSelect}
										renderOption={(props, option) => (
											<li {...props}>
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="space-between"
													width="100%"
												>
													{option?.newUser ? (
														<Typography component="label">
															User not found, invite{" "}
															<Typography
																component="label"
																sx={{ fontWeight: 500 }}
															>
																{option.email}
															</Typography>{" "}
															?
														</Typography>
													) : (
														option.label
													)}
													<Button
														variant="contained"
														sx={styles.addBtn}
														onClick={(e) => hanldeEmailSelect(e, option)}
													>
														{option?.newUser ? "Invite" : "Add"}
													</Button>
												</Stack>
											</li>
										)}
										getOptionLabel={(option) => option.label}
									/>
								)}
								<LoadingButton
									variant="contained"
									sx={{ ...styles.btn, ...styles.addBtn }}
									onClick={(e) =>
										!search ? handlemailSearch(e) : setSearch(false)
									}
									isLoading={emailMutation.isLoading}
								>
									{!search ? "Search" : "Clear"}
								</LoadingButton>
							</Stack>
							{errors?.email && (
								<FormHelperText sx={{ fontSize: 14, color: "#d32f2f" }}>
									{errors?.email}
								</FormHelperText>
							)}
						</Grid>
					</Grid>
					{selectedMembers.length > 0 && (
						<Typography variant="h6" sx={styles.name} my={2}>
							Existing Users
						</Typography>
					)}
					<Box sx={styles.gridWrapper}>
						{selectedMembers?.map((card, index) => (
							<Box sx={styles.card} key={card.id}>
								<Box>
									<Box sx={styles.profileWrapper}>
										{card.logo ? (
											<Box component="img" src={card.logo} />
										) : (
											<PlaceholderImage />
										)}
									</Box>
								</Box>

								{card.id !== loggedUser.data.id && (
									<IconButton
										sx={styles.menuBtn}
										onClick={() => handleRemove(index)}
									>
										<Cross />
									</IconButton>
								)}
								<Typography component="h3">
									{card?.first_name} {card?.last_name}
									{/* {card.name} */}
								</Typography>
								<Typography component="p">{card.email}</Typography>
								{card.id === loggedUser.data.id && (
									<Typography component="p">Owner</Typography>
								)}
							</Box>
						))}
					</Box>

					{newSelectedMembers.length > 0 && (
						<Typography variant="h6" sx={styles.name} my={2}>
							Invited Members
						</Typography>
					)}
					<Box sx={styles.gridWrapper}>
						{newSelectedMembers?.map((card, index) => (
							<Box sx={styles.card} key={index}>
								<Box>
									<Box sx={styles.profileWrapper}>
										<PlaceholderImage />
									</Box>
								</Box>

								<IconButton
									sx={styles.menuBtn}
									onClick={() => handleRemoveInvited(index)}
								>
									<Cross />
								</IconButton>
								<Typography component="h3">{card.email}</Typography>
								<Typography component="p">Invited</Typography>
							</Box>
						))}
					</Box>
				</Box>
			</Box>

			<Stack
				direction="row"
				justifyContent="space-between"
				flexWrap={{ md: "nowrap", xs: "wrap" }}
				rowGap={2}
				spacing={{ md: 2, xs: 0 }}
				pb={2}
			>
				<Button
					variant="contained"
					sx={[styles.btn, styles.removeBtn]}
					disableElevation
					onClick={() => navigate(routes.CUSTOMER_TEAMS)}
				>
					<RightArrow className="mr-1" />
					Back
				</Button>

				<Stack direction="row" columnGap={2}>
					<Button
						variant="contained"
						sx={[styles.btn, styles.removeBtn]}
						disableElevation
						onClick={handleCanel}
					>
						Cancel
					</Button>

					<LoadingButton
						variant="contained"
						sx={{ ...styles.btn, ...styles.addBtn }}
						disableElevation
						onClick={handleSubmit}
						isLoading={mutation.isLoading}
					>
						Save
					</LoadingButton>
				</Stack>
			</Stack>

			<CropAvatar
				modal={cropModal}
				setModal={setCropModal}
				image={image}
				user={team}
				setUser={setTeam}
				cropType=""
				aspectRatio={4 / 4}
			/>

			<ConfirmationModal
				header="Are you sure?"
				title={`Do you really want to cancel? This process cannot be undone.`}
				confirmButton="OK"
				confirmButtonColor="#E53935"
				open={modal}
				handleClose={() => setModal(false)}
				handleConfirm={() => navigate(routes.CUSTOMER_TEAMS)}
			/>
		</>
	);
};

export default AddEditTeam;
