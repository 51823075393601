import { useState } from "react";
import {
  NOTIFICATION_LISTING,
  NOTIFICATION_READ,
} from "../../../data/constants/apiRoutes";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loader";
import { get, post } from "../../../server";
import { useSnackbar } from "notistack";
import PageTitle from "../../../components/common/PageTitle";
import moment from "moment/moment";
import Pagination from "../../../components/pagination/Pagination";
import { useNavigate } from "react-router";
import routes from "../../../data/constants/routes";
import NotificationLogo from "../../../assets/images/notificationLogo.png";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    py: 2,
    "& h3": {
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 500,
      px: 2,
      textAlign: { xs: "end" },
    },
  },
  notification: {
    borderBottom: "1px solid #E0E0E0",
    "&:last-child": {
      borderBottom: "none",
    },
    "& .MuiListItem-root": {
      py: 0.5,
      pb: 0.5,
    },
    "& .MuiMenu-paper": {
      borderRadius: 5,
    },
    "& .MuiMenu-list": {
      width: 320,
      maxHeight: 330,
      "& p": {
        display: "-webkit-box",
        "-webkitLineClamp": "2",
        "-webkitBoxOrient": "vertical",
        overflow: "hidden",
      },
    },
    "& label": {
      color: "#1E1E1F",
      fontWeight: 500,
      px: 2,
      cursor: "pointer",
    },
  },
};

const NotificationsListing = () => {
  const [notifications, setNotifications] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const fetchNotification = async () => {
    let url;
    url = new URL(NOTIFICATION_LISTING);
    url.searchParams.append("page", paginationModel.page + 1);
    url.searchParams.append("limit", paginationModel.pageSize);

    const { status, message, data } = await get(url.toString());
    if (status) {
      setNotifications(data.data);
      setTotalCount(data.total_count);
    } else enqueueSnackbar(message, { variant: "error" });
  };

  const notificationQuery = useQuery(
    [queryNames.NOTIFICATIONS, paginationModel.page],
    fetchNotification,
    {
      refetchOnWindowFocus: false,
    }
  );

  const notificatioRead = async () => {
    const unReadNotifications = notifications
      .filter((notification) => notification.is_read === false)
      .map((notification) => notification.id);
    if (unReadNotifications.length > 0) {
      const { status, message, data } = await post(NOTIFICATION_READ, {
        notificationIds: unReadNotifications,
      });
    }
  };

  const notificationReadQuery = useQuery(
    queryNames.NOTIFICATIONS,
    notificatioRead,
    {
      enabled: notifications.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  function formatTime(time) {
    const now = moment();
    const messageTime = moment(time);

    if (now.isSame(messageTime, "day")) {
      return `Today, ${messageTime.format("hh:mm A")}`;
    } else {
      return messageTime.format("D MMM");
    }
  }

  const handleClick = (type) => {
    if (type === "order" || type === "order_created") navigate(routes.CUSTOMER_CARD_ORDER_HISTORY);
    else if (type === "added_to_team")
      navigate(`${routes.CUSTOMER_TEAMS}?type=myCard`);
    else if (type === "general") navigate(routes.CUSTOMER_NOTIFICATION);
  };

  if (notificationQuery.isLoading || notificationQuery.isFetching) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title="Notifications" />
      <Box sx={styles.wrapper}>
        {notifications.length > 0 ? (
          notifications.map(({ id, title, description, created_at, type }) => (
            <Box key={id} sx={styles.notification}>
              <Stack
                direction={{ md: "row", xs: "column" }}
                justifyContent="space-between"
                alignItems="center"
                mb={{ md: 0, xs: 2 }}
              >
                <ListItem button onClick={() => handleClick(type)}>
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={NotificationLogo} />
                  </ListItemAvatar>
                  <ListItemText primary={title} secondary={description} />
                </ListItem>
                <Typography component="h3" width={300}>
                  {moment(created_at).format("h:mm a  MM/DD/YYYY")}
                </Typography>
              </Stack>
            </Box>
          ))
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              // component="h3"
              width={120}
              textAlign="center"
              mt={2}
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: 500,
              }}
            >
              No Notifications
            </Typography>
          </Box>
        )}
      </Box>
      {notifications.length > 0 && (
        <Pagination
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalCount={totalCount}
        />
      )}
    </>
  );
};

export default NotificationsListing;
