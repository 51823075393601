import { useEffect, useState } from "react";
import SplashForm from "../../../components/auth/SplashForm";
import routes from "../../../data/constants/routes";
import {
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { validator } from "../../../utils/helpers/validator";
import { SignupSchema } from "../../../utils/schemas/AuthSchema";
import { CUSTOMER_SIGNUP } from "../../../data/constants/apiRoutes";
import { post } from "../../../server";
import FormInput from "../../../components/forms/FormInput";
import { loggedUserAtom } from "../../../data/store";
import { useAtom } from "jotai";
// import styles from "../../../assets/styles/signup.styles";

const styles = {
  privacyLinkWrapper: {
    mx: "3px",
  },
  privacyLink: {
    color: "inherit",
    lineHeight: "24px",
    textDecoration: "none",
    borderBottom: "1px solid #5C5C5C",
  },
};
const Signup = () => {
  const [signupForm, setSignupForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: null,
    confirm_password: null,
  });

  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [terms, setTerms] = useState(false);

  const [errors, setErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUser?.data) {
      setSignupForm({
        first_name: loggedUser?.data?.first_name || "",
        last_name: loggedUser?.data?.last_name || "",
        email: loggedUser?.data?.email || "",
        password: null,
        confirm_password: null,
      });
    }
  }, [loggedUser]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "first_name" || name === "last_name") {
      value = value.slice(0, 25);
    }

    setSignupForm({ ...signupForm, [name]: value });
  };

  const validateForm = () => {
    let newErrors = validator(signupForm, SignupSchema);
    if (!signupForm.confirm_password) {
      newErrors = {
        ...newErrors,
        confirmPassword: "Please enter confirm password",
      };
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleSignup = async () => {
    const { status, message, data } = await post(CUSTOMER_SIGNUP, signupForm);
    if (status === true) {
      const token = data.token;
      delete data.token;
      delete data.password;
      const newData = {
        token: token,
        data: {
          ...data,
          login_type: "signup",
          verifyOtp: true,
          share_card: loggedUser?.data?.share_card,
        },
      };
      setLoggedUser(newData);
      // enqueueSnackbar("Sign up Succesfull!!", { variant: "success" });
      navigate(routes.CUSTOMER_BUSINESS_CARD);
      // navigate(routes.CUSTOMER_VERIFY_ACCOUNT);
    } else {
      if (message === "The email has already been taken.")
        enqueueSnackbar(
          "There is already an account associated with this email",
          { variant: "error" }
        );
      else enqueueSnackbar(message, { variant: "error" });
    }
  };

  const mutation = useMutation(handleSignup);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!terms) {
      enqueueSnackbar("Please Agree to Terms & Conditions", {
        variant: "error",
      });
      return;
    }
    mutation.mutate();
  };

  return (
    <>
      <SplashForm
        title="Sign up"
        btnText="Sign up"
        linkTaglinePlainText="Already have an account? "
        linkTaglineText="Login"
        linkTaglineHref={routes.CUSTOMER_LOGIN}
        handleSubmit={handleSubmit}
        isLoading={mutation.isLoading}
      >
        <>
          <FormInput
            type="text"
            name="first_name"
            value={signupForm.first_name}
            onChange={handleChange}
            placeholder="First Name"
            error={errors?.first_name}
            helperText={errors?.first_name}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <FormInput
            type="text"
            name="last_name"
            value={signupForm.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            error={errors?.last_name}
            helperText={errors?.last_name}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <FormInput
            type="email"
            name="email"
            value={signupForm.email}
            onChange={handleChange}
            placeholder="Email"
            error={errors?.email}
            helperText={errors?.email || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <FormInput
            type={showPassword ? "text" : "password"}
            name="password"
            value={signupForm.password}
            placeholder="Password"
            onChange={handleChange}
            error={errors?.password}
            helperText={errors?.password || ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <FormInput
            type={showConfirmPassword ? "text" : "password"}
            name="confirm_password"
            placeholder="Confirm Password"
            value={signupForm.confirm_password}
            onChange={handleChange}
            error={errors?.confirm_password}
            helperText={errors?.confirm_password || ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowConfirmPassword}>
                    {showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
                sx={{ color: "#E2E3E4" }}
                size="small"
              />
            }
            // label="I agree to the terms & conditions"
            label={
              <Box component="span">
                I agree to the
                <Box component="span" sx={styles.privacyLinkWrapper}>
                  <a
                    href={routes.PUBLIC_TERMS_AND_CONDITIONS}
                    style={styles.privacyLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </Box>
                and
                <Box component="span" sx={styles.privacyLinkWrapper}>
                  <a
                    href={routes.PUBLIC_PRIVACY_POLICY}
                    style={styles.privacyLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Box>
              </Box>
            }
            sx={{
              mt: "-10px",
              "& span": {
                fontSize: { md: 16, sm: 13, xs: 13 },
              },
            }}
          />
        </>
      </SplashForm>
    </>
  );
};

export default Signup;
