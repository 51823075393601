import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/common/PageTitle";
import { useSnackbar } from "notistack";
import { Box, Grid } from "@mui/material";
import CustomInputField from "../../../components/forms/CustomInputField";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import queryNames from "../../../data/constants/queryNames";
import { get } from "../../../server";
import { CONTACT_US } from "../../../data/constants/apiRoutes";
import Loader from "../../../components/Loader";

const styles = {
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  submitBtn: {
    bgcolor: "#135099",
    borderRadius: 2.5,
    mt: 2,
    minWidth: 130,
    height: 45,
  },
};
const ContactUsDetail = () => {
  const [contactUs, setContactUs] = useState({});
  const { contactUsId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const isId = contactUsId !== null && typeof contactUsId !== "undefined";

  const fetchContactUsDetail = async () => {
    const { status, message, data } = await get(`${CONTACT_US}/${contactUsId}`);

    if (status) {
      setContactUs({
        ...contactUs,
        name: data.name || "",
        email: data.email || "",
        message: data.message || "",
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const singleContactQuery = useQuery(
    [queryNames.CONTACT_US, contactUsId],
    fetchContactUsDetail,
    { enabled: isId, refetchOnWindowFocus: false }
  );

  if (singleContactQuery.isFetching) {
    return <Loader />;
  }
  return (
    <>
      <PageTitle title="Query Detail" />
      <Box component="form" noValidate autoComplete="off">
        <Box sx={styles.wrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInputField label="Name" value={contactUs.name} disabled />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputField
                label="Email"
                value={contactUs.email}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomInputField
                label="Message"
                value={contactUs.message}
                multiline
                rows={5}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ContactUsDetail;
