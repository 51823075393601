const styles = {
  mainWrapper: {
    borderTop: "1px solid #EBEBEE",
    marginTop: "20px",
    pb: "20px",
  },
  wrapper: {
    bgcolor: "#fff",
    borderRadius: "20px",
    p: 3,
    mt: 2,
  },
  heading: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#1E1E1F",
    lineHeight: "36px",
  },
  subHeading: {
    color: "#B4B1B6",
    mb: 1,
  },
  name: {
    color: "#1E1E1F",
    fontSize: 16,
    fontWeight: 500,
  },
  email: {
    color: "#4B4B4B",
    fontSize: 14,
  },
  infoWrapper: {
    flex: 1,
  },

  btn: {
    px: 2,
    height: { md: "50px", xs: 45 },
    whiteSpace: "nowrap",
    minWidth: { md: 120, xs: 80 },
    textTransform: "capitalize",
    fontWeight: { md: 600, xs: 500 },
    borderRadius: "10px",
  },
  addBtn: {
    bgcolor: "#135099",
    color: "#fff",
  },
  removeBtn: {
    bgcolor: "#E8EEF5",
    color: "#000000",
    "&:hover": {
      bgcolor: "#E8EEF5",
    },
  },

  avatar: {
    height: { md: 140, sm: 120, xs: 100 },
    width: { md: 140, sm: 120, xs: 100 },
    border: { md: "5px solid #FFF", xs: "2px solid #fff" },
    bgcolor: "#E8EEF5",
    mb: 2,

    cursor: "pointer",
    "& svg": {
      height: 75,
      width: 75,
      fill: "#135099",
    },
  },
  gridWrapper: {
    display: "flex",
    justifyContent: { sm: "flex-start", xs: "center" },
    flexWrap: "wrap",
    gap: "30px",
    pt: 2,
    // borderTop: "1px solid #EBEBEE",
    // marginTop: "20px",
    // py: "20px",
  },
  card: {
    position: "relative",
    background: "#fff",
    borderRadius: 4,
    width: 200,
    minHeight: 190,
    textAlign: "center",
    border: "1px solid #EBEBEE",
    px: "20px",
    "& h3": {
      color: "#000",
      fontSize: 16,
      fontWeight: 400,
      textAlign: "center",
      mt: 1,
      wordBreak:"break-word"
    },
    "& p": {
      color: "#4B4B4B",
      textAlign: "center",
      fontSize: 14,
      wordBreak:"break-word"
    },
  },
  menuBtn: {
    position: "absolute",
    top: 5,
    right: 5,
    p: "5px",
    background: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(20px)",
    "& svg": {
      width: 30,
      height: 30,
    },
  },
  profileWrapper: {
    textAlign: "center",
    mt: 3,
    "& img": {
      //   width: "100%",
      //   height: "100%",
      width: 70,
      height: 70,
      objectFit: "cover",
      border: "2px solid #fff",
      borderRadius: "50%",
    },
    "& svg": {
      width: "100%",
      height: 70,
    },
  },
};
export default styles;
