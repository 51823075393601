import { useAtom } from "jotai";
import { loggedUserAtom, appPackagesAtom, featureModal } from "../data/store";
import { SUBSCRIPTION_KEY } from "../data/constants/feature";
import _ from "lodash";

const useFeature = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);
  const [packages, setPackages] = useAtom(appPackagesAtom);
  const [globalFeatureModal, setGlobalFeatureModal] = useAtom(featureModal);
  // const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  // const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  // const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
  //   userRemainingFunctions
  // );

  // const [memberAvailableFunctions, setMemberAvailableFunctions] = useAtom(
  //   memberRemainingFunctions
  // );

  // const userActions = (userData) => {
  //   const actionInfoWithTeam = userData?.user_action_info?.filter(
  //     (obj) => obj.team_id !== null
  //   );
  //   const actionInfoWithoutTeam = userData?.user_action_info?.filter(
  //     (obj) => obj.team_id === null
  //   );

  //   setMemberAvailableFunctions({
  //     ...memberAvailableFunctions,
  //     total_cards: actionInfoWithTeam?.[0]?.total_cards,
  //     total_connections: actionInfoWithTeam?.[0]?.total_connections,
  //     total_custom_urls: actionInfoWithTeam?.[0]?.total_custom_urls,
  //     total_emergency_contacts:
  //       actionInfoWithTeam?.[0]?.total_emergency_contacts,
  //     total_file_uploads: actionInfoWithTeam?.[0]?.total_file_uploads,
  //     total_payments: actionInfoWithTeam?.[0]?.total_payments,
  //   });

  //   setUserAvailableFunctions({
  //     ...userAvailableFunctions,
  //     total_cards: actionInfoWithoutTeam?.[0]?.total_cards,
  //     total_connections: actionInfoWithoutTeam?.[0]?.total_connections,
  //     total_custom_urls: actionInfoWithoutTeam?.[0]?.total_custom_urls,
  //     total_emergency_contacts:
  //       actionInfoWithoutTeam?.[0]?.total_emergency_contacts,
  //     total_file_uploads: actionInfoWithoutTeam?.[0]?.total_file_uploads,
  //     total_payments: actionInfoWithoutTeam?.[0]?.total_payments,
  //   });
  // };

  const compareLimits = (type) => {
    // check Availablity
    let currentPackage = loggedUser.data.package;

    // for community
    if (type === SUBSCRIPTION_KEY.community) {
      if (currentPackage.community) {
        return true;
      } else {
        console.log("show not community support");
        setGlobalFeatureModal(true);
        return false;
      }
    }

    // for e-commerce
    if (type === SUBSCRIPTION_KEY.commerce) {
      if (currentPackage.commerce) {
        return true;
      } else {
        console.log("IN fasle commerce");
        setGlobalFeatureModal(true);
        return false;
      }
    }

    // for Team
    if (type === SUBSCRIPTION_KEY.team) {
      if (currentPackage.teams) {
        return true;
      } else {
        console.log("IN FALSE TEAM");
        setGlobalFeatureModal(true);
        return false;
      }
    }

    // For video
    if (type === SUBSCRIPTION_KEY.video) {
      if (currentPackage.video_capacity) {
        return true;
      } else {
        setGlobalFeatureModal(true);
        return false;
      }
    }

    // for card calculate limit
    if (type === SUBSCRIPTION_KEY.card) {
      let packageInfo = packages[currentPackage.id - 1];

      if (packageInfo.limit_card_create === "unlimited") {
        return true;
      } else {
        if (loggedUser.data.cards_count < packageInfo.limit_card_create) {
          return true;
        } else {
          setGlobalFeatureModal(true);
          return false;
        }
      }
    }

    return true;
    // Get the limit value from data based on the functionType

    // const limitValue =
    //   purchasedPackage?.[
    //     `limit_${
    //       functionType === "connection" ? "connections" : functionType
    //     }_${functionType === "connection" ? "save" : "create"}`
    //   ];

    // // Get the total value from userAvailableFunctions based on the functionType
    // let totalValue;
    // if (userTeamId) {
    //   totalValue = memberAvailableFunctions[`total_${functionType}s`];
    // } else {
    //   totalValue = userAvailableFunctions[`total_${functionType}s`];
    // }

    // // Perform the comparison and return true or false
    // if (limitValue && (limitValue > totalValue || limitValue == "unlimited")) {
    //   return false;
    // } else {
    //   if (purchasedPackage?.id == "1") {
    //     manageFreePackage();
    //   }
    //   return true;
    // }
  };

  const updateCount = (actionType) => {
    if (actionType === "add") {
      let user = _.cloneDeep(loggedUser);
      user.data.cards_count = makeNonNegative(loggedUser.data.cards_count) + 1;
      setLoggedUser(user);
    }

    if (actionType === "delete") {
      let user = _.cloneDeep(loggedUser);
      user.data.cards_count = makeNonNegative(loggedUser.data.cards_count) - 1;
      setLoggedUser(user);
    }
  };

  function makeNonNegative(number) {
    if (number < 0) {
      return 0;
    } else {
      return number;
    }
  }

  // const updateCount = (functionType, actionType) => {
  //   if (actionType === "add") {
  //     if (userTeamId) {
  //       setMemberAvailableFunctions((prevFunctions) => ({
  //         ...prevFunctions,
  //         [`total_${functionType}s`]:
  //           prevFunctions[`total_${functionType}s`] + 1,
  //       }));
  //     } else {
  //       setUserAvailableFunctions((prevFunctions) => ({
  //         ...prevFunctions,
  //         [`total_${functionType}s`]:
  //           prevFunctions[`total_${functionType}s`] + 1,
  //       }));
  //     }
  //   } else if (actionType === "delete") {
  //     if (userTeamId) {
  //       setMemberAvailableFunctions((prevFunctions) => ({
  //         ...prevFunctions,
  //         [`total_${functionType}s`]:
  //           prevFunctions[`total_${functionType}s`] > 0
  //             ? prevFunctions[`total_${functionType}s`] - 1
  //             : 0,
  //       }));
  //     } else {
  //       setUserAvailableFunctions((prevFunctions) => ({
  //         ...prevFunctions,
  //         [`total_${functionType}s`]:
  //           prevFunctions[`total_${functionType}s`] > 0
  //             ? prevFunctions[`total_${functionType}s`] - 1
  //             : 0,
  //       }));
  //     }
  //   }
  // };

  // const manageFreePackage = (functionType) => {
  //   if (userAvailableFunctions?.total_cards >= 1) return functionType;
  //   if (userAvailableFunctions?.total_custom_urls >= 1) return functionType;
  //   if (userAvailableFunctions?.total_emergency_contacts >= 1)
  //     return functionType;
  //   if (userAvailableFunctions?.total_file_uploads >= 1) return functionType;
  //   if (userAvailableFunctions?.total_payments >= 1) return functionType;
  //   return false;
  // };

  return { compareLimits, updateCount };
};

export default useFeature;
