import { ReactComponent as Dashboard } from "../../assets/svg/sidebarIcons/Dashboard.svg";
import { ReactComponent as BusinessCard } from "../../assets/svg/sidebarIcons/BusinessCard.svg";
import { ReactComponent as Contact } from "../../assets/svg/sidebarIcons/Contact.svg";
import { ReactComponent as Product } from "../../assets/svg/sidebarIcons/Product.svg";
import { ReactComponent as Settings } from "../../assets/svg/sidebarIcons/Settings.svg";
import { ReactComponent as History } from "../../assets/svg/sidebarIcons/Order History.svg";
import { ReactComponent as Terms } from "../../assets/svg/sidebarIcons/Terms.svg";

import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import PolicyIcon from "@mui/icons-material/Policy";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import routes from "./routes";

const customerSidebarRoutes = [
  {
    label: "Profile",
    icon: <PermIdentityIcon />,
    path: routes.CUSTOMER_PROFILE,
  },
  {
    label: "Brand You",
    icon: <BusinessCard />,
    path: routes.CUSTOMER_BUSINESS_CARD,
  },
  // {
  // 	label: "Contact",
  // 	icon: <Contact />,
  // 	path: routes.CUSTOMER_CONTACT,
  // },
  {
    label: "Your Community",
    icon: <Contact />,
    path: routes.CUSTOMER_CONNECTIONS,
  },
  {
    label: "Product / Services",
    icon: <Product />,
    path: routes.CUSTOMER_PRODUCT,
  },
  {
    label: "My Products",
    icon: <CategoryOutlinedIcon />,
    path: routes.CUSTOMER_PRODUCT_LISTING,
  },
  {
    label: "Card Order History",
    icon: <History />,
    path: routes.CUSTOMER_CARD_ORDER_HISTORY,
  },
  {
    label: "Settings",
    icon: <Settings />,
    path: routes.CUSTOMER_SETTINGS,
  },
  {
    label: "Subscriptions",
    icon: <PriceChangeIcon />,
    path: routes.CUSTOMER_SUBSCRIPTION,
  },
  {
    label: "Help",
    icon: <HelpOutlineIcon />,

    subCategories: [
      {
        label: "About Us",
        icon: <InfoIcon />,
        path: routes.CUSTOMER_ABOUT_US,
      },
      {
        label: "Contact Us",
        icon: <Contact />,
        path: routes.CUSTOMER_CONTACT_US,
      },
      {
        label: "Privacy Policy",
        icon: <PolicyIcon />,
        path: routes.CUSTOMER_PRIVACY_POLICY,
      },
      {
        label: "Terms & Conditions",
        icon: <Terms />,
        path: routes.CUSTOMER_TERMS_AND_CONDITIONS,
      },
    ],
  },
];

export { customerSidebarRoutes };
