import { useEffect } from "react";
import {
	Avatar,
	Box,
	Button,
	Divider,
	Grid,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import ProfileBanner from "../../../assets/svg/ProfileBanner.svg";
import { ReactComponent as UploadPic } from "../../../assets/svg/UploadPic.svg";
import { ReactComponent as Library } from "../../../assets/svg/Library.svg";
import { ReactComponent as LinkArrow } from "../../../assets/svg/LinkArrow.svg";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CropAvatar from "../../../components/modal/CropAvatar";
import VideoUpload from "../../../components/modal/VideoUpload";
import { destroy, get } from "../../../server";
import {
	ADMIN_COUSTOMER_DELETE,
	CUSTOMER_PROFILE_GET,
	GET_IMAGE,
} from "../../../data/constants/apiRoutes";
import { useSnackbar } from "notistack";
import queryNames from "../../../data/constants/queryNames";
import { useMutation, useQuery } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../../data/constants/routes";
import Loader from "../../../components/Loader";
import PageTitle from "../../../components/common/PageTitle";
import { Delete } from "@mui/icons-material";
import BoxModal from "../../../components/modal/BoxModal";
import { useAtom } from "jotai";
import { loggedUserAtom } from "../../../data/store";

const styles = {
	banner: {
		mt: "17px",
		position: "relative",
		height: 250,
		cursor: "pointer",
	},
	bannerImg: {
		height: "100%",
		width: "100%",
		borderRadius: "15px",
		objectFit: "contain",
		backgroundColor: "#eee",
	},
	uploadStack: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		alignItems: "center",
		cursor: "pointer",
		fontSize: { lg: 20, md: 18, sm: 15 },
		"& svg": {
			height: { lg: 64, md: 50, sm: 35 },
		},
		"& label": {
			color: "#FFF",
			fontWeight: 400,
			lineHeight: "24px",
			textTransform: "capitalize",
			whiteSpace: "nowrap",
		},
	},
	avatar: {
		height: { md: 160, sm: 120, xs: 100 },
		width: { md: 160, sm: 120, xs: 100 },
		border: "5px solid #FFF",
		bgcolor: "#E8EEF5",
		position: "absolute",
		left: { lg: 50, md: 40, sm: 40 },
		top: 140,
		cursor: "pointer",
		"& svg": {
			height: 75,
			width: 75,
			fill: "#135099",
		},
		"@media (max-width: 768px)": {
			left: 20,
			top: 140,
		},
	},
	photoMenu: {
		width: "100%",
		top: "3%",
		left: { lg: "9%", md: "16.5%", sm: "2%" },
		"& .MuiPaper-root": {
			borderRadius: "15px",
		},
	},
	coverMenu: {
		mt: "45px",
		top: "40.7%",
		left: { lg: "56%", md: "48%", sm: "40%", xs: "40%" },
		transform: "translate(-50%,-50%)",
		"& .MuiPaper-root": {
			borderRadius: "15px",
		},
	},
	name: {
		mt: { lg: "25px", md: "25px", xs: "80px" },
		ml: { lg: "235px", md: "235px", xs: "10px" },
		"& h4": {
			color: "#1E1E1F",
			fontSize: { md: 30, xs: 20 },
			fontWeight: 600,
			textTransform: "capitalize",
		},
		"& label": {
			color: "#4B4B4B",
			fontSize: 20,
			fontWeight: 400,
		},
	},
	container: {
		// mt: { lg: 10, xs: 5 },
		mt: 3,
		bgcolor: "#FFF",
		borderRadius: "20px",
		p: { md: "30px 45px", xs: "20px" },
		// px: "45px",
		// py: "31px",
		width: "100%",
		"& h5": {
			color: "#1E1E1F",
			fontSize: { md: 24, xs: 20 },
			fontWeight: 500,
		},
		"& p": {
			mt: 1,
			color: "#4B4B4B",
			fontSize: 16,
			fontWeight: 400,
		},
		"& h1": {
			color: "#1E1E1F",
			fontSize: { md: 22, xs: 20 },
			fontWeight: 600,
			textTransform: "capitalize",
		},
		"& h2, & a": {
			color: "#4B4B4B",
			fontSize: 20,
			fontWeight: 400,
			textDecoration: "none",
		},
	},
	contacts: {
		"& label": {
			color: "#4B4B4B",
			fontWeight: 600,
		},
		"& span": {
			mt: 0.5,
			color: "#1E1E1F",
			fontWeight: 400,
		},
		"& a": {
			textDecoration: "none",
			mt: 0.5,
			color: "#1E1E1F",
			fontWeight: 400,
		},
	},

	editBtn: {
		textTransform: "capitalize",
		background: "#135099",
		"&:hover": {
			background: "#135099",
		},
	},
	expandTextBtn: {
		textTransform: "capitalize",
		background: "none",
		"&:hover": {
			background: "none",
		},
		padding: 0,
	},
	label: {
		width: "120px", // Set a fixed width for the label column
		alignItems: "center",
	},
	value: {
		alignItems: "center",
	},
};

const initialState = {
	first_name: "",
	last_name: "",
	email: "",
	phone: "",
	position: "",
	company: "",
	linkedin: "",
	about: "",
};

const ViewProfile = () => {
	const [user, setUser] = useState(initialState);
	const [loggedUser, setLoggedUser] = useAtom(loggedUserAtom);

	const [file, setFile] = useState(null);
	const [profilePhoto, setProfilePhoto] = useState(null);

	const [coverPhoto, setCoverPhoto] = useState(null);

	const [profilePhotoMenu, setProfilePhotoMenu] = useState(null);
	const [coverPhotoMenu, setCoverPhotoMenu] = useState(null);

	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const fileInputRef = useRef(null);
	const navigate = useNavigate();

	const [showFullText, setShowFullText] = useState(false);
	const displayText = showFullText
		? user?.about
		: user?.about?.length > 400
		? user?.about?.slice(0, 400) + "..."
		: user?.about;

	const toggleText = () => {
		setShowFullText(!showFullText);
	};

	const { enqueueSnackbar } = useSnackbar();

	const handleMenuItemClick = () => {
		fileInputRef.current.click();
	};

	const handleFileChange = (event) => {
		const selectedFile = URL.createObjectURL(event.target.files[0]);
		setFile(selectedFile);
		setModal("avatar");
	};

	//the modal doesnt close on clicking on scren
	const handleOpenProfilePhotoMenu = (e) => {
		setProfilePhotoMenu(e.currentTarget);
	};
	const handleOpenCoverPhotoMenu = (e) => {
		setCoverPhotoMenu(e.currentTarget);
	};

	const handleCloseProfilePhotoMenu = () => {
		setProfilePhotoMenu(null);
	};
	const handleCloseCoverPhotoMenu = () => {
		setCoverPhotoMenu(null);
	};

	const fetchProfile = async () => {
		setLoading(true);
		const { status, message, data } = await get(CUSTOMER_PROFILE_GET);
		if (status === true) {
			setUser(data);
		}
		// else enqueueSnackbar(message, { variant: "error" });
		setLoading(false);
	};

	const userQuery = useQuery([queryNames.PROFILE], fetchProfile, {
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		fetchProfile();
	}, []);

	const handleDelete = async () => {
		const { status, message, data } = await destroy(
			`${ADMIN_COUSTOMER_DELETE}/${user?.id}`
		);
		console.log(status, message, data);
		if (status === true) {
			enqueueSnackbar(message, { variant: "success" });
			setLoggedUser({});
			navigate(routes.LOGIN);
			// handleClose();
		} else {
			enqueueSnackbar(message, { variant: "error" });
		}
	};

	const mutation = useMutation(handleDelete);

	if (loading) {
		return <Loader />;
	}
	return (
		<>
			{/* <Box component="div" sx={{ textAlign: "end" }}>
        <Link to={`${routes.CUSTOMER_PROFILE_EDIT}${user.id}`}>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={styles.editBtn}
          >
            Edit
          </Button>
        </Link>
      </Box>
      <Box position="relative">
        <Box
          sx={styles.banner}
          rowGap="16px"
          //  onClick={handleCoverClick}
        >
          <Box
            component="img"
            src={
              user?.cover_image
                ? user?.cover_image.includes("images")
                  ? `${GET_IMAGE}/${user?.cover_image}`
                  : URL.createObjectURL(user?.cover_image)
                : ProfileBanner
            }
            sx={styles.bannerImg}
          />
        </Box>
        <Avatar
          sx={styles.avatar}
          // onClick={handleAvatarClick}
          src={
            user?.image
              ? user?.image.includes("images")
                ? `${GET_IMAGE}/${user?.image}`
                : URL.createObjectURL(user?.image)
              : null
          }
        >
          <CameraAltOutlinedIcon />
          <input
            type="file"
            accept="image/*"
            hidden
            // onChange={handleAvatarChange}
            id="avatarUpload"
            // ref={avatarRef}
          />
        </Avatar>

        <Stack sx={styles.name}>
          <Box component="h4">
            {user?.first_name} {user?.last_name}
          </Box>
          <Box component="label">{user.position}</Box>
        </Stack>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box sx={{ ...styles.container, flex: 2 }}>
            <Typography component="h5">About</Typography>
            <Typography component="p">
              {displayText}

              {user?.about?.length > 400 && (
                <Button
                  onClick={toggleText}
                  disableRipple
                  disableElevation
                  sx={styles.expandTextBtn}
                >
                  {showFullText ? "Show Less" : "Read More"}
                </Button>
              )}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: "flex" }}>
          <Box sx={{ ...styles.container, flex: 2 }}>
            <Typography component="h5">Contact Info</Typography>
            <Box mt={1} sx={styles.contacts}>
              {user?.email && (
                <Stack>
                  <Typography component="label">Email:</Typography>
                  <Typography component="a" href={`mailto:${user.email}`}>
                    {user.email} <LinkArrow />
                  </Typography>
                </Stack>
              )}

              {user?.phone && (
                <Stack mt="14px">
                  <Typography component="label">Phone:</Typography>
                  <Typography component="span">{`+${user.phone}`}</Typography>
                </Stack>
              )}

              {user?.company && (
                <Stack mt="14px">
                  <Typography component="label">Company:</Typography>
                  <Typography component="span">{user.company}</Typography>
                </Stack>
              )}

              {user?.linkedin && (
                <Stack mt="14px">
                  <Typography component="label">Linkedin:</Typography>
                  <Typography
                    component="a"
                    href={`https://${user.linkedin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.linkedin} <LinkArrow />
                  </Typography>
                </Stack>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CropAvatar
        modal={modal === "avatar"}
        setModal={setModal}
        image={file}
        setProfilePhoto={setProfilePhoto}
      />
      <VideoUpload modal={modal === "video"} setModal={setModal} /> */}

			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<PageTitle title="Profile Detail" />
				<Stack direction="row" alignItems="center" spacing={1}>
					<Button
						variant="contained"
						startIcon={<Delete />}
						sx={{
							...styles.editBtn,
							bgcolor: "red",
							// mr: 1,
							"&:hover": { bgcolor: "red" },
						}}
						onClick={() => setModal(true)}
					>
						Delete Account
					</Button>
					<Link to={`${routes.CUSTOMER_PROFILE_EDIT}${user.id}`}>
						<Button
							variant="contained"
							startIcon={<EditIcon />}
							sx={styles.editBtn}
						>
							Edit
						</Button>
					</Link>
				</Stack>
			</Stack>

			{/* <PageTitle
				title="Profile Detail"
				createBtn
				createBtnText="Edit"
				url={`${routes.CUSTOMER_PROFILE_EDIT}${user.id}`}
			/> */}

			<Box sx={styles.container}>
				<Stack>
					<Typography component="h1">Full Name:</Typography>
					<Typography component="h2">
						{user?.first_name} {user?.last_name}
					</Typography>
				</Stack>

				<Stack mt={2}>
					<Typography component="h1">Email:</Typography>
					<Typography component="a" href={`mailto:${user.email}`}>
						{user.email} <LinkArrow />
					</Typography>
				</Stack>
			</Box>

			<BoxModal
				header="Are you sure?"
				title={`Do you really want to delete your profile?`}
				open={modal}
				handleClose={() => setModal(false)}
				handleConfirm={() => mutation.mutate()}
				confirmButton="Delete"
				confirmButtonColor="#E53935"
				isLoading={mutation.isLoading}
			/>
		</>
	);
};

export default ViewProfile;
